import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';

import {
 ComboBox, IComboBoxOption, Stack, Text,
} from '@fluentui/react';
import _ from 'lodash';
import moment from 'moment';
import { ISuggestedValue } from '../../../utils/SuggestedValue';

import {
  GET_SUGGESTED_VALUES_BY_CATEGORY_CODE,
  GET_SUGGESTED_VALUE_CATEGORIES,
  ISuggestedValueCategory,
  convertSuggestedValuesToComboBoxOptions,
} from '../../../utils';
import { IProperty } from '../../../utils/Property';
import { convertSuggestedValueCategoriesToComboBoxOptionsByCategoryCode } from '../../../utils/SuggestedValueCategory';

interface ISuggestedValueView {
  property: IProperty;
  callBack: (item: IProperty) => void;
}

const SuggestedValueView: React.FC<ISuggestedValueView> = ({
  property,
  callBack,
}) => {
  const [suggestedValueCategory, setSuggestedValueCategory] = useState<
    string | undefined
  >();

  const { data: suggestedValueCategoryData } = useQuery(
    GET_SUGGESTED_VALUE_CATEGORIES,
    {
      variables: {
        orderBy: {
          category_description: 'asc',
        },
      },
    },
  );

  useEffect(() => {
    console.log(suggestedValueCategoryData);
  }, [suggestedValueCategoryData]);

  const { loading, error, data } = useQuery(
    GET_SUGGESTED_VALUES_BY_CATEGORY_CODE,
    {
      variables: {
        filter: {
          suggested_value_category: {
            category_code: {
              contains: property?.remote_category, // Assumes 'property' is defined elsewhere in your code
            },
          },
          active: true,
        },
        orderBy: {
          suggested_value: 'asc',
        },
      },

      skip: property === undefined || !property.remote_category,
    },
  );

  // const comboboxSuggestedValue = useRef<IComboBox>(null);

  if (loading) return <p>Laden...</p>;
  if (error) return <p>Fout :(</p>;

  const comboboxSuggestedValueOptions: IComboBoxOption[] =
    convertSuggestedValuesToComboBoxOptions(
      data && data.findManySuggestedValues ? data.findManySuggestedValues : [],
    );

  if (!property || !property.type) {
    return <Stack>property niet goed geladen.</Stack>;
  }

  return (
    <Stack>
      <Text style={{ fontWeight: 'bold', marginBottom: '10px' }}>
        {suggestedValueCategory}
      </Text>
      <ComboBox
        label='Category'
        options={convertSuggestedValueCategoriesToComboBoxOptionsByCategoryCode(
          suggestedValueCategoryData &&
            suggestedValueCategoryData.findManySuggestedValueCategories
            ? suggestedValueCategoryData.findManySuggestedValueCategories
            : [],
        )}
        disabled={property && !property.isNew}
        selectedKey={
          property && property.remote_category
            ? property.remote_category
            : undefined
        }
        onChange={(e, option) => {
          if (option) {
            let newValue: undefined | string;
            let newCategory: undefined | string;
            if (
              suggestedValueCategoryData &&
              suggestedValueCategoryData.findManySuggestedValueCategories
            ) {
              const filterValues =
                suggestedValueCategoryData.findManySuggestedValueCategories.filter(
                  (item: ISuggestedValueCategory) => {
                    if (
                      item &&
                      item.category_code &&
                      item.category_code === option.key
                    ) return true;
                    return false;
                  },
                );

              if (filterValues[0]) {
                newValue = filterValues[0];
              }
            }
            if (newValue) {
              const result = _.cloneDeep(property);

              result.remote_category = option.key as string;
              callBack(result);
            }
          }
        }}
      />
      <ComboBox
        label='Waarde'
        options={comboboxSuggestedValueOptions}
        disabled={comboboxSuggestedValueOptions.length === 0}
        selectedKey={
          property && property.suggested_value
            ? property.suggested_value.id
            : undefined
        }
        onChange={(e, option) => {
          if (option) {
            let newValue: undefined | string;
            let newCategory: undefined | string;
            if (data.findManySuggestedValues) {
              const filterValues = data.findManySuggestedValues.filter(
                (item: ISuggestedValue) => {
                  if (item && item.id && item.id === option.key) return true;
                  return false;
                },
              );

              if (filterValues && filterValues[0]) newValue = filterValues[0].suggested_value;
              newCategory = filterValues[0].suggested_value_category
                ? filterValues[0].suggested_value_category.category_description
                : undefined;
            }
            if (newValue) {
              const result = _.cloneDeep(property);
              if (typeof option.key === 'number') {
                result.suggested_value = {
                  id: option.key,
                  suggested_value: newValue,
                };

                result.isDirty = true;

                if (newCategory) {
                  result.suggested_value.suggested_value_category = {
                    category_description: newCategory,
                    id: moment().unix(),
                  };
                }
                callBack(result);
              }
            }
          }
        }}
      />
    </Stack>
  );
};

export default SuggestedValueView;
