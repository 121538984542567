import { useCallback } from 'react';

type Props = {
  color: 'green' | 'blue' | 'orange' | 'red' | 'gray' | 'black' | 'white';
  style?: React.CSSProperties;
};

const Label: React.FC<Props> = ({
  children,
  style,
  color = 'green',
  ...props
}) => {
  const getBackgroundColor = useCallback(() => {
    let value = '#71BF43';
    switch (color) {
      case 'green':
        value = '#71BF43';
        break;
      case 'blue':
        value = '#581A73';
        break;
      case 'orange':
        value = '#ff9d57';
        break;
      case 'red':
        value = '#ff5757';
        break;
      case 'gray':
        value = '#757575';
        break;
      case 'black':
        value = '#000000';
        break;
      case 'white':
        value = '#ffffff';
        break;
      default:
        break;
    }
    return value;
  }, [color]);

  const getTransparentColor = (hexCode: string) => {
    const r = parseInt(hexCode.slice(1, 3), 16);
    const g = parseInt(hexCode.slice(3, 5), 16);
    const b = parseInt(hexCode.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, 0.2)`;
  };

  const getTransparentColorV2 = (hexCode: string) => {
    const r = parseInt(hexCode.slice(1, 3), 16);
    const g = parseInt(hexCode.slice(3, 5), 16);
    const b = parseInt(hexCode.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, 0.3)`;
  };

  const backgroundColor = getBackgroundColor();
  const transparentColor = getTransparentColorV2(backgroundColor);
  const transparentColorV2 = getTransparentColor(backgroundColor);
  return (
    <span
      style={{
        background: `linear-gradient(135deg, ${transparentColor}, ${transparentColorV2})`,
        border: `1px solid ${getBackgroundColor()}`,
        color: getBackgroundColor(),
        fontWeight: 600,
        padding: '4px',
        borderRadius: '3px',
        ...style,
      }}
      {...props}
    >
      {children}
    </span>
  );
};

export default Label;
