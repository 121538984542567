import { useAuth0 } from '@auth0/auth0-react';
import { Icon, Stack, TextField } from '@fluentui/react';
import { useCallback, useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useSearchParams } from 'react-router-dom';
import {
  GET_PURCHASE_ORDERS,
  IPurchaseOrder,
  stackTokens15,
  textFieldStyles300,
} from '../../utils';
import useDebounce from '../../components/hooks/useDebounce';
import PurchaseOrdersOverview from './components/PurchaseOrdersOverview';

interface IPagedPurchaseOrders {
  skip: number;
  take: number;
  filter: string;
  initialLoad: boolean;
  showLoadMore: boolean;
  isFiltered: boolean;
  isSortedAsc: boolean;
  sortedField: string;
}

function PurchaseOrders() {
  const { isAuthenticated } = useAuth0();
  const [purchaseOrders, setPurchaseOrders] = useState<IPurchaseOrder[]>([]);
  const [searchField, setSearchField] = useState<string>('');
  const [searchParams] = useSearchParams();

  const [pagedState, setPagedState] = useState<IPagedPurchaseOrders>({
    skip: 0,
    take: 20,
    filter: '',
    showLoadMore: true,
    isFiltered: false,
    initialLoad: true,
    isSortedAsc: false,
    sortedField: 'creation_date',
  });

  const debouncedSearchField = useDebounce(searchField.replace('-', ''), 500);

  const searchFieldQuery = useCallback(() => {
    const query: any = {
      orderBy: {
        [pagedState.sortedField]: pagedState.isSortedAsc ? 'asc' : 'desc',
      },
    };

    if (debouncedSearchField !== '') {
      const filterArray = debouncedSearchField.split(' ');
      const filterObject: any = {
        OR: [],
      };

      const innerFilterObject: any = {
        AND: [],
      };

      for (let i = 0; i < filterArray.length; i++) {
        const filterValue: any = {
          OR: [
            {
              supplier: {
                name: i !== 0 ? { startsWith: filterArray[i] } : { contains: filterArray[i] },
              },
            },
            { construction_site: { name: { contains: filterArray[i] } } },
            { purchase_order_no: { contains: filterArray[i] } },
            { description: { contains: filterArray[i] } },
          ],
        };

        const numericValue = parseInt(filterArray[i], 10);
        if (!Number.isNaN(numericValue)) {
          filterValue.OR.push({ purchase_order_no: { contains: numericValue.toString() } });
        }

        innerFilterObject.AND.push(filterValue);
      }

      filterObject.OR.push(innerFilterObject);
      query.filter = filterObject;
    }

    return query;
  }, [debouncedSearchField, pagedState.sortedField, pagedState.isSortedAsc]);

  const construction_site = searchParams.get('constructionSite');
  const redirect = searchParams.get('redirect');

  const getQueryParams = () => {
    if (construction_site) {
      const query = searchFieldQuery();
      const constructionSiteFilter = {
        construction_site: { id: { equals: +construction_site } },
      };

      if (!query.filter) {
        query.filter = constructionSiteFilter;
      } else {
        query.filter = {
          AND: [
            constructionSiteFilter,
            query.filter,
          ],
        };
      }

      return {
        ...query,
        skip: pagedState.skip,
        take: pagedState.take,
      };
    }

    return {
      ...searchFieldQuery(),
      skip: pagedState.skip,
      take: pagedState.take,
    };
  };

  const {
    loading,
    error,
    refetch,
  } = useQuery(GET_PURCHASE_ORDERS, {
    variables: getQueryParams(),
    onCompleted: (data: any) => {
      const newPurchaseOrders = data.findManyPurchaseOrders;
      if (pagedState.skip === 0) {
        setPurchaseOrders(newPurchaseOrders);
      } else {
        setPurchaseOrders(prevOrders => {
          const updatedOrders = [
            ...prevOrders,
            ...newPurchaseOrders.map((order: IPurchaseOrder) => ({ ...order })),
          ];
          if (newPurchaseOrders.length > 0 && prevOrders.length > 0) {
            updatedOrders[0] = {
              ...updatedOrders[0],
              prisma_total: prevOrders[0].prisma_total,
            };
          }
          return updatedOrders;
        });
      }

      setPagedState(prev => ({
        ...prev,
        showLoadMore: newPurchaseOrders.length === prev.take,
        initialLoad: false,
      }));
    },
  });

  const loadMoreCallback = () => {
    if (!loading) {
      setPagedState(prev => ({
        ...prev,
        skip: prev.skip + prev.take,
      }));
    }
  };

  const setSorting = (isSortedAsc: boolean, sortedField: string) => {
    setPagedState(prev => ({
      ...prev,
      isSortedAsc,
      sortedField,
      skip: 0,
      showLoadMore: true,
    }));
    setPurchaseOrders([]);
  };

  if (!isAuthenticated) return <p>Verboden</p>;
  if (error) return <p>oeps er ging iets mis</p>;

  return (
    <Stack tokens={stackTokens15}>
      <div style={{ padding: '25px' }}>
        <h3
          style={{
            fontSize: '20px',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <Icon iconName='ServerEnviroment' style={{ fontSize: '20px' }} />
          Bestelbonnen
        </h3>
        <TextField
          label='Zoek op werf, leverancier of bestelbonnummer'
          styles={textFieldStyles300}
          onChange={(_, newValue) => setSearchField(newValue || '')}
          value={searchField}
        />
      </div>

      <PurchaseOrdersOverview
        orders={purchaseOrders}
        refetch={refetch}
        setPurchaseOrders={setPurchaseOrders}
        showBack={!!construction_site}
        constructionSite={construction_site || null}
        redirectTarget={redirect || null}
        loadMoreCallback={loadMoreCallback}
        loading={loading}
        showLoadMore={pagedState.showLoadMore && !pagedState.initialLoad}
        isSortedAsc={pagedState.isSortedAsc}
        sortedField={pagedState.sortedField}
        setSorting={setSorting}
        lazyLoading
      />
    </Stack>
  );
}

export default PurchaseOrders;
