import {
 FormEvent, useCallback, useEffect, useState,
} from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useAuth0 } from '@auth0/auth0-react';
import _ from 'lodash';
import {
  IComboBoxOption,
  Icon,
  Stack,
  StackItem,
  TextField,
  Toggle,
} from '@fluentui/react';
import { toast } from 'react-toastify';
import moment from 'moment';
import {
  convertConstructionSitesToComboBoxOptions,
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_LOADING_MESSAGE,
  default_page_size,
  DEFAULT_TOAST_POSITION,
  GET_CONSTRUCTION_SITES,
  GET_SUGGESTED_VALUES_BY_CATEGORY_CODE,
  stackTokens15,
  textFieldStyles300,
} from '../../utils';
import useDebounce from '../../components/hooks/useDebounce';
import { FilterState } from '../../components/parts/FilterPanel';
import { GET_ASSETS, IAsset } from '../../utils/Asset';
import { toastError, toastSuccess } from '../../utils/toast';
import { convertTeamsToComboBoxOptions, GET_TEAMS } from '../../utils/Team';
import { ComboboxWithFilter } from '../../components/parts';
import AssetsOverview from './components/AssetsOverview';

interface IPagedAsset {
  assets: IAsset[];
  skip: number;
  take: number;
  filter: string;
  initialLoad: boolean;
  showLoadMore: boolean;
  lazyLoading: boolean;
  isFiltered: boolean;
  isSortedAsc: boolean;
  sortedField: string;
}

const isNumeric = (value: string) => {
  let newValue = value;
  if (newValue.startsWith('+')) {
    newValue = newValue.substring(1);
  }

  return !Number.isNaN(parseInt(newValue, 10));
};

const Assets = () => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [pagedState, setPagedState] = useState<IPagedAsset>({
    assets: [],
    skip: 0,
    take: default_page_size,
    filter: '',
    showLoadMore: true,
    lazyLoading: true,
    isFiltered: false,
    initialLoad: true,
    isSortedAsc: true,
    sortedField: 'name',
  });

  const [filter, setFilter] = useState('');
  const [inUse, setInUse] = useState(true);
  const [assetFilter, setAssetFilter] = useState<FilterState | undefined>();
  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false);
  const [teamFilter, setTeamFilter] = useState<number | undefined>();
  const [werfFilter, setWerfFilter] = useState<number | undefined>();

  const debouncedValue = useDebounce(filter.replace('-', ''), 500);

  useEffect(() => {
    client.cache.reset();

    setPagedState(prevState => ({
      ...prevState,
      constructionSites: [],
      skip: 0,
    }));
  }, [filter, assetFilter, teamFilter, werfFilter, inUse]);

  const downloadFile = useCallback(() => {
    toast.promise(
      new Promise((resolve, reject) => {
        (async () => {
          try {
            const url = `${process.env.REACT_APP_BACKEND_URI}/files/assets/xls`;
            const token = await getAccessTokenSilently();
            const fileName = `assets_${moment().format('YYYYMMDD')}.xlsx`;

            const res = await fetch(url, {
              method: 'post',
              headers: new Headers({
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              }),
              body: JSON.stringify({
                filter: getFilters(debouncedValue),
              }),
            });

            if (!res.ok) throw new Error('Download failed');

            const result = await res.blob();

            const aElement = document.createElement('a');
            aElement.setAttribute('download', fileName);
            const href = URL.createObjectURL(result);
            aElement.href = href;
            aElement.setAttribute('target', '_blank');
            aElement.click();
            URL.revokeObjectURL(href);

            resolve('Bestand is gedownload');
            toastSuccess('Bestand is succesvol gedownload');
          } catch (error) {
            reject(error);
          }
        })();
      }),
      {
        pending: {
          position: DEFAULT_TOAST_POSITION,
          render() {
            return DEFAULT_LOADING_MESSAGE;
          },
        },

        error: {
          render({ data }: { data: any }) {
            return (
              data.message || 'Er is een fout opgetreden bij het downloaden'
            );
          },
        },
      },
    );
  }, [filter, assetFilter, teamFilter, werfFilter, inUse]);

  const getOrderBy = () => {
    const orderByObject: any = {}; // deliberately kept any as type to index by string
    orderByObject[pagedState.sortedField] = pagedState.isSortedAsc
      ? 'asc'
      : 'desc';
    return orderByObject;
  };

  const getFilters = useCallback(
    (filterString: string) => {
      const filterArray = filterString.split(' ');

      const filters: any = {
        AND: [
          {
            active: inUse,
          },
        ],
      };

      if (assetFilter && assetFilter.schema.length > 0) {
        for (let i = 0; i < assetFilter.schema.length; i++) {
          for (let t = 0; t < assetFilter.schema[i].fields.length; t++) {
            if (
              assetFilter.schema[i].fields[t].value &&
              assetFilter.schema[i].fields[t].parsedValue &&
              assetFilter.schema[i].fields[t].value !== ''
            ) {
              filters.AND.push(assetFilter.schema[i].fields[t].parsedValue);
            }
          }
        }
      }

      if (filterString.length > 0 && filterString) {
        for (let i = 0; i < filterArray.length; i++) {
          const filterValue: any = {
            OR: [],
          };

          if (isNumeric(filterArray[i])) {
            // TODO, asset might not have numeric field
            const numericValue = parseInt(filterArray[i], 10);
            filterValue.OR = [{ name: { contains: numericValue.toString() } }];
          } else {
            filterValue.OR = [
              { name: { contains: filterArray[i] } },
              { comments: { contains: filterArray[i] } },
            ];
          }

          filters.AND.push(filterValue);
        }
      }

      // ========================================================================================
      // ========================================================================================
      // ========================================================================================

      // TODO ADD TEAM FILTER SO WE CAN FILTER ON TEAM
      if (teamFilter) {
        filters.AND.push({
          asset_logs: {
            some: {
              team_id: {
                equals: teamFilter,
              },
            },
          },
        });
      }

      if (werfFilter) {
        filters.AND.push({
          asset_logs: {
            some: {
              construction_site_id: {
                equals: werfFilter,
              },
            },
          },
        });
      }

      // ========================================================================================
      // ========================================================================================
      // ========================================================================================

      // if (dateFilter.start_date) {
      // filters.AND.push({
      // date_created: {
      // gte: moment(new Date(dateFilter.start_date)).toISOString(),
      // },
      // });
      // }

      // ========================================================================================
      // ========================================================================================
      // ========================================================================================

      // if (dateFilter.end_date) {
      // filters.AND.push({
      // date_created: {
      // lte: moment(new Date(dateFilter.end_date)).toISOString(),
      // },
      // });
      // }

      // ========================================================================================
      // ========================================================================================
      // ========================================================================================

      // if (status && status !== 'ALL') {
      //   if (status === 'INVOICE_ONLY') {
      //     const filterValue: any = {
      //       OR: [
      //         {
      //           status: {
      //             equals: status,
      //           },
      //         },
      //         {
      //           only_invoice: true,
      //         },
      //       ],
      //     };

      //     filters.AND.push(filterValue);
      //   } else {
      //     filters.AND.push({
      //       status: {
      //         equals: status,
      //       },
      //     });
      //   }
      // }

      return filters;
    },

    [filter, assetFilter, teamFilter, werfFilter, inUse],
  );

  // TODO we might need to download assets to excle
  //   const downloadFile = useCallback(async () => {
  //     dispatch(
  //       sendNotification({
  //         message: 'bezig met downloaden',
  //         level: 0,
  //         module: 'template.updateTemplate',
  //         spinner: true,
  //       }),
  //     );
  //     const url = `${process.env.REACT_APP_BACKEND_URI}/files/assets/xls`;
  //     const token = await getAccessTokenSilently();
  //     const fileName = `assets_${moment().format('YYYYMMDD')}.xlsx`;
  //     const res = await fetch(url, {
  //       method: 'post',
  //       headers: new Headers({
  //         Authorization: `Bearer ${token}`,
  //         'Content-Type': 'application/json',
  //       }),
  //       body: JSON.stringify({
  //         filter: parsedFilter,
  //       }),
  //     });

  //     const result = await res.blob();

  //     const aElement = document.createElement('a');
  //     aElement.setAttribute('download', fileName);
  //     const href = URL.createObjectURL(result);
  //     aElement.href = href;
  //     aElement.setAttribute('target', '_blank');
  //     aElement.click();
  //     URL.revokeObjectURL(href);

  //     dispatch(
  //       sendNotification({
  //         module: 'template.updateTemplate',
  //         message: 'Bestand is gedownload',
  //         level: 1,
  //         timeout: 2500,
  //       }),
  //     );
  //   }, [parsedFilter]);

  const variables = {
    filter: getFilters(debouncedValue),
    take: pagedState.take,
    skip: 0,
    orderBy: getOrderBy(),
  };

  const {
    loading: loadingSuggestedValues,
    error: errorSuggestedValues,
    data: dataSuggestedValues,
  } = useQuery(GET_SUGGESTED_VALUES_BY_CATEGORY_CODE, {
    variables: {
      filter: {
        suggested_value_category: {
          category_code: {
            contains: 'assets',
          },
        },
      },
      orderBy: {
        suggested_value: 'asc',
      },
    },
  });

  const {
    data: dataAssets,
    loading,
    error,
    fetchMore,
    refetch,
    client,
  } = useQuery(GET_ASSETS, {
    variables: {
      filter: getFilters(debouncedValue),
      take: pagedState.take,
      skip: 0,
      orderBy: getOrderBy(),
    },
    onError: error => {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    },
  });

  const onSearchValueChange = (
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined,
  ) => {
    const filterString = newValue ? newValue.toLowerCase() : '';
    setFilter(filterString);
  };

  const onChangeFilterInUse = () => {
    setInUse(!inUse);
    clearAssets();
  };

  const setSorting = (isSortedAsc: boolean, sortedField: string) => {
    client.cache.reset();
    clearAssets();
    setPagedState(prevState => ({
      ...prevState,
      isSortedAsc,
      sortedField,
    }));
  };

  const clearAssets = () => {
    setPagedState(prevState => ({
      ...prevState,
      assets: [],
      skip: 0,
    }));
  };

  const fetchMoreAssets = () => {
    fetchMore({
      variables: {
        filter: getFilters(debouncedValue),
        take: pagedState.take,
        skip: pagedState.skip + pagedState.take,
        orderBy: getOrderBy(),
      },
    });

    setPagedState(prevState => ({
      ...prevState,
      skip: pagedState.skip + pagedState.take,
    }));
  };
  // ===============================================================================================
  // =================================== P L O E G E N =============================================
  // ===============================================================================================

  const { data: teamData } = useQuery(GET_TEAMS);

  // ===============================================================================================
  // ===============================================================================================
  // ===============================================================================================

  // ===============================================================================================
  // =================================== C O N S T R U C T I O N  ==================================
  // ===============================================================================================

  const [constructionSiteSearch, setConstructionSiteSearch] = useState<
    string | undefined
  >();

  const debouncedSearchTermConstructionSite = useDebounce(
    constructionSiteSearch,
    500,
  );

  const constructionSiteQuery = useCallback(() => {
    const query: any = {
      orderBy: {
        name: 'asc',
      },
      filter: debouncedSearchTermConstructionSite
        ? {
            OR: [
              { name: { contains: debouncedSearchTermConstructionSite } },
              { city: { contains: debouncedSearchTermConstructionSite } },
              { address: { contains: debouncedSearchTermConstructionSite } },
            ],
          }
        : undefined,
      take: 15,
    };

    return query;
  }, [debouncedSearchTermConstructionSite]);

  const { data: dataConstructionSites } = useQuery(GET_CONSTRUCTION_SITES, {
    variables: {
      ...constructionSiteQuery(),
    },
  });

  // ===============================================================================================
  // ===============================================================================================
  // ===============================================================================================

  if (!isAuthenticated) return <p>Verboden</p>;
  if (error) return <p>Oeps, er ging iets mis...</p>;

  return (
    <Stack tokens={stackTokens15}>
      <div style={{ padding: '25px' }}>
        <h3
          style={{
            fontSize: '20px',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <Icon iconName='DeveloperTools' style={{ fontSize: '20px' }} />
          Materiaal inventaris
        </h3>

        <Stack horizontal tokens={{ childrenGap: 20 }}>
          <TextField
            label='Zoeken...'
            value={filter}
            styles={textFieldStyles300}
            onChange={onSearchValueChange}
            placeholder='naam, beschrijving'
          />

          {teamData &&
            teamData.findManyTeams &&
            teamData.findManyTeams.length > 0 && (
              <StackItem style={{ width: 200, position: 'relative' }}>
                <ComboboxWithFilter
                  label='Ploegen'
                  options={convertTeamsToComboBoxOptions(
                    teamData.findManyTeams,
                  )}
                  value={teamFilter}
                  callBack={(newValue: IComboBoxOption[]) => {
                    if (newValue && newValue.length > 0) {
                      setTeamFilter(newValue[0].key as number);
                      setWerfFilter(undefined);
                    }
                  }}
                />
                <span
                  aria-hidden='true'
                  style={{
                    color: 'rgb(112, 191, 68)',
                    cursor: 'pointer',
                    position: 'absolute',
                  }}
                  onClick={() => {
                    setTeamFilter(undefined);
                  }}
                >
                  Verwijder
                </span>
              </StackItem>
            )}

          {dataConstructionSites &&
            dataConstructionSites.findManyConstructionSites && (
              <StackItem style={{ width: 200, position: 'relative' }}>
                <ComboboxWithFilter
                  label='Werf'
                  options={convertConstructionSitesToComboBoxOptions(
                    dataConstructionSites.findManyConstructionSites,
                  )}
                  value={werfFilter}
                  multiline={false}
                  callBack={(newValue: IComboBoxOption[]) => {
                    if (newValue && newValue.length > 0) {
                      setWerfFilter(newValue[0].key as number);
                      setTeamFilter(undefined);
                    }
                  }}
                  setFilter={(filterValue: string) => {
                    setConstructionSiteSearch(filterValue);
                  }}

                  //   style={{ width: '300px' }}
                />
                <span
                  aria-hidden='true'
                  style={{
                    color: 'rgb(112, 191, 68)',
                    cursor: 'pointer',
                    position: 'absolute',
                  }}
                  onClick={() => {
                    setWerfFilter(undefined);
                  }}
                >
                  Verwijder
                </span>
              </StackItem>
            )}

          <Stack>
            <Toggle
              label='Uitgefaseerd?'
              defaultChecked={!inUse}
              onText='Ja'
              offText='Nee'
              onChange={onChangeFilterInUse}
            />
          </Stack>
        </Stack>
      </div>

      <AssetsOverview
        assets={dataAssets ? dataAssets.findManyAssets : []}
        suggestedValues={
          dataSuggestedValues ? dataSuggestedValues.findManySuggestedValues : []
        }
        loading={loading || loadingSuggestedValues}
        setSorting={setSorting}
        isSortedAsc={pagedState.isSortedAsc}
        sortedField={pagedState.sortedField}
        //
        showLoadMore
        loadMore
        loadMoreCallback={fetchMoreAssets}
        lazyLoading
        //
        downloadFile={downloadFile}
        //
        listFilters={variables}
        setAssetFilter={setAssetFilter}
        //
        isFilterPanelOpen={isFilterPanelOpen}
        setIsFilterPanelOpen={setIsFilterPanelOpen}
        //
        refetch={refetch}
      />
    </Stack>
  );
};

export default Assets;
