import { IComboBoxOption } from '@fluentui/react';
import { gql } from '@apollo/client';
import { IMailLog } from './MailLog';
import {
  IConstructionSheetRemark,
  IConstructionSite,
  IContactPerson,
  IProperty,
  IPurchaseOrderLine,
  ISupplier,
} from '.';

export const GET_PURCHASE_ORDERS = gql`
  query getPurchaseOrders(
    $filter: PurchaseOrderFilterInput
    $orderBy: PurchaseOrderOrderByInput
    $take: Int
    $skip: Int
  ) {
    findManyPurchaseOrders(
      filter: $filter
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      id
      prisma_total
      creation_date
      delivery_date
      remarks
      description
      delivery_to_other_supplier
      is_default_contact_person
      is_on_call
      pick_up
      on_call_value
      purchase_order_no
      is_purchase_order
      mail_logs {
        id
        from
        to
        subject
        body
        date_created
        cc
        bcc
        attachments
        employee {
          first_name
          last_name
        }
      }
      status {
        id
        label
      }
      contact_person {
        id
        first_name
        last_name
        email
      }
      supplier {
        id
        name
        address
        phone
        fax
        city
        zip_code
        email
        contact
        contact_persons {
          id
          first_name
          last_name
          email
          notes
          mobile
          phone
        }
      }
      deliver_to_supplier {
        id
        name
        address
        phone
        fax
        city
        zip_code
      }
      construction_site {
        id
        name
        address
        city
        zip_code
        
        comments
        project_manager {
          id
          first_name
          last_name
          mobile
        }
        site_manager {
          id
          first_name
          last_name
          mobile
        }
      }
      construction_sheet_remark_id
      construction_sheet_remark {
        id
        label
        remarks
        property_list_type {
          id
          name
        }
      }
      line_items {
        id
        name
        description
        sku
        unit_price
        comment
        quantity
        weight
        measuring_unit {
          id
          name
        }
      }
    }
  }
`;

export const GET_PURCHASE_ORDER_BY_ID = gql`
  query getPurchaseOrderById($where: PurchaseOrderWhereInput) {
    findOnePurchaseOrder(where: $where) {
      id
      creation_date
      delivery_date
      remarks
      description
      delivery_to_other_supplier
      is_default_contact_person
      no_delivery_hour
      is_on_call
      pick_up
      on_call_value
      is_purchase_order
      purchase_order_no
      mail_logs {
        id
        from
        to
        subject
        body
        date_created
        cc
        bcc
        attachments
        employee {
          first_name
          last_name
        }
      }
      contact_person {
        id
        first_name
        last_name
      }
      construction_sheet_remark_id
      construction_sheet_remark {
        id
        label
        remarks
        property_list_type {
          id
          name
        }
      }
      status {
        id
        label
      }
      supplier {
        id
        name
        address
        phone
        fax
        city
        zip_code
        email
        contact
        contact_persons {
          id
          first_name
          last_name
          email
          notes
          mobile
          phone
        }
      }
      deliver_to_supplier {
        id
        name
        address
        phone
        fax
        city
        zip_code
      }
      construction_site {
        id
        name
        address
        city
        zip_code
        comments
        project_manager {
          id
          first_name
          last_name
          mobile
        }
        lead {
          id
          first_name1
          last_name1
          first_name2
          last_name2
        }
        site_manager {
          id
          first_name
          last_name
          mobile
        }
      }
      line_items {
        id
        name
        description
        sku
        unit_price
        comment
        quantity
        weight
        measuring_unit {
          id
          name
        }
      }
    }
  }
`;

export const ADD_PURCHASE_ORDER = gql`
  mutation addPurchaseOrder($data: PurchaseOrderCreateInput!) {
    createPurchaseOrder(data: $data) {
      id
      creation_date
      delivery_date
      remarks
      description
      delivery_to_other_supplier
      is_default_contact_person
      no_delivery_hour
      is_on_call
      pick_up
      on_call_value
      purchase_order_no
      is_purchase_order
      mail_logs {
        id
        from
        to
        subject
        body
        date_created
        cc
        bcc
        attachments
        employee {
          first_name
          last_name
        }
      }
      construction_sheet_remark_id
      construction_sheet_remark {
        id
        label
        remarks
        property_list_type {
          id
          name
        }
      }
      contact_person {
        id
        first_name
        last_name
      }
      supplier {
        id
        name
        address
        phone
        fax
        city
        zip_code
        email
        contact
        contact_persons {
          id
          first_name
          last_name
          email
          notes
          mobile
          phone
        }
      }
      deliver_to_supplier {
        id
        name
        address
        phone
        fax
        city
        zip_code
      }
      status {
        id
        label
      }
      construction_site {
        id
        name
        address
        city
        zip_code
        comments
        project_manager {
          id
          first_name
          last_name
          mobile
        }
      }
      line_items {
        id
        name
        description
        sku
        unit_price
        comment
        quantity
        weight
        measuring_unit {
          id
          name
        }
      }
    }
  }
`;

export const UPDATE_PURCHASE_ORDER = gql`
  mutation updatePurchaseOrder($id: Int!, $data: PurchaseOrderUpdateInput) {
    updatePurchaseOrder(id: $id, data: $data) {
      id
      creation_date
      delivery_date
      remarks
      description
      delivery_to_other_supplier
      is_default_contact_person
      no_delivery_hour
      is_on_call
      pick_up
      on_call_value
      purchase_order_no
      is_purchase_order
      mail_logs {
        id
        from
        to
        subject
        body
        date_created
        cc
        bcc
        attachments
        employee {
          first_name
          last_name
        }
      }
      construction_sheet_remark_id
      construction_sheet_remark {
        id
        label
        remarks
        property_list_type {
          id
          name
        }
      }
      contact_person {
        id
        first_name
        last_name
      }
      supplier {
        id
        name
        address
        phone
        fax
        city
        zip_code
        email
        contact
        contact_persons {
          id
          first_name
          last_name
          email
          notes
          mobile
          mobile_v2
          phone
          phone_v2
        }
      }
      deliver_to_supplier {
        id
        name
        address
        phone
        fax
        city
        zip_code
      }
      construction_site {
        id
        name
        address
        city
        zip_code
        comments
        project_manager {
          id
          first_name
          last_name
          mobile
        }
      }
      status {
        id
        label
      }
      line_items {
        id
        name
        description
        sku
        unit_price
        comment
        quantity
        weight
        measuring_unit {
          id
          name
        }
      }
    }
  }
`;

export const DELETE_PURCHASE_ORDER = gql`
  mutation deletePurchaseOrder($where: PurchaseOrderWhereInput) {
    deletePurchaseOrder(where: $where) {
      id
      creation_date
      delivery_date
      remarks
    }
  }
`;

export const GET_PURCHASE_ORDER_STATUSES = gql`
  query {
    findManyPurchaseOrderStatuses {
      id
      label
    }
  }
`;

export interface IPurchaseOrder {
  id?: number;
  creation_date?: string;
  delivery_date?: string;
  remarks?: string;
  description?: string;
  supplier?: ISupplier;
  contact_person?: IContactPerson;
  construction_site?: IConstructionSite;
  no_delivery_hour?: boolean;
  line_items?: IPurchaseOrderLine[];
  purchase_order_no?: string;
  deliver_to_supplier?: ISupplier;
  delivery_to_other_supplier?: boolean;
  is_default_contact_person?: boolean;
  is_on_call?: boolean;
  is_purchase_order?: boolean;
  on_call_value?: string;
  status?: IPurchaseOrderStatus;
  pick_up?: boolean;
  construction_sheet_remark_id?: number;
  construction_sheet_remark?: IConstructionSheetRemark;
  prisma_total?: number;
  property?: IProperty;
  mail_logs?: IMailLog[];
}

export interface IPurchaseOrderStatus {
  id: number;
  label: string;
}

export const convertStatusesToComboBoxOptions = (
  statuses: IPurchaseOrderStatus[],
) => {
  const result: IComboBoxOption[] = statuses.map(
    (status: IPurchaseOrderStatus): IComboBoxOption => ({
      key: status.id,
      text: status.label,
    }),
  );
  return result;
};
