import { FormEvent, useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useAuth0 } from '@auth0/auth0-react';
import _, { startsWith } from 'lodash';
import { Icon, Stack, TextField } from '@fluentui/react';
import useDebounce from '../../components/hooks/useDebounce';
import {
  default_page_size,
  GET_SUPPLIERS,
  ISupplier,
  stackTokens15,
  textFieldStyles300,
} from '../../utils';
import { useAppDispatch } from '../../redux/hooks';
import { SeverityLevel, throwError } from '../../redux/error/errorSlice';
import SuppliersOverview from './components/SuppliersOverview';

interface IPagedSuppliers {
  suppliers: ISupplier[];
  skip: number;
  take: number;
  filter: string;
  initialLoad: boolean;
  showLoadMore: boolean;
  lazyLoading: boolean;
  isFiltered: boolean;
  isSortedAsc: boolean;
  sortedField: string;
}

const Suppliers = () => {
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useAuth0();
  const [pagedState, setPagedState] = useState<IPagedSuppliers>({
    suppliers: [],
    skip: 0,
    take: default_page_size,
    filter: '',
    showLoadMore: true,
    lazyLoading: true,
    isFiltered: false,
    initialLoad: true,
    isSortedAsc: true,
    sortedField: 'name',
  });

  const [filter, setFilter] = useState('');
  const debouncedValue = useDebounce(filter, 500);

  useEffect(() => {
    setPagedState(prevState => ({ ...prevState, suppliers: [], skip: 0 }));
  }, [filter]);

  const getOrderBy = () => {
    const orderByObject: any = {}; // deliberately kept any as type to index by string
    orderByObject[pagedState.sortedField] = pagedState.isSortedAsc
      ? 'asc'
      : 'desc';
    return orderByObject;
  };

  function transformString(input: string): string {
    return input
      .toLowerCase() // Convert to lowercase
      .normalize('NFD') // Decompose combined characters into their base letters and diacritics
      .replace(/[\u0300-\u036f]/g, '') // Remove diacritical marks
      .replace(/\s+/g, ''); // Remove all spaces
  }

  const getFilters = (filterString: string) => {
    const filterArray = filterString.split(' ');

    const filterObject: any = {
      OR: [],
    };

    const innerFilterObject: any = {
      AND: [],
    };

    for (let i = 0; i < filterArray.length; i++) {
      const filterValue: any = {
        OR: [
          {
            name:
              i !== 0
                ? { startsWith: filterArray[i] }
                : { contains: filterArray[i] },
          },
          { address: { contains: filterArray[i] } },
          { city: { contains: filterArray[i] } },
          { vat_code: { contains: filterArray[i] } },
          { iban: { contains: filterArray[i] } },
          { bic: { contains: filterArray[i] } },
          { comments: { contains: filterArray[i] } },
          { email: { contains: filterArray[i] } },
          { phone_v2: { contains: filterArray[i] } },
          { mobile_v2: { contains: filterArray[i] } },
          { fax: { contains: filterArray[i] } },
          { website: { contains: filterArray[i] } },
        ],
      };

      const numericValue = parseInt(filterArray[i], 10);
      if (numericValue) filterValue.OR.push({ zip_code: { equals: +numericValue } });
      if (numericValue) {
 filterValue.OR.push({
          vat_code: { contains: numericValue.toString() },
        });
}

      innerFilterObject.AND.push(filterValue);
    }

    filterObject.OR.push({
      OR: [
        {
          normalized_name: {
            startsWith: transformString(filterString),
          },
        },
        {
          ...innerFilterObject,
        },
      ],
    });

    return filterObject;
  };

  const getFiltersOld = (filterString: string) => {
    const filterArray = filterString.split(' ');

    const filterObject: any = {
      AND: [],
    };
    for (let i = 0; i < filterArray.length; i++) {
      const filterValue: any = {
        OR: [
          { name: { contains: filterArray[i] } },
          { address: { contains: filterArray[i] } },
          { city: { contains: filterArray[i] } },
          { vat_code: { contains: filterArray[i] } },
          { iban: { contains: filterArray[i] } },
          { bic: { contains: filterArray[i] } },
          { comments: { contains: filterArray[i] } },
          { email: { contains: filterArray[i] } },
          { phone_v2: { contains: filterArray[i] } },
          { mobile_v2: { contains: filterArray[i] } },
          { fax: { contains: filterArray[i] } },
          { website: { contains: filterArray[i] } },
        ],
      };

      const numericValue = parseInt(filterArray[i], 10);
      if (numericValue) filterValue.OR.push({ zip_code: { equals: +numericValue } });
      if (numericValue) filterValue.OR.push({ vat_code: { equals: numericValue } });
      filterObject.AND.push(filterValue);
    }

    return filterObject;
  };

  const {
    data: dataSuppliers,
    loading,
    error,
    fetchMore,
  } = useQuery(GET_SUPPLIERS, {
    variables: {
      filter: getFilters(debouncedValue),
      take: pagedState.take,
      skip: pagedState.skip,
      orderBy: getOrderBy(),
    },
    // notifyOnNetworkStatusChange: true,
    onError: error => {
      dispatch(
        throwError({
          module: 'car Overview',
          message: error.message,
          level: SeverityLevel.Critical,
        }),
      );
    },
    // fetchPolicy: 'cache-and-network', comment, we want to use default fetchPolicy
  });

  const onSearchValueChange = (
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined,
  ) => {
    const filterString = newValue ? newValue.toLowerCase() : '';
    setFilter(filterString);
  };

  const setSorting = (isSortedAsc: boolean, sortedField: string) => {
    clearSuppliers();
    setPagedState(prevState => ({
      ...prevState,
      isSortedAsc,
      sortedField,
    }));
  };

  const clearSuppliers = () => {
    setPagedState(prevState => ({
      ...prevState,
      suppliers: [],
      skip: 0,
    }));
  };

  const fetchMoreSuppliers = () => {
    // Load more suppliers

    fetchMore({
      variables: {
        filter: getFilters(debouncedValue),
        take: pagedState.take,
        skip: pagedState.skip + pagedState.take,
        orderBy: getOrderBy(),
      },
    });

    setPagedState(prevState => ({
      ...prevState,
      skip: pagedState.skip + pagedState.take,
    }));
  };

  if (!isAuthenticated) return <p>Verboden</p>;
  if (error) return <p>Oeps, er ging iets mis...</p>;

  return (
    <Stack tokens={stackTokens15}>
      <div style={{ padding: '25px' }}>
        <h3
          style={{
            fontSize: '20px',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <Icon iconName='UserEvent' style={{ fontSize: '20px' }} />
          Leveranciers
        </h3>

        <TextField
          label='Zoeken...'
          value={filter}
          styles={textFieldStyles300}
          onChange={onSearchValueChange}
        />
      </div>

      <SuppliersOverview
        suppliers={dataSuppliers ? dataSuppliers.findManySuppliers : []}
        loading={loading}
        setSorting={setSorting}
        isSortedAsc={pagedState.isSortedAsc}
        sortedField={pagedState.sortedField}
        showLoadMore={pagedState.showLoadMore}
        loadMore
        loadMoreCallback={fetchMoreSuppliers}
        lazyLoading={pagedState.lazyLoading}
      />
    </Stack>
  );
};

export default Suppliers;
