import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  DefaultButton,
  Icon,
  Label,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  StackItem,
  Text,
  TextField,
} from '@fluentui/react';
import _ from 'lodash';
import PhoneInput from 'react-phone-input-2';
import { toast } from 'react-toastify';
import { useAuth0 } from '@auth0/auth0-react';
import SuggestedView from '../../../components/parts/SuggestedView';
import {
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_TOAST_POSITION,
  iconProps,
  modalContentStyles,
} from '../../../utils';
import { Accordion, AccordionItem } from '../../../components/parts/Accordion';
import { getDefaultContactPerson } from '../../../utils/ContactPerson';
import SaveButtonWithPermissions from '../../../components/parts/SaveButtonWithPermissions';
import ZipCodeFetcher from '../../../components/DynamicZip';
import { customPanelStyles } from '../../../theme';
import { toastSuccess } from '../../../utils/toast';

const SupplierDetail = ({
  isOpen,
  dismissPanel,
  saveSupplier,
  supplier,
  setSupplier,
  refetchSupplier,
}: any) => {
  const { getAccessTokenSilently } = useAuth0();
  const [emailChanged, setEmailChanged] = useState<boolean>(false);

  useEffect(() => {
    setEmailChanged(false);
  }, [supplier?.id]);

  const [selectedValueItem, setSelectedValueItem] = useState<
    number | string | undefined
  >();

  const addContactPerson = () => {
    const defaultContactPerson = getDefaultContactPerson();
    const currentSupplier = _.cloneDeep(supplier);
    if (currentSupplier.contact_persons) {
      currentSupplier.contact_persons.push(defaultContactPerson);
    } else {
      currentSupplier.contact_persons = [defaultContactPerson];
    }
    setSupplier(currentSupplier);
  };

  const toggleDeleteContactPerson = (index: number) => {
    const contactPersons = supplier.contact_persons;
    contactPersons[index] = {
      ...contactPersons[index],
      delete: !contactPersons[index].delete,
    };
    setSupplier((prevState: any) => ({
      ...prevState,
      contact_persons: contactPersons,
    }));
  };

  const onChangeTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    if ((event.target as HTMLTextAreaElement).name === 'email') {
      setEmailChanged(true);
    }
    setSupplier((prevState: any) => ({
      ...prevState,
      [(event.target as HTMLTextAreaElement).name]: newValue || '',
    }));
  };

  const onChangeContactTextFieldValue = (
    index: number,
    event?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
    fieldName?: string,
  ) => {
    if (supplier) {
      const contactPersons = supplier.contact_persons;
      if (fieldName) {
        contactPersons[index] = {
          ...contactPersons[index],
          [fieldName]: newValue || '',
          dirty: true,
        };
      } else if (event) {
        contactPersons[index] = {
          ...contactPersons[index],
          [(event.target as HTMLTextAreaElement).name]: newValue || '',
          dirty: true,
        };
      }

      setSupplier((prevState: any) => ({
        ...prevState,
        contact_persons: contactPersons,
      }));
    }
  };

  const action = async (id: string, email: string, action: string) => {
    toast.promise(
      (async () => {
        try {
          const url = `${process.env.REACT_APP_BACKEND_URI}/chimp/${action}-supplier`;
          const token = await getAccessTokenSilently();

          const res = await fetch(url, {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              leadId: id,
              emailAddress: email,
            }),
          });

          if (!res.ok) throw new Error(`${action} failed`);

          const result = await res.json();

          await refetchSupplier();

          toastSuccess(result.message || `Successfully ${action}!`);
        } catch (error: any) {
          console.error(`${action} failed`, error);
          throw error;
        }
      })(),
      {
        pending: {
          position: DEFAULT_TOAST_POSITION,
          render() {
            return DEFAULT_LOADING_MESSAGE;
          },
        },
        error: {
          render({ data }: { data: any }) {
            return data.message || `Er is een fout opgetreden bij ${action}`;
          },
        },
      },
    );
  };

  const formatStatus = (status: string) => {
    if (status.toUpperCase() === 'SUBSCRIBED') return 'Ingeschreven';
    if (status.toUpperCase() === 'UNSUBSCRIBED') return 'Uitgeschreven';

    // status is all caps but i want to capitalize the first letter and the rest of the word should be lowercase
    return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
  };

  return (
    <Panel
      isLightDismiss
      isOpen={isOpen}
      onDismiss={dismissPanel}
      closeButtonAriaLabel='Close'
      headerText={
        supplier && supplier.id
          ? 'Leverancier wijzigen'
          : 'Leverancier toevoegen'
      }
      type={PanelType.custom}
      customWidth='700px'
      styles={customPanelStyles}
    >
      <div>
        <div className={modalContentStyles.header} />
        <div className={modalContentStyles.body}>
          <Stack>
            <Stack style={{ marginBottom: 10 }}>
              <TextField
                name='name'
                label='Naam'
                value={supplier && supplier.name ? supplier.name : ''}
                onChange={onChangeTextFieldValue}
                required
                errorMessage={
                  supplier && !supplier.name ? 'Dit veld is verplicht' : ''
                }
              />
            </Stack>

            <Stack style={{ marginBottom: 5 }}>
              <TextField
                name='address'
                label='Adres'
                value={supplier && supplier.address ? supplier.address : ''}
                onChange={onChangeTextFieldValue}
              />
            </Stack>

            <Stack
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: 10,
              }}
            >
              <StackItem style={{ width: '25%' }}>
                <ZipCodeFetcher
                  object={supplier}
                  setObject={setSupplier}
                  zipFieldName='zip_code'
                  cityFieldName='city'
                />
              </StackItem>
              <StackItem style={{ width: '74%' }}>
                <TextField
                  name='city'
                  value={supplier && supplier.city ? supplier.city : ''}
                  onChange={onChangeTextFieldValue}
                />
              </StackItem>
            </Stack>

            <Stack
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: 10,
              }}
            >
              <StackItem style={{ width: '49.5%' }}>
                <Label>Telefoon</Label>
                <PhoneInput
                  country='be'
                  preferredCountries={['be']}
                  value={supplier && supplier.phone_v2}
                  onChange={phone =>
                    setSupplier((prevState: any) => ({
                      ...prevState,
                      phone_v2: phone,
                    }))
                  }
                  inputStyle={{
                    width: '100%',
                    border: '1px solid rgb(96, 94, 92)',
                    borderRadius: '2px',
                  }}
                  buttonStyle={{
                    border: '1px solid rgb(96, 94, 92)',
                    borderRadius: '2px',
                  }}
                />
                {supplier && !supplier.phone_v2 && supplier.phone && (
                  <div>{supplier.phone}</div>
                )}
              </StackItem>
              <StackItem style={{ width: '49.5%' }}>
                <Label>GSM</Label>
                <PhoneInput
                  country='be'
                  preferredCountries={['be']}
                  value={supplier && supplier.mobile_v2}
                  onChange={phone =>
                    setSupplier((prevState: any) => ({
                      ...prevState,
                      mobile_v2: phone,
                    }))
                  }
                  inputStyle={{
                    width: '100%',
                    border: '1px solid rgb(96, 94, 92)',
                    borderRadius: '2px',
                  }}
                  buttonStyle={{
                    border: '1px solid rgb(96, 94, 92)',
                    borderRadius: '2px',
                  }}
                />
                {supplier && !supplier.mobile_v2 && supplier.mobile && (
                  <div>{supplier.mobile}</div>
                )}
              </StackItem>
            </Stack>
            <Stack style={{ marginBottom: 10 }}>
              <TextField
                name='email'
                label='Email'
                value={supplier && supplier.email ? supplier.email : ''}
                onChange={onChangeTextFieldValue}
              />
            </Stack>
            <Stack style={{ marginBottom: 10 }}>
              <Checkbox
                label='Geen marketing'
                disabled
                checked={
                  supplier && supplier.email_opt_out
                    ? supplier.email_opt_out
                    : false
                }
                onChange={(e, checked) => {
                  setSupplier((prevState: any) => ({
                    ...prevState,
                    email_opt_out: checked,
                  }));
                }}
              />
            </Stack>
            {supplier && supplier.id && !emailChanged ? (
              <Stack
                style={{
                  marginBottom: 30,
                  marginTop: 30,
                  backgroundColor: '#fbeeca',
                  padding: 12,
                  borderRadius: 2,
                  boxShadow: '0 1px 4px rgba(0, 0, 0, 0.05)',
                }}
              >
                <Stack
                  horizontal
                  horizontalAlign='space-between'
                  verticalAlign='center'
                  wrap
                  tokens={{ childrenGap: 12 }}
                >
                  {/* Mailchimp Logo */}
                  <Stack
                    horizontal
                    verticalAlign='center'
                    style={{ gap: 10, minWidth: 100, flexShrink: 0 }}
                  >
                    <div
                      style={{
                        width: 32,
                        height: 32,
                        backgroundColor: '#FFE01B',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: 4,
                        boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
                      }}
                    >
                      <img
                        src='/mailchimp.svg'
                        height={22}
                        width={22}
                        alt='Mailchimp'
                      />
                    </div>
                    <Text variant='small' style={{ fontWeight: 600 }}>
                      Mailchimp
                    </Text>
                  </Stack>

                  {/* Email Sections Container */}
                  <Stack
                    horizontal
                    tokens={{ childrenGap: 8 }}
                    style={{
                      flexGrow: 1,
                      width:
                        supplier?.email && supplier?.email
                          ? 'calc(100% - 200px)'
                          : 'auto',
                    }}
                  >
                    {/* Email 1 Section */}
                    {supplier?.email && (
                      <Stack
                        horizontal
                        verticalAlign='center'
                        style={{
                          backgroundColor: '#ffffff',
                          padding: '6px 10px',
                          borderRadius: 3,
                          border: '1px solid #e8e8e8',
                          flexGrow: 1,
                          flexBasis: supplier?.email ? '50%' : '100%',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Stack
                          horizontal
                          verticalAlign='center'
                          style={{
                            gap: 8,
                            flexGrow: 1,
                            overflow: 'hidden',
                            maxWidth: '60%',
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: '#EFEEEB',
                              width: 22,
                              height: 22,
                              borderRadius: '50%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              flexShrink: 0,
                            }}
                          >
                            <Icon
                              iconName='Mail'
                              style={{ fontSize: 12, color: '#241C16' }}
                            />
                          </div>
                          <Text
                            style={{
                              fontSize: 12,
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              color: '#241C16',
                            }}
                          >
                            {supplier.email}
                          </Text>
                        </Stack>

                        <Stack
                          horizontal
                          style={{
                            gap: 8,
                            flexShrink: 0,
                            alignItems: 'center',
                          }}
                        >
                          <span
                            style={{
                              backgroundColor:
                                supplier.email_mailchimp_status.toUpperCase() ===
                                'SUBSCRIBED'
                                  ? '#d8eacc'
                                  : supplier.email_mailchimp_status.toUpperCase() ===
                                    'UNSUBSCRIBED'
                                  ? '#fbeeca'
                                  : supplier.email_mailchimp_status.toUpperCase() ===
                                    'ERROR'
                                  ? '#FBCFBD'
                                  : '#EFEEEB',
                              padding: '3px 6px',
                              borderRadius: 2,
                              fontSize: 12,
                              fontWeight: 500,
                              color:
                                supplier.email_mailchimp_status.toUpperCase() ===
                                'SUBSCRIBED'
                                  ? '#241C16'
                                  : supplier.email_mailchimp_status.toUpperCase() ===
                                    'UNSUBSCRIBED'
                                  ? '#241C16'
                                  : '#241C16',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {formatStatus(supplier.email_mailchimp_status)}
                          </span>

                          {supplier.email_mailchimp_status.toUpperCase() ===
                          'SUBSCRIBED' ? (
                            <button
                              type='button'
                              style={{
                                backgroundColor: 'transparent',
                                border: '1px solid #241C16',
                                borderRadius: '3px',
                                cursor: 'pointer',
                              }}
                              onClick={async e => {
                                e.preventDefault();
                                await action(
                                  supplier.id,
                                  supplier.email,
                                  'unsubscribe',
                                );
                              }}
                            >
                              <Icon
                                iconName='UserRemove'
                                style={{ fontSize: 12, color: '#241C16' }}
                              />
                            </button>
                          ) : supplier.email_mailchimp_status.toUpperCase() ===
                            'UNSUBSCRIBED' ? (
                              <button
                                type='button'
                                style={{
                                backgroundColor: 'transparent',
                                border: '1px solid #241C16',
                                borderRadius: '3px',
                                cursor: 'pointer',
                              }}
                                onClick={async e => {
                                e.preventDefault();
                                await action(
                                  supplier.id,
                                  supplier.email,
                                  'subscribe',
                                );
                              }}
                              >
                                <Icon
                                  iconName='AddFriend'
                                  style={{ fontSize: 12, color: '#241C16' }}
                                />
                              </button>
                          ) : (
                            <button
                              type='button'
                              style={{
                                backgroundColor: 'transparent',
                                border: '1px solid #241C16',
                                borderRadius: '3px',
                                cursor: 'pointer',
                              }}
                              onClick={async e => {
                                e.preventDefault();
                                await action(
                                  supplier.id,
                                  supplier.email,
                                  'subscribe',
                                );
                              }}
                            >
                              <Icon
                                iconName='AddFriend'
                                style={{ fontSize: 12, color: '#241C16' }}
                              />
                            </button>
                          )}
                        </Stack>
                      </Stack>
                    )}
                  </Stack>

                  {/* No emails message */}
                  {!supplier?.email && (
                    <Text style={{ fontSize: 12, color: '#8a8886' }}>
                      Geen email adressen beschikbaar
                    </Text>
                  )}
                </Stack>
              </Stack>
            ) : (
              <Stack
                style={{
                  marginBottom: 30,
                  marginTop: 30,
                  backgroundColor: '#fbeeca',
                  padding: 12,
                  borderRadius: 2,
                  boxShadow: '0 1px 4px rgba(0, 0, 0, 0.05)',
                }}
              >
                <Stack
                  horizontal
                  horizontalAlign='space-between'
                  verticalAlign='center'
                  wrap
                  tokens={{ childrenGap: 12 }}
                >
                  {/* Mailchimp Logo */}
                  <Stack
                    horizontal
                    verticalAlign='center'
                    style={{ gap: 10, minWidth: 100, flexShrink: 0 }}
                  >
                    <div
                      style={{
                        width: 32,
                        height: 32,
                        backgroundColor: '#FFE01B',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: 4,
                        boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
                      }}
                    >
                      <img
                        src='/mailchimp.svg'
                        height={22}
                        width={22}
                        alt='Mailchimp'
                      />
                    </div>
                    <Text variant='small' style={{ fontWeight: 600 }}>
                      Mailchimp
                    </Text>
                  </Stack>

                  {/* Email Sections Container */}
                  <Stack
                    horizontal
                    tokens={{ childrenGap: 8 }}
                    style={{
                      flexGrow: 1,
                      width:
                        supplier?.email && supplier?.email
                          ? 'calc(100% - 200px)'
                          : 'auto',
                    }}
                  >
                    <Text style={{ fontSize: 12, color: '#8a8886' }}>
                      Instellingen zijn beschikbaar na het opslaan.
                    </Text>
                  </Stack>

                </Stack>
              </Stack>
            )}
            <Stack style={{ marginBottom: 10 }}>
              <TextField
                name='vat_code'
                label='BTW nummer'
                value={supplier && supplier.vat_code ? supplier.vat_code : ''}
                onChange={onChangeTextFieldValue}
              />
            </Stack>

            <Stack style={{ marginBottom: 10 }}>
              <TextField
                name='iban'
                label='Bankrekeningnummer (IBAN)'
                value={supplier && supplier.iban ? supplier.iban : ''}
                onChange={onChangeTextFieldValue}
              />
            </Stack>

            <Stack style={{ marginBottom: 10 }}>
              <TextField
                name='bic'
                label='BIC-code'
                value={supplier && supplier.bic ? supplier.bic : ''}
                onChange={onChangeTextFieldValue}
              />
            </Stack>

            <Stack style={{ marginBottom: 10 }}>
              <SuggestedView
                item={supplier}
                setItem={setSupplier}
                prefix='sector'
                code={
                  supplier &&
                  supplier.sector &&
                  supplier.sector.substr(supplier.sector.length - 4)
                }
                label='Sector'
                field='sector'
              />
            </Stack>

            <Stack style={{ marginBottom: 10 }}>
              <Label style={{ marginBottom: -8 }}>Contactpersonen</Label>
              <Accordion
                selectedKey={selectedValueItem}
                defaultKey={undefined}
                toggleItem={(key: string | number) => {
                  if (selectedValueItem === key) {
                    setSelectedValueItem(undefined);
                  } else setSelectedValueItem(key);
                }}
              >
                {supplier &&
                  supplier.contact_persons &&
                  supplier.contact_persons.map(
                    (contact_person: any, i: number) => (
                      <AccordionItem
                        key={contact_person.id || `temp${i.toString()}`}
                        id={contact_person.id || `temp${i.toString()}`}
                        title={
                          <Stack horizontal>
                            <Stack.Item
                              style={{
                                textDecoration: contact_person.delete
                                  ? 'line-through'
                                  : undefined,
                                marginRight: '10px',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                maxWidth: '200px',
                              }}
                            >
                              {contact_person.draft && (
                                <Icon iconName='LocationDot' />
                              )}
                              {`${contact_person.first_name} ${contact_person.last_name}`}
                            </Stack.Item>
                            <Stack.Item>
                              <Icon
                                iconName={
                                  contact_person.delete ? 'Refresh' : 'Delete'
                                }
                                styles={iconProps}
                                onClick={() => {
                                  toggleDeleteContactPerson(i);
                                }}
                              />
                            </Stack.Item>
                          </Stack>
                        }
                      >
                        <Stack style={{ marginBottom: 10 }}>
                          <TextField
                            name='first_name'
                            label='Voornaam'
                            value={
                              contact_person && contact_person.first_name
                                ? contact_person.first_name
                                : ''
                            }
                            onChange={(
                              event: React.FormEvent<
                                HTMLInputElement | HTMLTextAreaElement
                              >,
                              newValue?: string,
                            ) =>
                              onChangeContactTextFieldValue(i, event, newValue)
                            }
                          />
                          <TextField
                            name='last_name'
                            label='Achternaam'
                            value={
                              contact_person && contact_person.last_name
                                ? contact_person.last_name
                                : ''
                            }
                            onChange={(
                              event: React.FormEvent<
                                HTMLInputElement | HTMLTextAreaElement
                              >,
                              newValue?: string,
                            ) =>
                              onChangeContactTextFieldValue(i, event, newValue)
                            }
                          />
                          <TextField
                            name='email'
                            label='E-mailadres'
                            value={
                              contact_person && contact_person.email
                                ? contact_person.email
                                : ''
                            }
                            onChange={(
                              event: React.FormEvent<
                                HTMLInputElement | HTMLTextAreaElement
                              >,
                              newValue?: string,
                            ) =>
                              onChangeContactTextFieldValue(i, event, newValue)
                            }
                          />
                          <Label>Telefoon</Label>
                          <PhoneInput
                            country='be'
                            preferredCountries={['be']}
                            value={contact_person && contact_person.phone_v2}
                            onChange={phone =>
                              onChangeContactTextFieldValue(
                                i,
                                undefined,
                                phone,
                                'phone_v2',
                              )
                            }
                            inputStyle={{
                              width: '100%',
                              border: '1px solid rgb(96, 94, 92)',
                              borderRadius: '2px',
                            }}
                            buttonStyle={{
                              border: '1px solid rgb(96, 94, 92)',
                              borderRadius: '2px',
                            }}
                          />
                          {contact_person &&
                            !contact_person.phone_v2 &&
                            contact_person.phone && (
                              <div>{contact_person.phone}</div>
                            )}
                          <Label>GSM</Label>
                          <PhoneInput
                            country='be'
                            preferredCountries={['be']}
                            value={contact_person && contact_person.mobile_v2}
                            onChange={phone =>
                              onChangeContactTextFieldValue(
                                i,
                                undefined,
                                phone,
                                'mobile_v2',
                              )
                            }
                            inputStyle={{
                              width: '100%',
                              border: '1px solid rgb(96, 94, 92)',
                              borderRadius: '2px',
                            }}
                            buttonStyle={{
                              border: '1px solid rgb(96, 94, 92)',
                              borderRadius: '2px',
                            }}
                          />
                          {contact_person &&
                            !contact_person.mobile_v2 &&
                            contact_person.mobile && (
                              <div>{contact_person.mobile}</div>
                            )}
                          <TextField
                            name='notes'
                            multiline
                            label='Extra informatie'
                            value={
                              contact_person && contact_person.notes
                                ? contact_person.notes
                                : ''
                            }
                            onChange={(
                              event: React.FormEvent<
                                HTMLInputElement | HTMLTextAreaElement
                              >,
                              newValue?: string,
                            ) =>
                              onChangeContactTextFieldValue(i, event, newValue)
                            }
                          />
                        </Stack>
                        {/* Contact (contact, email, email opt out, phone, fax,
                        mobile, website) */}
                      </AccordionItem>
                    ),
                  )}
              </Accordion>
              <Stack.Item style={{ marginBottom: '10px', marginTop: '10px' }}>
                <PrimaryButton
                  text='Voeg een contactpersoon toe'
                  disabled={!supplier || (supplier && !supplier.id)}
                  onClick={addContactPerson}
                />
                {((supplier && !supplier.id) || !supplier) && (
                  <div>Na opslaan kan je contactpersonen toevoegen.</div>
                )}
              </Stack.Item>
            </Stack>

            <Stack
              style={{
                marginBottom: 10,
                flexDirection: 'row',
                alignItems: 'flex-end',
              }}
            >
              <StackItem style={{ width: '50%' }}>
                <TextField
                  name='payment_terms'
                  label='Betalingstermijn'
                  type='number'
                  value={
                    supplier && supplier.payment_terms
                      ? supplier.payment_terms
                      : ''
                  }
                  onChange={onChangeTextFieldValue}
                />
              </StackItem>
              <StackItem
                style={{ marginBottom: 7, marginLeft: 8, fontWeight: 600 }}
              >
                dagen
              </StackItem>
            </Stack>

            <Stack style={{ marginBottom: 10 }}>
              <TextField
                name='wings_code'
                label='Wings code'
                type='number'
                value={
                  supplier && supplier.wings_code ? supplier.wings_code : ''
                }
                onChange={onChangeTextFieldValue}
              />
            </Stack>

            <Stack style={{ marginBottom: 10 }}>
              <TextField
                name='comments'
                multiline
                label='Extra informatie'
                value={supplier && supplier.comments ? supplier.comments : ''}
                onChange={onChangeTextFieldValue}
              />
            </Stack>
          </Stack>
        </div>
        <div className={modalContentStyles.footer}>
          <Stack style={{ flexDirection: 'row', marginTop: 10 }}>
            <SaveButtonWithPermissions
              disabled={!supplier || (supplier && !supplier.name)}
              save={saveSupplier}
              permission='write:suppliers'
            />

            <StackItem>
              <DefaultButton onClick={dismissPanel}>Annuleren</DefaultButton>
            </StackItem>
          </Stack>
        </div>
      </div>
    </Panel>
  );
};

export default SupplierDetail;
