import moment from 'moment';
import { useState } from 'react';
import {
  DetailsListLayoutMode,
  IColumn,
  IObjectWithKey,
  ISelection,
  SelectionMode,
} from '@fluentui/react';
import { IPurchaseOrder } from '../../../utils';
import { DetailsListDefault } from '../../../components/parts';
import Label from '../../../components/parts/Label';

interface Props {
  items: IPurchaseOrder[];
  selection?: ISelection<IObjectWithKey> | undefined;
  onItemInvoked?: () => void;
  enableShimmer?: boolean;
  isSortedAsc?: boolean;
  sortedField: string;
  setSorting: (isDesc: boolean, field: string) => void;
  loadMore?: boolean;
  lazyLoading?: boolean;
  loading?: boolean;
  loadMoreCallback?: () => void;
  showLoadMore?: boolean;
}

const PurchaseOrdersDetailsList = ({
  items,
  selection,
  onItemInvoked,
  enableShimmer = false,
  isSortedAsc = false,
  sortedField,
  setSorting,
  loadMore,
  lazyLoading,
  loading,
  loadMoreCallback,
  showLoadMore,
}: Props) => {
  const onColumnClick = (event: any, column: any) => {
    const sortedColumn = columns.filter((col: IColumn) => col.isSorted);
    let fieldName = '';
    let sortDescending = false;

    if (sortedColumn) {
      const newColumns = columns.map((col: IColumn) => {
        if (col.fieldName === column.fieldName) {
          col.isSorted = true;
          col.isSortedDescending =
            column.fieldName !== sortedColumn[0].fieldName
              ? false
              : !col.isSortedDescending;

          fieldName = column.fieldName;
          sortDescending = col.isSortedDescending;
        } else {
          col.isSorted = false;
        }
        return col;
      });
      setColumns(newColumns);

      setSorting(!sortDescending, fieldName);
    }
  };

  const columnsList = [
    {
      key: 'column1',
      name: 'Nr',
      fieldName: 'purchase_order_no',
      minWidth: 50,
      maxWidth: 50,
      isRowHeader: true,
      isResizable: false,
      onColumnClick,
      onRender: (item: IPurchaseOrder) => (
        <span>{item.purchase_order_no || ''}</span>
      ),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column2',
      name: 'Beschrijving',
      fieldName: 'description',
      minWidth: 190,
      maxWidth: 190,
      isRowHeader: true,
      isResizable: false,
      onColumnClick,
      onRender: (item: IPurchaseOrder) => <span>{item.description || ''}</span>,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column3',
      name: 'Leverancier',
      fieldName: 'supplier',
      minWidth: 240,
      maxWidth: 240,
      isRowHeader: true,
      isResizable: false,
      onColumnClick,
      onRender: (item: IPurchaseOrder) => (
        <span>{item.supplier?.name || ''}</span>
      ),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column4',
      name: 'Werf',
      fieldName: 'construction_site',
      minWidth: 150,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: false,
      onColumnClick,
      onRender: (item: IPurchaseOrder) => (
        <span>{item.construction_site?.name || ''}</span>
      ),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column5',
      name: 'Status',
      fieldName: 'status',
      minWidth: 90,
      maxWidth: 90,
      isRowHeader: true,
      isResizable: false,
      onColumnClick,
      onRender: (item: IPurchaseOrder) => (
        <Label
          color={
            item.status
              ? item.status.id === 1
                ? 'green'
                : item.status.id === 2
                ? 'blue'
                : item.status.id === 3
                ? 'orange'
                : 'gray'
              : 'gray'
          }
        >
          <span
            style={{
              padding:
                item?.status?.id === 1
                  ? '0px 12px'
                  : item?.status?.id === 2
                  ? '0px 2px'
                  : '0px 0px',
            }}
          >
            {item.status?.label || ''}
          </span>
        </Label>
      ),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column6',
      name: 'Aangemaakt',
      fieldName: 'creation_date',
      minWidth: 80,
      maxWidth: 80,
      isRowHeader: true,
      isResizable: false,
      isSorted: true,
      isSortedDescending: true,
      onColumnClick,
      onRender: (item: IPurchaseOrder) => (
        <span>
          {item.creation_date &&
            moment(item.creation_date).format('DD/MM/YYYY')}
        </span>
      ),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column7',
      name: 'Leverdatum',
      fieldName: 'delivery_date',
      minWidth: 80,
      maxWidth: 80,
      isRowHeader: true,
      isResizable: false,
      onColumnClick,
      onRender: (item: IPurchaseOrder) => (
        <span>
          {item.delivery_date
            ? moment(item.delivery_date).format('DD/MM/YYYY')
            : null}
        </span>
      ),
      data: 'string',
      isPadded: true,
    },
  ];

  const initColumns = (sortedField: string, isSortedAsc: boolean) =>
    columnsList.map((column: IColumn) => {
      if (column.fieldName === sortedField) {
        column.isSorted = true;
        column.isSortedDescending = !isSortedAsc;
        return column;
      }
      return column;
    });

  const [columns, setColumns] = useState(initColumns(sortedField, isSortedAsc));

  return (
    <DetailsListDefault
      items={items}
      columns={columns}
      layoutMode={DetailsListLayoutMode.justified}
      selection={selection}
      selectionMode={SelectionMode.single}
      selectionPreservedOnEmptyClick
      onItemInvoked={onItemInvoked}
      enableShimmer={enableShimmer}
      loadMore={loadMore}
      lazyLoading={lazyLoading}
      loadMoreCallback={loadMoreCallback}
      loading={loading}
    />
  );
};

export default PurchaseOrdersDetailsList;
