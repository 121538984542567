import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useAuth0 } from '@auth0/auth0-react';
import { IComboBoxOption } from '@fluentui/react';

import {
  GET_SUGGESTED_VALUES_BY_CATEGORY_CODE,
  convertSuggestedValuesToComboBoxOptions,
} from '../../utils';
import { ComboboxWithFilter } from '.';

interface ISuggestedValueView {
  onChange: any;
  prefix: string;
  label?: string;
  code?: string;
  disabled?: boolean;
  section: any;
  field: any;
}

const SuggestedViewFilter = ({
  onChange,
  prefix,
  label,
  code,
  disabled = false,
  section,
  field,
}: ISuggestedValueView) => {
  const { isAuthenticated } = useAuth0();

  const { loading, error, data } = useQuery(
    GET_SUGGESTED_VALUES_BY_CATEGORY_CODE,
    {
      variables: {
        filter: {
          suggested_value_category: {
            suggested_value_code_prefix: {
              contains: prefix,
            },
          },

        },
        orderBy: {
          suggested_value: 'asc',
        },
      },
      skip: prefix === undefined || prefix.length === 0,
    },
  );

  if (!isAuthenticated) return <p>Verboden</p>;
  if (loading && !data) return <p>Laden...</p>;
  if (error) return <p>Fout :(</p>;

  const comboboxSuggestedValueOptions: IComboBoxOption[] = convertSuggestedValuesToComboBoxOptions(data?.findManySuggestedValues);

  if (!comboboxSuggestedValueOptions.some(e => e.key === 'ALLES')) {
    comboboxSuggestedValueOptions.unshift({
      key: 'ALLES',
      text: 'Alles',
    });
  }

  return (
    <ComboboxWithFilter
      disabled={disabled}
      label={label || undefined}
      options={comboboxSuggestedValueOptions}
      value={code ? [+code] : undefined}
      multiline={false}
      callBack={(newValue: IComboBoxOption[]) => {
        if (newValue && newValue.length > 0) {
          onChange(section, field, newValue[0].key as number);
        }
      }}
    />
  );
};

export default SuggestedViewFilter;
