import { useState, useEffect } from 'react';
import {
  ICommandBarItemProps,
  PrimaryButton,
  Selection,
  Stack,
} from '@fluentui/react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import {
  ADD_SUPPLIER,
  GET_SUPPLIER_BY_ID,
  ISupplier,
  UPDATE_SUPPLIER,
} from '../../../utils/Supplier';
import { commandBarTheme, commandBarThemeCounter } from '../../../theme';
import { CommandBarSticky } from '../../../components/parts';
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_TOAST_DURATION,
  DEFAULT_TOAST_POSITION,
  IContactPerson,
} from '../../../utils';
import { toastError, toastSuccess } from '../../../utils/toast';
import SupplierDetail from './SupplierDetail';
import SuppliersDetailsList from './SupplierDetailsList';

interface Props {
  loadMore?: boolean;
  lazyLoading?: boolean;
  loading?: boolean;
  loadMoreCallback?: () => void;
  suppliers: ISupplier[];
  setSorting: (isSortedAsc: boolean, sortedField: string) => void;
  isSortedAsc?: boolean;
  sortedField?: string;
  showLoadMore?: boolean;
}

const SuppliersOverview = ({
  suppliers,
  loading,
  loadMore,
  setSorting = (isSortedAsc: boolean, sortedField: string) => {},
  isSortedAsc = false,
  sortedField = '',
  showLoadMore,
  lazyLoading,
  loadMoreCallback = () => ({}),
}: any) => {
  const [supplier, setSupplier] = useState<ISupplier | undefined>(undefined);
  const [isPanelOpen, setIsPanelOpen] = useState(false);

  const [addSupplier] = useMutation(ADD_SUPPLIER, {
    // refetchQueries: [{ query: GET_SUPPLIERS }],
  });

  const [modifySupplier] = useMutation(UPDATE_SUPPLIER, {});

  // Selection
  const [selectionDetails, setSelectionDetails] = useState<
    ISupplier | undefined
  >();

  const getSelectionDetails = () => {
    const currentSelection: any = selection.getSelection();

    if (currentSelection.length > 0) {
      setSelectionDetails(currentSelection[0]);
    } else {
      setSelectionDetails(undefined);
    }
  };

  const selection = new Selection({
    onSelectionChanged: getSelectionDetails,
  });

  const { data: supplierData, refetch: refetchSupplier } = useQuery(GET_SUPPLIER_BY_ID, {
    // notifyOnNetworkStatusChange: true,
    variables: selectionDetails
      ? { where: { id: selectionDetails.id } }
      : undefined,
    skip: !selectionDetails,
    onCompleted: data => {
      setSupplier(data.findOneSupplier);
      },
    });

  const openSupplierDetail = (newSupplier?: boolean) => {
    if (selectionDetails && !newSupplier) {
      //  getSupplier({ variables: { where: { id: selectionDetails.id } } });
    } else {
      setSupplier(undefined);
    }
    setIsPanelOpen(true);
  };

  const saveSupplier = async () => {
    try {
      if (supplier) {
        const res = await toast.promise(
          new Promise((resolve, reject) => {
            const drafts =
              supplier.contact_persons &&
              supplier.contact_persons
                .filter((item: IContactPerson) => item.draft && item.dirty)
                .map((item: IContactPerson) => ({
                  email: item.email,
                  fax: item.fax,
                  first_name: item.first_name,
                  last_name: item.last_name,
                  mobile_v2: item.mobile_v2,
                  notes: item.notes,
                  phone_v2: item.phone_v2,
                }));

            const updates =
              supplier.contact_persons &&
              supplier.contact_persons
                .filter((item: IContactPerson) => !item.draft && item.dirty)
                .map((item: IContactPerson) => ({
                  where: { id: item.id },
                  data: {
                    email: item.email,
                    fax: item.fax,
                    first_name: item.first_name,
                    last_name: item.last_name,
                    mobile_v2: item.mobile_v2,
                    notes: item.notes,
                    phone_v2: item.phone_v2,
                  },
                }));

            const deletes =
              supplier.contact_persons &&
              supplier.contact_persons
                .filter((item: IContactPerson) => item.delete && !item.draft)
                .map((item: IContactPerson) => ({
                  id: item.id,
                }));

            const allInput = {
              name: supplier.name,
              address: supplier.address,
              city: supplier.city,
              comments: supplier.comments,
              zip_code: supplier.zip_code ? +supplier.zip_code : null,
              payment_terms: supplier.payment_terms
                ? +supplier.payment_terms
                : null,
              iban: supplier.iban,
              bic: supplier.bic,
              vat_code: supplier.vat_code,
              email: supplier.email,
              email_opt_out: supplier.email_opt_out,
              wings_code: supplier.wings_code ? +supplier.wings_code : null,
              sector: supplier.sector,
              phone_v2: supplier.phone_v2,
              mobile_v2: supplier.mobile_v2,
              suggested_value: supplier.suggested_value
                ? {
                    connect: {
                      id: supplier?.suggested_value?.id,
                    },
                  }
                : undefined,
              contact_persons: {
                create: drafts,
                update: updates,
                delete: deletes,
              },
            };

            if (supplier.id) {
              modifySupplier({
                variables: {
                  id: supplier.id,
                  data: allInput,
                },
                onError: error => {
                  setIsPanelOpen(false);
                  reject(error);
                },
                onCompleted: (x: any) => {
                  setIsPanelOpen(false);
                  resolve(x);
                },
              });
            } else {
              addSupplier({
                variables: {
                  data: allInput,
                },
                onError: error => {
                  setIsPanelOpen(false);
                  reject(error);
                },
                onCompleted: (x: any) => {
                  resolve(x);
                },
              });
            }
          }),
          {
            pending: {
              position: DEFAULT_TOAST_POSITION,
              render() {
                return DEFAULT_LOADING_MESSAGE;
              },
            },
          },
          {
            autoClose: DEFAULT_TOAST_DURATION,
          },
        );

        const x = await (res as any);
        if (x && x.createSupplier) {
          setSupplier(x.createSupplier);
          toastSuccess('Leverancier opgeslagen.');
        } else {
          toastSuccess('Leverancier opgeslagen');
        }
      }
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    }
  };

  // Command bar
  const commandBaritems: ICommandBarItemProps[] = [
    {
      key: 'totaal',
      text: `${suppliers.length} / ${suppliers.length > 0 ? suppliers[0].prisma_total : 0}`,
      theme: commandBarThemeCounter,
    },
    {
      key: 'new',
      text: 'Nieuw',
      iconProps: { iconName: 'Add' },
      onClick: () => openSupplierDetail(true),
      theme: commandBarTheme,
    },
    {
      key: 'modify',
      text: 'Wijzig',
      iconProps: { iconName: 'Edit' },
      onClick: () => openSupplierDetail(),
      theme: commandBarTheme,
      disabled: !selectionDetails,
    },
  ];

  useEffect(() => {
    if (supplier && isPanelOpen) {
      document.title = `3bouw | Leverancier - ${supplier.name}`;
    } else {
      document.title = '3bouw | Leveranciers';
    }
  }, [supplier, isPanelOpen]);

  return (
    <>
      <CommandBarSticky
        items={commandBaritems}
        theme={commandBarTheme}
        maxWidth='2000px'
      />

      <SupplierDetail
        isOpen={isPanelOpen}
        dismissPanel={() => {
          setIsPanelOpen(false);
        }}
        supplier={supplier}
        saveSupplier={saveSupplier}
        setSupplier={setSupplier}
        refetchSupplier={refetchSupplier}
      />

      <SuppliersDetailsList
        items={suppliers}
        selection={selection}
        enableShimmer={!loading}
        isSortedAsc={isSortedAsc}
        sortedField={sortedField}
        setSorting={setSorting}
        loadMore={loadMore}
        lazyLoading={lazyLoading}
        loadMoreCallback={loadMoreCallback}
        loading={loading}
        onItemInvoked={() => openSupplierDetail(false)}
      />

      {suppliers && (
        <>
          {showLoadMore && (
            <Stack
              style={{
                marginTop: '15px',
                marginLeft: 'auto',
                marginRight: 'auto',
                // display: items.length === suppliers.length ? 'block' : 'none',
              }}
              horizontal
              horizontalAlign='center'
            >
              <PrimaryButton
                text='Toon meer'
                onClick={() => loadMoreCallback()}
              />
            </Stack>
          )}
          <Stack style={{ minHeight: '50px' }} />
        </>
      )}
      {!loading && !showLoadMore && suppliers.length === 0 && (
        <div
          style={{
            textAlign: 'center',
            fontWeight: 600,
            fontSize: 14,
          }}
        >
          Geen resultaten
        </div>
      )}
    </>
  );
};

export default SuppliersOverview;
