/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from 'react';
import {
  ICommandBarItemProps,
  PrimaryButton,
  Selection,
  Stack,
} from '@fluentui/react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import {
  ADD_LEAD,
  displayNameLead,
  GET_LEAD_BY_ID,
  UPDATE_LEAD,
  ILead,
} from '../../../utils/Lead';
import { commandBarTheme, commandBarThemeCounter } from '../../../theme';
import { CommandBarSticky } from '../../../components/parts';
import {
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_TOAST_DURATION,
  DEFAULT_TOAST_POSITION,
  GET_EMPLOYEES,
  IEmployee,
  convertEmployeesToComboBoxOptions,
  DEFAULT_ERROR_MESSAGE,
} from '../../../utils';
import { dismissNotification } from '../../../redux/notification/notificationSlice';
import { SeverityLevel, throwError } from '../../../redux/error/errorSlice';
import { useAppDispatch } from '../../../redux/hooks';
import {
  Filter,
  FilterPanel,
  FilterState,
  initFilterPanelState,
} from '../../../components/parts/FilterPanel';
import { toastError, toastSuccess } from '../../../utils/toast';
import CustomersDetailsList from './CustomersDetailsList';
import CustomerDetail from './CustomerDetail';

interface Props {
  loadMore?: boolean;
  lazyLoading?: boolean;
  loading?: boolean;
  loadMoreCallback?: () => void;
  downloadFile?: () => void;
  customers: ILead[];
  setSorting: (isSortedAsc: boolean, sortedField: string) => void;
  isSortedAsc?: boolean;
  sortedField?: string;
  showLoadMore?: boolean;
  setLeadFilter: React.Dispatch<React.SetStateAction<FilterState | undefined>>;
  isFilterPanelOpen: boolean;
  setIsFilterPanelOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CustomersOverview = ({
  customers,
  loading,
  loadMore,
  setSorting = (isSortedAsc: boolean, sortedField: string) => {},
  isSortedAsc = false,
  sortedField = '',
  showLoadMore,
  lazyLoading,
  loadMoreCallback = () => ({}),
  downloadFile = () => {},
  setLeadFilter,
  isFilterPanelOpen,
  setIsFilterPanelOpen,
}: Props) => {
  const dispatch = useAppDispatch();
  const [customer, setCustomer] = useState<ILead | undefined>(undefined);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  // const [employees, setEmployees] = useState([]);

  const { id: customerId } = useParams();

  const [addCustomer] = useMutation(ADD_LEAD);
  const [modifyCustomer] = useMutation(UPDATE_LEAD);

  const { data: salesRepData, client } = useQuery(GET_EMPLOYEES, {
    fetchPolicy: 'no-cache',
    /* onCompleted: (x: any) => {
      setEmployees(x.findManyEmployees);
    }, */
    variables: {
      filter: {
        sales_rep: true,
      },
    },
  });

  // Selection
  const [selectionDetails, setSelectionDetails] = useState<ILead | undefined>();

  const getSelectionDetails = () => {
    const currentSelection: any = selection.getSelection();

    if (currentSelection.length > 0) {
      setSelectionDetails(currentSelection[0]);
    } else {
      setSelectionDetails(undefined);
    }
  };

  const selection = new Selection({
    onSelectionChanged: getSelectionDetails,
  });

  const { refetch: refetchCustomer } = useQuery(GET_LEAD_BY_ID, {
    // notifyOnNetworkStatusChange: true,
    variables:
      selectionDetails || customerId
        ? {
            where: {
              id: selectionDetails
                ? selectionDetails.id
                : parseInt(customerId!, 10),
            },
          }
        : undefined,
    skip: !selectionDetails && !customerId,
    onCompleted: data => {
      setCustomer(data.findOneLead);
    },
  });

  const openCustomerDetail = (newCustomer?: boolean) => {
    if (selectionDetails && !newCustomer) {
      // getCustomer({ variables: { where: { id: selectionDetails.id } } });
    } else {
      setCustomer(undefined);
    }
    setIsPanelOpen(true);
  };

  useEffect(() => {
    if (customerId) {
      openCustomerDetail();
    }
  }, [customerId]);

  const openFilter = () => {
    setIsFilterPanelOpen(true);
  };

  const [emailChanged, setEmailChanged] = useState<boolean>(false);

  const saveCustomer = async () => {
    try {
      if (customer) {
        console.log('customer', customer);
        const res = await toast.promise(
          new Promise((resolve, reject) => {
            const allInput = {
              address1: customer.address1,
              archive_no: customer.archive_no,
              budget_estimate_high: customer.budget_estimate_high
                ? +customer.budget_estimate_high
                : null,
              budget_estimate_low: customer.budget_estimate_low
                ? +customer.budget_estimate_low
                : null,
              city1: customer.city1,
              close_follow_up: customer.close_follow_up,
              comments: customer.comments,
              competitors: customer.competitors,
              construction_site_address: customer.construction_site_address,
              construction_site_available: customer.construction_site_available,
              construction_site_city: customer.construction_site_city,
              construction_site_comments: customer.construction_site_comments,
              construction_site_wanted_location:
                customer.construction_site_wanted_location,
              construction_site_zip_code: customer.construction_site_zip_code
                ? +customer.construction_site_zip_code
                : null,
              contact_method: customer.contact_method,
              country: customer.country,
              date_created: customer.date_created,
              date_documentation_sent: customer.date_documentation_sent,
              date_last_info_visit: customer.date_last_info_visit,
              date_last_updated: customer.date_last_updated,
              date_visit_exhibition_house: customer.date_visit_exhibition_house,
              date_visit_exposition: customer.date_visit_exposition,
              degree_of_completion: customer.degree_of_completion,
              email1: customer.email1,
              email1opt_out: customer.email1opt_out,
              email2: customer.email2,
              email2opt_out: customer.email2opt_out,
              fax1: customer.fax1,
              fax2: customer.fax2,
              first_name1: customer.first_name1 || '', // if business it is not required but we save an empty string instead
              first_name2: customer.first_name2,
              known_by: customer.known_by,
              last_name1: customer.last_name1,
              last_name2: customer.last_name2,
              mobile1_v2: customer.mobile1_v2,
              mobile2_v2: customer.mobile2_v2,
              phone1_v2: customer.phone1_v2,
              phone2_v2: customer.phone2_v2,
              reason_declined: customer.reason_declined,
              status: customer.only_invoice ? 'INVOICE_ONLY' : 'SOLD',
              title1: customer.title1,
              title2: customer.title2,
              type_of_home: customer.type_of_home,
              vat: customer.vat,
              year_to_start_construction: customer.year_to_start_construction
                ? +customer.year_to_start_construction
                : null,
              zip_code1: customer.zip_code1 ? +customer.zip_code1 : null,
              christmas_card:
                typeof customer.christmas_card === 'undefined'
                  ? true
                  : customer.christmas_card,
              only_invoice: !!customer.only_invoice,
              business: !!customer.business,
              vat_number: customer.vat_number,

              wings_code: customer.wings_code ? +customer.wings_code : null,
              wings_account_code: customer.wings_account_code
                ? +customer.wings_account_code
                : null,
            };

            if (customer.id) {
              modifyCustomer({
                variables: {
                  id: customer.id,
                  data: allInput,
                },
                onError: error => {
                  reject(error);
                },
                onCompleted: (x: any) => {
                  resolve(x);
                  setEmailChanged(false);
                  toastSuccess('Klant gewijzigd');
                },
              });
            } else {
              addCustomer({
                variables: {
                  data: allInput,
                },
                onError: error => {
                  reject(error);
                },
                onCompleted: (x: any) => {
                  if (x && x.createLead) {
                    setCustomer(x.createLead);
                    selection.setItems(x.createLead);
                    resolve(x);
                    setEmailChanged(false);
                    toastSuccess('Klant toegevoegd');
                  }
                },

              });
            }
          }),
          {
            pending: {
              position: DEFAULT_TOAST_POSITION,
              render() {
                return DEFAULT_LOADING_MESSAGE;
              },
            },
          },
          {
            autoClose: DEFAULT_TOAST_DURATION,
          },
        );

        await res;
      }
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    }
  };

  // Command bar
  const commandBaritems: ICommandBarItemProps[] = [
    {
      key: 'totaal',
      text: `${customers.length} / ${customers.length > 0 ? customers[0].prisma_total : 0}`,
      theme: commandBarThemeCounter,
    },
    {
      key: 'new',
      text: 'Nieuw',
      iconProps: { iconName: 'Add' },
      onClick: () => openCustomerDetail(true),
      theme: commandBarTheme,
    },
    {
      key: 'modify',
      text: 'Wijzig',
      iconProps: { iconName: 'Edit' },
      onClick: () => openCustomerDetail(),
      theme: commandBarTheme,
      disabled: !selectionDetails,
    },
    {
      key: 'downloadXLS',
      text: 'Excel',
      iconProps: { iconName: 'ExcelDocument' },
      onClick: () => downloadFile(),
      theme: commandBarTheme,
      // disabled: !selectionDetails,
    },
    {
      key: 'filter',
      text: 'Filter',
      iconProps: { iconName: 'Filter' },
      onClick: () => openFilter(),
      theme: commandBarTheme,
    },

  ];

  useEffect(() => {
    if (customer && isPanelOpen) {
      document.title = `3bouw | Klant - ${displayNameLead(customer)}`;
    } else {
      document.title = '3bouw | Klanten';
    }
  }, [customer, isPanelOpen]);

  const filter_schema: Filter = {
    id: 'customers_filter_schema_rev5',
    permission: 'read:leads',
    schema: [
      {
        id: 'section_0',
        label: 'Algemeen',
        width: 100,
        fields: [
          {
            id: 'section_0_0',
            name: 'Startdatum',
            fieldName: 'date_created',
            data: 'date',
            type: 'date',
            width: 32.5,
            callback: (fieldValue: string) => ({
              gte: moment(new Date(fieldValue)).toISOString(),
            }),
          },
          {
            id: 'section_0_1',
            name: 'Einddatum',
            fieldName: 'date_created',
            data: 'date',
            type: 'date',
            width: 32.5,
            callback: (fieldValue: string) => ({
              lte: moment(new Date(fieldValue)).toISOString(),
            }),
          },
          {
            id: 'section_0_2',
            name: 'Status',
            fieldName: 'OR',
            data: 'string',
            type: 'list',
            options: [
              {
                key: 'NIHIL',
                text: 'Nihil',
              },
              { key: 'SOLD', text: 'Verkocht' },
              {
                key: 'INVOICE_ONLY',
                text: 'Factuur Klant',
              },
            ],
            width: 32.5,
            callback: (fieldValue: string) => {
              if (fieldValue && fieldValue !== 'ALLES') {
                if (fieldValue === 'INVOICE_ONLY') {
                  const filterValue: any = [
                    {
                      status: {
                        equals: fieldValue,
                      },
                    },
                    {
                      only_invoice: true,
                    },
                  ];
                  return filterValue;
                }

                const filterValue: any = [
                  {
                    status: {
                      equals: fieldValue,
                    },
                  },
                ];

                return filterValue;
              }
            },
          },
        ],
      },
      {
        id: 'section_1',
        label: 'Contact',
        width: 48,
        fields: [
          {
            id: 'section_1_2',
            name: 'Voornaam',
            fieldName: 'first_name1',
            data: 'string',
            type: 'text',
            width: 49.5,
            callback: (fieldValue: string) => ({
              contains: fieldValue,
            }),
          },
          {
            id: 'section_1_1',
            name: 'Achternaam',
            fieldName: 'last_name1',
            data: 'string',
            type: 'text',
            width: 49.5,
            callback: (fieldValue: string) => ({
              startsWith: fieldValue,
            }),
          },
          {
            id: 'section_1_3',
            name: 'Adres',
            fieldName: 'address1',
            data: 'string',
            type: 'text',
            callback: (fieldValue: string) => ({
              startsWith: fieldValue,
            }),
          },
          {
            id: 'section_1_4',
            name: 'Stad',
            fieldName: 'city1',
            data: 'string',
            type: 'text',
            width: 69.5,
            callback: (fieldValue: string) => ({
              startsWith: fieldValue,
            }),
          },
          {
            id: 'section_1_5',
            name: 'Post code',
            fieldName: 'zip_code1',
            data: 'number',
            type: 'number',
            width: 29.5,
            callback: (fieldValue: string) => parseInt(fieldValue, 10),
          },
          {
            id: 'section_1_6',
            name: 'Post code (comma separated)',
            fieldName: 'OR',
            data: 'number',
            type: 'text',
            callback: (fieldValue: string) => {
              const fieldArray = fieldValue.replace(' ', '').split(',');
              if (fieldArray.length > 0) {
                const filterArray = [];

                for (let i = 0; i < fieldArray.length; i++) {
                  if (!isNaN(fieldArray[i] as any)) {
                    filterArray.push({
                      zip_code1: parseInt(fieldArray[i], 10),
                    });
                  }
                }

                return filterArray;
              }
            },
          },
          {
            id: 'section_1_7',
            name: 'Land',
            fieldName: 'country',
            data: 'string',
            type: 'text',
            callback: (fieldValue: string) => ({
              startsWith: fieldValue,
            }),
          },
          {
            id: 'section_1_8',
            name: 'E-mailadres',
            fieldName: 'email1',
            data: 'string',
            type: 'text',
            callback: (fieldValue: string) => ({
              startsWith: fieldValue,
            }),
          },
          {
            id: 'section_1_9',
            name: 'GSM',
            fieldName: 'OR',
            data: 'string',
            type: 'text',
            callback: (fieldValue: string) => [
              { mobile1: { contains: fieldValue } },
              { mobile1_v2: { contains: fieldValue } },
              { mobile1search: { contains: fieldValue } },
            ],
          },
          {
            id: 'section_1_10',
            name: 'Telefoon',
            fieldName: 'OR',
            data: 'string',
            type: 'text',
            callback: (fieldValue: string) => [
              { phone1: { contains: fieldValue } },
              { phone1_v2: { contains: fieldValue } },
              { phone1search: { contains: fieldValue } },
            ],
          },
          {
            id: 'section_1_11',
            name: 'BTW-nummer',
            fieldName: 'vat',
            data: 'string',
            type: 'text',
            callback: (fieldValue: string) => ({
              contains: fieldValue.replace(' ', ''),
            }),
          },
        ],
      },
      {
        id: 'section_2',
        label: 'Partner',
        width: 48,
        fields: [
          {
            id: 'section_2_1',
            name: 'Voornaam',
            fieldName: 'first_name2',
            data: 'string',
            type: 'text',
            width: 49.5,
            callback: (fieldValue: string) => ({
              startsWith: fieldValue,
            }),
          },
          {
            id: 'section_2_2',
            name: 'Achternaam',
            fieldName: 'last_name2',
            data: 'string',
            type: 'text',
            width: 49.5,
            callback: (fieldValue: string) => ({
              startsWith: fieldValue,
            }),
          },

          {
            id: 'section_2_3',
            name: 'E-mailadres',
            fieldName: 'email2',
            data: 'string',
            type: 'text',
            callback: (fieldValue: string) => ({
              startsWith: fieldValue,
            }),
          },
          {
            id: 'section_2_4',
            name: 'GSM',
            fieldName: 'OR',
            data: 'string',
            type: 'text',
            callback: (fieldValue: string) => [
              { mobile2: { contains: fieldValue } },
              { mobile2_v2: { contains: fieldValue } },
              { mobile2search: { contains: fieldValue } },
            ],
          },
          {
            id: 'section_2_5',
            name: 'Telefoon',
            fieldName: 'OR',
            data: 'string',
            type: 'text',
            callback: (fieldValue: string) => [
              { phone2: { contains: fieldValue } },
              { phone2_v2: { contains: fieldValue } },
              { phone2search: { contains: fieldValue } },
            ],
          },
          {
            id: 'section_2_7',
            name: 'Analytische code',
            fieldName: 'wings_account_code',
            data: 'number',
            type: 'number',
            callback: (fieldValue: string) => parseInt(fieldValue, 10),
          },
          {
            id: 'section_2_8',
            name: 'Enkel factuur',
            fieldName: 'only_invoice',
            data: 'boolean',
            type: 'checkbox',
            callback: (fieldValue: string) => fieldValue === 'true',
          },
        ],
      },
      {
        id: 'section_3',
        label: 'Marketing',
        width: 48,
        fields: [
          {
            id: 'section_3_0',
            name: 'Verkoper',
            fieldName: 'sales_rep_employee',
            data: 'string',
            type: 'list',
            options: salesRepData
              ? convertEmployeesToComboBoxOptions(
                  salesRepData.findManyEmployees.filter(
                    (x: IEmployee) => x.sales_rep === true,
                  ),
                )
              : [],
            callback: (fieldValue: string) => {
              if (fieldValue && fieldValue !== 'ALLES') {
                return {
                  id: {
                    equals: parseInt(fieldValue, 10),
                  },
                };
              }
            },
          },
          {
            id: 'section_3_1',
            name: 'Naambekendheid via',
            fieldName: 'known_by',
            data: 'string',
            type: 'suggested',
            prefix: 'lead_knownBy_',
            callback: (fieldValue: string) => {
              if (fieldValue && fieldValue !== 'ALLES') {
                return {
                  startsWith: `lead_knownBy_${fieldValue}`,
                };
              }
            },
          },
          {
            id: 'section_3_2',
            name: 'Contact opgenomen via',
            fieldName: 'contact_method',
            data: 'string',
            type: 'suggested',
            prefix: 'lead_contactMetod_',
            callback: (fieldValue: string) => {
              if (fieldValue && fieldValue !== 'ALLES') {
                return {
                  startsWith: `lead_contactMetod_${fieldValue}`,
                };
              }
            },
          },
          {
            id: 'section_3_3',
            name: 'Kerstkaartje?',
            fieldName: 'christmas_card',
            data: 'boolean',
            type: 'checkbox',
            // prefix: 'lead_contactMetod_',
            callback: (fieldValue: string) => fieldValue === 'true',
          },
        ],
      },
      {
        id: 'section_4',
        label: 'Bouwgrond info',
        width: 48,
        fields: [
          {
            id: 'section_4_2',
            name: 'Bouwgrond beschikbaar',
            fieldName: 'construction_site_available',
            data: 'string',
            type: 'list',
            options: [
              { key: 'true', text: 'Ja' },
              { key: 'false', text: 'Nee' },
            ],
            callback: (fieldValue: string) => {
              if (fieldValue && fieldValue !== 'ALLES') {
                if (fieldValue === 'true') return true;
                if (fieldValue === 'false') return false;
              }
            },
          },
          {
            id: 'section_4_3',
            name: 'Adres',
            fieldName: 'construction_site_address',
            data: 'string',
            type: 'text',
            callback: (fieldValue: string) => ({
              startsWith: fieldValue,
            }),
          },
          {
            id: 'section_4_4',
            name: 'Stad',
            fieldName: 'construction_site_city',
            data: 'string',
            type: 'text',
            width: 69.5,
            callback: (fieldValue: string) => ({
              startsWith: fieldValue,
            }),
          },
          {
            id: 'section_4_5',
            name: 'Post code',
            fieldName: 'construction_site_zip_code',
            data: 'number',
            type: 'number',
            width: 29.5,
            callback: (fieldValue: string) => parseInt(fieldValue, 10),
          },
          {
            id: 'section_4_6',
            name: 'Post code (comma separated)',
            fieldName: 'OR',
            data: 'number',
            type: 'text',
            callback: (fieldValue: string) => {
              const fieldArray = fieldValue.replace(' ', '').split(',');
              if (fieldArray.length > 0) {
                const filterArray = [];

                for (let i = 0; i < fieldArray.length; i++) {
                  if (!isNaN(fieldArray[i] as any)) {
                    filterArray.push({
                      construction_site_zip_code: parseInt(fieldArray[i], 10),
                    });
                  }
                }

                return filterArray;
              }
            },
          },
        ],
      },
    ],
  };

  const [filterState, setFilterState] = useState<FilterState>(
    initFilterPanelState(filter_schema),
  );

  return (
    <>
      <CommandBarSticky
        items={commandBaritems}
        theme={commandBarTheme}
        maxWidth='2000px'
      />

      <CustomerDetail
        isOpen={isPanelOpen}
        dismissPanel={() => {
          setIsPanelOpen(false);
        }}
        customer={customer}
        saveCustomer={saveCustomer}
        setCustomer={setCustomer}
        employees={salesRepData ? salesRepData.findManyEmployees : []}
        refetchCustomer={refetchCustomer}
        emailChanged={emailChanged}
        setEmailChanged={setEmailChanged}
      />

      {isFilterPanelOpen && (
        <FilterPanel
          isOpen={isFilterPanelOpen}
          dismissPanel={() => {
            setIsFilterPanelOpen(false);
          }}
          filter={filter_schema}
          setFilter={setLeadFilter}
          filterState={filterState}
          setFilterState={setFilterState}
        />
      )}

      <CustomersDetailsList
        items={customers}
        selection={selection}
        enableShimmer={!loading}
        isSortedAsc={isSortedAsc}
        sortedField={sortedField}
        setSorting={setSorting}
        loadMore={loadMore}
        lazyLoading={lazyLoading}
        loadMoreCallback={loadMoreCallback}
        loading={loading}
        onItemInvoked={item => {
          openCustomerDetail(false);
        }}
        employees={salesRepData ? salesRepData.findManyEmployees : []}
      />

      {customers && (
        <>
          {showLoadMore && (
            <Stack
              style={{
                marginTop: '15px',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
              horizontal
              horizontalAlign='center'
            >
              <PrimaryButton
                text='Toon meer'
                onClick={() => loadMoreCallback()}
              />
            </Stack>
          )}
          <Stack style={{ minHeight: '50px' }} />
        </>
      )}
      {!loading && !showLoadMore && customers.length === 0 && (
        <div
          style={{
            textAlign: 'center',
            fontWeight: 600,
            fontSize: 14,
          }}
        >
          Geen resultaten
        </div>
      )}
    </>
  );
};

export default CustomersOverview;
