import React, { useEffect, useRef, useState } from 'react';
import {
  DefaultButton,
  Panel,
  PanelType,
  Stack,
  Label,
  TextField,
  ComboBox,
  IComboBoxOption,
  IComboBox,
  IconButton,
  ActionButton,
  PrimaryButton,
  Link,
  Toggle,
} from '@fluentui/react';
import _ from 'lodash';
import { useLazyQuery, useQuery } from '@apollo/client';
import { Editor } from '@tinymce/tinymce-react';
import moment from 'moment';
import SaveButtonWithPermissions from '../../../components/parts/SaveButtonWithPermissions';
import { customPanelStyles } from '../../../theme';
import { GET_MAIL_TEMPLATES } from '../../../utils/MailTemplate';
import { GET_STORAGE_TOKEN } from '../../../utils';
import { BlobStorage } from '../../blob-storage';
import { ILead } from '../../../utils/Lead';

interface EmailVariable {
  id: string;
  value: string;
}

const resolveEmailVariables = (
  htmlBody: string,
  variables: EmailVariable[],
): string =>
  htmlBody.replace(/\[([^\]]+)\]/g, (match, p1) => {
    const variable = variables.find(v => v.id === p1);
    return variable ? variable.value : match;
  });

const resolveCustomerName = (lead?: ILead) => {
  if (lead?.first_name1 && lead?.first_name2) {
    return `${lead.first_name1} en ${lead.first_name2}`;
  }
  if (lead?.first_name1) {
    return lead.first_name1;
  }
  return '';
};

const MailDetail = ({
  isOpen,
  dismissPanel,
  saveMail,
  mail,
  setMail,
  customerInvoice,
  constructionSite,
  pdfCallback, // Add pdfTemplate prop
  selectedFiles,
  setSelectedFiles,
}: {
  isOpen: boolean;
  dismissPanel: any;
  saveMail: any;
  mail: any;
  setMail: any;
  customerInvoice: any;
  constructionSite: any;
  pdfCallback: any;
  selectedFiles: File[];
  setSelectedFiles: React.Dispatch<React.SetStateAction<File[]>>;
}) => {
  function extractBetalingsschijf(list: any[]): string | undefined {
    const firstBetalingsschijf = list.find(item => item.description.includes('[Betalingsschijf]'));

    // filter out the string '[Betalingsschijf]' from the description
    const filteredDescription = firstBetalingsschijf?.description.replace('[Betalingsschijf]', '');

    return filteredDescription || undefined;
  }

  const emailVariables: EmailVariable[] = [
    {
      id: 'invoice_no',
      value: customerInvoice?.invoice_no || '[invoice_no niet gevonden]',
    },
    {
      id: 'invoice_date',
      value: customerInvoice.invoice_date
        ? moment(customerInvoice.invoice_date).format('DD/MM/YYYY')
        : '[invoice_date niet gevonden]',
    },
    {
      id: 'construction_site_name',
      value: constructionSite?.name || '[construction_site_name niet gevonden]',
    },
    {
      id: 'construction_site_address',
      value:
        constructionSite?.address ||
        '[construction_site_address niet gevonden]',
    },
    {
      id: 'construction_site_zip_code',
      value: constructionSite?.zip_code
        ? constructionSite?.zip_code.toString()
        : '[construction_site_zip_code niet gevonden]',
    },
    {
      id: 'construction_site_city',
      value: constructionSite?.city || '[construction_site_city niet gevonden]',
    },
    {
      id: 'customer_name',
      value:
        resolveCustomerName(constructionSite?.lead) ||
        '[customer_name niet gevonden]',
    },
    //
    {
      id: 'sales_rep',
      value:
        constructionSite?.sales_rep?.first_name &&
        constructionSite?.sales_rep?.last_name
          ? `${constructionSite?.sales_rep?.first_name} ${constructionSite?.sales_rep?.last_name}`
          : '[sales_rep niet gevonden]',
    },
    {
      id: 'site_manager',
      value:
        constructionSite?.site_manager?.first_name &&
        constructionSite?.site_manager?.last_name
          ? `${constructionSite?.site_manager?.first_name} ${constructionSite?.site_manager?.last_name}`
          : '[site_manager niet gevonden]',
    },
    {
      id: 'payment_slip',
      value: extractBetalingsschijf(customerInvoice?.customer_invoice_line_items) ? extractBetalingsschijf(customerInvoice?.customer_invoice_line_items) : '[betalingsschijf niet van toepassing]',
    },
  ];

  const isViewMode = mail && mail.id;
  const { data: mailTemplatesData, loading: templatesLoading } = useQuery(
    GET_MAIL_TEMPLATES,
    {
      variables: {
        filter: {
          active: true,
          type: {
            contains: 'Uitgaande factuur',
          },
        },
      },
    },
  );

  const [showCcBcc, setShowCcBcc] = useState(false);
  const [toInput, setToInput] = useState('');
  const [ccInput, setCcInput] = useState('');
  const [bccInput, setBccInput] = useState('');
  const [uploadedAttachments, setUploadedAttachments] = useState<any[]>(
    mail?.attachments ? JSON.parse(mail.attachments) : [],
  );

  const fileInputRef = useRef<HTMLInputElement>(null);

  const [acquireToken] = useLazyQuery(GET_STORAGE_TOKEN, {
    fetchPolicy: 'no-cache',
  });

  const downloadAttachment = async (fileName: string) => {
    try {
      const { data } = await acquireToken();
      const fileStore = new BlobStorage(
        data.createStorageToken.accountName,
        data.createStorageToken.sasToken,
      );
      await fileStore.downloadBlob('attachments', fileName);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  useEffect(() => {
    if (!isViewMode) {
      setMail((prevState: any) => ({
        ...prevState,
        attachments: JSON.stringify(uploadedAttachments),
      }));
    }
  }, [uploadedAttachments]);

  useEffect(() => {
    if (mail?.template) {
      const resolvedBody = resolveEmailVariables(mail.body, emailVariables);
      setMail((prevState: any) => ({
        ...prevState,
        template: mail.template,
        body: resolvedBody,
      }));
    }
  }, [mail?.template]);

  const addInitialFiles = async () => {
    if (pdfCallback) {
      const file = await pdfCallback();
      setSelectedFiles([...selectedFiles, file]);
    }
  };

  useEffect(() => {
    console.log('addInitialFiles');
    addInitialFiles();
  }, []);

  // ===================================================================

  const onChangeTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    if (isViewMode) return;

    setMail((prevState: any) => ({
      ...prevState,
      [(event.target as HTMLTextAreaElement).name]: newValue,
    }));
  };

  const addEmail = (
    field: string,
    input: string,
    setInput: (value: string) => void,
  ) => {
    if (isViewMode || !input || !input.includes('@')) return;

    const currentEmails = mail?.[field] ? JSON.parse(mail[field]) : [];
    if (!currentEmails.includes(input)) {
      setMail((prevState: any) => ({
        ...prevState,
        [field]: JSON.stringify([...currentEmails, input]),
      }));
    }
    setInput('');
  };

  const removeEmail = (field: string, emailToRemove: string) => {
    if (isViewMode) return;

    const currentEmails = mail?.[field] ? JSON.parse(mail[field]) : [];
    setMail((prevState: any) => ({
      ...prevState,
      [field]: JSON.stringify(
        currentEmails.filter((email: string) => email !== emailToRemove),
      ),
    }));
  };

  const renderEmailList = (field: string) => {
    try {
      const emails = mail?.[field] ? JSON.parse(mail[field]) : [];
      return emails.map((email: string, index: number) => (
        <div
          key={`${email}-${index}`}
          style={{
            display: 'inline-block',
            margin: '2px',
            padding: '4px 8px',
            backgroundColor: '#f0f0f0',
            borderRadius: '4px',
            alignItems: 'center',
          }}
        >
          {email}
          {!isViewMode && (
            <IconButton
              iconProps={{ iconName: 'Cancel' }}
              onClick={() => removeEmail(field, email)}
              styles={{ root: { marginLeft: 4, height: 20 } }}
            />
          )}
        </div>
      ));
    } catch {
      return null;
    }
  };

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (isViewMode) return;
    setSelectedFiles(prev => [
      ...prev,
      ...Array.from(event.target.files || []),
    ]);
  };

  const removeAttachment = (name: string) => {
    if (isViewMode) return;
    setUploadedAttachments(prev =>
      prev.filter(attachment => attachment.name !== name));
  };

  const getFileIcon = (extension: string) => {
    switch (extension) {
      case 'pdf':
        return 'PDF';
      case 'doc':
      case 'docx':
        return 'WordDocument';
      case 'xls':
      case 'xlsx':
        return 'ExcelDocument';
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        return 'Photo2';
      default:
        return 'Document';
    }
  };

  const renderSelectedFiles = () =>
    selectedFiles.map((file, index) => (
      <div
        key={`${file?.name}-${index}`}
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          margin: '5px',
          padding: '8px 12px',
          border: '1px dashed #71bf43',
          borderRadius: '4px',
          backgroundColor: '#f0f9eb',
        }}
      >
        <IconButton
          iconProps={{ iconName: 'Hourglass' }}
          styles={{
            root: { color: '#71bf43', marginRight: '8px' },
          }}
          disabled
        />
        <span style={{ marginRight: '10px' }}>{file?.name}</span>
        <IconButton
          iconProps={{ iconName: 'Cancel' }}
          onClick={() => setSelectedFiles(prev => prev.filter(f => f !== file))}
          styles={{
            root: { color: '#605E5' },
          }}
        />
      </div>
    ));

  const renderUploadedAttachments = () =>
    JSON.parse(mail?.attachments || '[]').map((file: any, index: number) => (
      <div
        key={`${file?.name}-${index}`}
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          margin: '5px',
          padding: '8px 12px',
          border: '1px solid #e1e1e1',
          borderRadius: '4px',
          backgroundColor: '#ffffff',
          boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
        }}
      >
        <IconButton
          iconProps={{ iconName: getFileIcon(file.extension) }}
          styles={{
            root: { color: '#71bf43', marginRight: '8px' },
          }}
          disabled
        />
        {isViewMode ? (
          <span
            onClick={() => downloadAttachment(file.name)}
            style={{ cursor: 'pointer', color: '#71bf43' }}
            aria-hidden='true'
          >
            {file?.name.split('_').slice(1).join('_')}
          </span>
        ) : (
          <span style={{ marginRight: '10px' }}>{file?.name}</span>
        )}
        {!isViewMode && (
          <IconButton
            iconProps={{ iconName: 'Delete' }}
            onClick={() => removeAttachment(file?.name)}
            styles={{
              root: { color: '#605E5' },
            }}
          />
        )}
      </div>
    ));

  const onTemplateChange = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
  ) => {
    if (isViewMode) return;

    if (option && mailTemplatesData?.findManyMailTemplates) {
      const template = mailTemplatesData.findManyMailTemplates.find(
        (t: any) => t.id === option.key,
      );

      if (template && template.body) {
        setMail((prevState: any) => ({
          ...prevState,
          template: template.id,
          body: template.body,
        }));
      }
    }
  };

  const mailTemplateOptions: IComboBoxOption[] = React.useMemo(() => {
    if (!mailTemplatesData?.findManyMailTemplates) return [];
    return mailTemplatesData.findManyMailTemplates.map((template: any) => ({
      key: template.id,
      text: template.name,
    }));
  }, [mailTemplatesData]);

  return (
    <Panel
      isLightDismiss
      isOpen={isOpen}
      onDismiss={dismissPanel}
      closeButtonAriaLabel='Close'
      headerText={isViewMode ? 'Mail bekijken' : 'Mail verzenden'}
      type={PanelType.custom}
      customWidth='900px'
      styles={customPanelStyles}
    >
      <div>
        <div>
          {isViewMode && (
            <Label style={{ fontSize: '12px', color: '#666' }}>
              ID: {mail.id}
            </Label>
          )}

          <Stack tokens={{ childrenGap: 15 }}>
            {/* From field and CC/BCC toggle */}
            <Stack horizontal tokens={{ childrenGap: 15 }}>
              <Stack.Item grow={3}>
                <Label style={{ fontSize: '12px', color: '#666' }}>Van:</Label>
                <TextField
                  value={mail?.from || ''}
                  disabled
                  onChange={() => {
                    // console.log('onChange');
                  }}
                  styles={{
                    root: { width: '100%' },
                    field: { backgroundColor: '#f3f2f1' },
                  }}
                />
              </Stack.Item>
              <Stack.Item align='end'>
                <Stack
                  horizontal
                  verticalAlign='center'
                  tokens={{ childrenGap: 8 }}
                >
                  <Label
                    style={{
                      fontSize: '12px',
                      color: '#666',
                      marginBottom: 0,
                      marginLeft: 10,
                    }}
                  >
                    CC/BCC
                  </Label>
                  <Toggle
                    checked={showCcBcc}
                    onChange={(_, checked) => setShowCcBcc(!!checked)}
                    styles={{
                      root: { margin: '0', marginTop: '16px' },
                    }}
                  />
                </Stack>
              </Stack.Item>
            </Stack>

            {/* To field */}
            <Stack>
              <Label style={{ fontSize: '12px', color: '#666' }}>Aan:</Label>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  borderBottom: '1px solid #e1e1e1',
                  paddingBottom: '8px',
                }}
              >
                <TextField
                  value={toInput}
                  onChange={(_, value) => setToInput(value || '')}
                  styles={{
                    root: { flexGrow: 1 },
                  }}
                  placeholder='Voer e-mailadres in'
                  disabled={isViewMode}
                />
                {!isViewMode && (
                  <ActionButton
                    iconProps={{ iconName: 'Add' }}
                    onClick={() => addEmail('to', toInput, setToInput)}
                  >
                    Toevoegen
                  </ActionButton>
                )}
              </div>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: '4px',
                  marginTop: '4px',
                }}
              >
                {renderEmailList('to')}
              </div>
            </Stack>

            {/* CC and BCC section */}
            {showCcBcc && (
              <Stack style={{}} tokens={{ childrenGap: 15 }}>
                {/* CC field */}
                <Stack>
                  <Label style={{ fontSize: '12px', color: '#666' }}>CC:</Label>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      borderBottom: '1px solid #e1e1e1',
                      paddingBottom: '8px',
                    }}
                  >
                    <TextField
                      value={ccInput}
                      onChange={(_, value) => setCcInput(value || '')}
                      styles={{
                        root: { flexGrow: 1 },
                      }}
                      placeholder='Voer CC e-mailadres in'
                      disabled={isViewMode}
                    />
                    {!isViewMode && (
                      <ActionButton
                        iconProps={{ iconName: 'Add' }}
                        onClick={() => addEmail('cc', ccInput, setCcInput)}
                      >
                        Toevoegen
                      </ActionButton>
                    )}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: '4px',
                      marginTop: '4px',
                    }}
                  >
                    {renderEmailList('cc')}
                  </div>
                </Stack>

                {/* BCC field */}
                <Stack>
                  <Label style={{ fontSize: '12px', color: '#666' }}>
                    BCC:
                  </Label>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      borderBottom: '1px solid #e1e1e1',
                      paddingBottom: '8px',
                    }}
                  >
                    <TextField
                      value={bccInput}
                      onChange={(_, value) => setBccInput(value || '')}
                      styles={{
                        root: { flexGrow: 1 },
                      }}
                      placeholder='Voer BCC e-mailadres in'
                      disabled={isViewMode}
                    />
                    {!isViewMode && (
                      <ActionButton
                        iconProps={{ iconName: 'Add' }}
                        onClick={() => addEmail('bcc', bccInput, setBccInput)}
                      >
                        Toevoegen
                      </ActionButton>
                    )}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: '4px',
                      marginTop: '4px',
                    }}
                  >
                    {renderEmailList('bcc')}
                  </div>
                </Stack>
              </Stack>
            )}

            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <Stack.Item grow={1}>
                <TextField
                  name='subject'
                  label='Onderwerp'
                  value={mail && mail.subject ? mail.subject : ''}
                  onChange={onChangeTextFieldValue}
                  required
                  disabled={isViewMode}
                />
              </Stack.Item>
              {!isViewMode &&
                !templatesLoading &&
                mailTemplatesData?.findManyMailTemplates?.length > 0 && (
                  <Stack.Item grow={1}>
                    <ComboBox
                      label='Template'
                      options={mailTemplateOptions}
                      onChange={onTemplateChange}
                      selectedKey={mail?.template}
                      disabled={isViewMode}
                    />
                  </Stack.Item>
                )}
            </Stack>

            <Stack>
              <Label style={{ fontSize: '12px', color: '#666' }}>Bericht</Label>

              <Editor
                apiKey='y8v65wfkd6uxajrh03buxibzkyrxwywwe3ggcccybjg1ry4d'
                init={{
                  height: 400,
                  menubar: false,
                  readonly: isViewMode,
                  menu: {
                    file: { title: 'File', items: 'newdocument' },
                    edit: {
                      title: 'Edit',
                      items: 'undo redo | cut copy paste pastetext | selectall',
                    },
                    insert: { title: 'Insert', items: 'link emplate' },
                    format: {
                      title: 'Format',
                      items: 'bold italic underline',
                    },
                  },
                  plugins: [
                    'advlist',
                    'autolink',
                    'lists',
                    'link',
                    'image',
                    'charmap',
                    'preview',
                    'anchor',
                    'searchreplace',
                    'visualblocks',
                    'code',
                    'fullscreen',
                    'insertdatetime',
                    'media',
                    'table',
                    'help',
                    'wordcount',
                  ],
                  toolbar: isViewMode
                    ? false
                    : 'undo redo | blocks | ' +
                      'bold italic | alignleft aligncenter ' +
                      'alignright alignjustify | bullist numlist outdent indent | ' +
                      'removeformat',

                  setup(editor) {
                    editor.on('init', () => {
                      // Override TinyMCE default styles
                      const customStyles = `
                        /* Main container border */
                        .tox.tox-tinymce {
                          border: 1px solid #605E5C !important;
                          border-radius: 2px !important;
                        }
                        .tox.tox-tinymce:focus-within,
                        .tox.tox-tinymce--focused {
                          outline: none !important;
  
                          border-radius: 2px !important;

                        }
                        
                        /* Menu items and buttons */
                        .tox .tox-mbtn--active,
                        .tox .tox-tbtn--enabled,
                        .tox .tox-tbtn:hover,
                        .tox .tox-split-button:hover,
                        .tox .tox-collection__item--active,
                        .tox .tox-collection__item:hover {
                          background-color: rgba(76, 175, 80, 0.1) !important;
                          color: inherit !important;
                        }

                        /* Menu button focus state */
                        .tox .tox-mbtn:focus:not(:disabled)::after {
                          border-radius: 3px;
                          bottom: 0;
                          box-shadow: 0 0 0 2px #71bf43;
                          content: '';
                          left: 0;
                          position: absolute;
                          right: 0;
                          top: 0;
                        }

                        /* Dropdown menus */
                  
                        
                        .tox .tox-collection--list .tox-collection__item--active {
                          background-color: rgba(76, 175, 80, 0.1) !important;
                        }

                        /* Edit area specific styles */
                        .tox .tox-edit-area {
                          border: 1px solid transparent !important;
                        }
                        
                        .tox .tox-edit-area::before {
                          border: 2px solid #71bf43;
                          border-radius: 4px;
                          content: '';
                          inset: 0;
                          opacity: 0;
                          pointer-events: none;
                          position: absolute;
                          transition: opacity .15s;
                          z-index: 1;
                        }

                        .tox .tox-tbtn:focus::after {
                          border-radius: 3px;
                          bottom: 0;
                          box-shadow: 0 0 0 2px #71bf43;
                          content: '';
                          left: 0;
                          position: absolute;
                          right: 0;
                          top: 0;
                        }

                        .tox .tox-edit-area__iframe {
                          border: none !important;
                        }
                      `;

                      // Add styles to document head
                      const styleElement = document.createElement('style');
                      styleElement.textContent = customStyles;
                      document.head.appendChild(styleElement);

                      // Also add to editor to ensure styles are applied
                      editor.dom.addStyle(customStyles);
                    });
                  },
                }}
                value={mail && mail.body ? mail.body : ''}
                onEditorChange={(e: any) => {
                  if (isViewMode) return;
                  setMail((prevState: any) => ({
                    ...prevState,
                    body: e,
                  }));
                }}
              />
            </Stack>

            {/* Attachment Section */}
            <Stack>
              <Label>Bijlagen</Label>
              {!isViewMode && (
                <>
                  <div
                    onDrop={e => {
                      e.preventDefault();
                      setSelectedFiles(prev => [
                        ...prev,
                        ...Array.from(e.dataTransfer.files),
                      ]);
                    }}
                    onDragOver={e => e.preventDefault()}
                    style={{
                      border: '2px dashed #71bf43',
                      padding: '20px',
                      textAlign: 'center',
                      cursor: 'pointer',
                      backgroundColor: '#f0f9eb',
                      borderRadius: '4px',
                      marginBottom: '10px',
                    }}
                    aria-hidden='true'
                    onClick={() => fileInputRef.current?.click()}
                  >
                    <IconButton
                      iconProps={{ iconName: 'Upload' }}
                      styles={{
                        root: {
                          color: '#71bf43',
                          fontSize: '24px',
                          marginBottom: '8px',
                        },
                      }}
                      disabled
                    />
                    <div>Sleep bestanden hier of klik om te selecteren</div>
                  </div>
                  <input
                    ref={fileInputRef}
                    type='file'
                    style={{ display: 'none' }}
                    multiple
                    onChange={handleFileInputChange}
                  />
                </>
              )}
              {selectedFiles.length > 0 && !isViewMode && (
                <div>
                  <Label>Geselecteerde bestanden:</Label>
                  {renderSelectedFiles()}
                  {/* <PrimaryButton
                    text='Upload bijlagen'
                    onClick={() => {
                      acquireToken();
                    }}
                    styles={{
                      root: { marginTop: '10px' },
                    }}
                  /> */}
                </div>
              )}
              {isViewMode && JSON.parse(mail?.attachments || '[]').length > 0 && (
                <div style={{ marginTop: '15px' }}>
                  <Label>Geüploade bijlagen:</Label>
                  {renderUploadedAttachments()}
                </div>
              )}
            </Stack>
          </Stack>

          <div
            style={{
              marginTop: '20px',
              borderTop: '1px solid #e1e1e1',
              paddingTop: '20px',
            }}
          >
            <Stack horizontal horizontalAlign='space-between'>
              <Stack horizontal tokens={{ childrenGap: 10 }}>
                {!isViewMode && (
                  <SaveButtonWithPermissions
                    disabled={
                      !mail ||
                      (mail &&
                        (!mail.to ||
                          !mail.subject ||
                          !mail.body ||
                          JSON.parse(mail?.to || '[]').length === 0))
                    }
                    save={saveMail}
                    permission='write:customerInvoicePayments'
                    text='Verzenden'
                  />
                )}
                <DefaultButton onClick={dismissPanel}>
                  {isViewMode ? 'Sluiten' : 'Annuleren'}
                </DefaultButton>
              </Stack>
            </Stack>
          </div>
        </div>
      </div>
    </Panel>
  );
};

export default MailDetail;
