import React from 'react';
import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import moment from 'moment';
import Logo from '../../3Bouw_logo.png';

import fontSegoe from '../../fonts/Segoe_UI.ttf';
import fontSegoeBold from '../../fonts/Segoe_UI_Bold.ttf';
import { IWaterPoint } from '../../utils/WaterPoint';
import { displayNameLead } from '../../utils/Lead';

Font.register({
  family: 'Segoe UI',
  fonts: [{ src: fontSegoe }, { src: fontSegoeBold, fontStyle: 'bold' }],
});

const WaterPointsTemplate = ({
  constructionSite,
  title,
  waterPoints,
  date,
}: {
  constructionSite: any;
  title: string;
  waterPoints: IWaterPoint[];
  date: Date;
}) => (
  <Document>
    <Page size='A4' style={styles.page}>
      {/* Header */}
      <PageHeader constructionSite={constructionSite} title={title} />
      <View style={styles.pageHeader}>
        <ConstructionSiteInfo constructionSite={constructionSite} />
      </View>

      {/* Water Points List Content */}
      <WaterPointsListContent waterPoints={waterPoints} />

      {/* Signing Section */}
      <SigningSection constructionSite={constructionSite} date={date} />

      {/* Footer */}
      <Text style={styles.now} fixed>
        {moment(date).format('DD/MM/YYYY')}
      </Text>
      <Text
        style={styles.pageNumber}
        render={({ pageNumber, totalPages }) =>
          `Pagina ${pageNumber} van ${totalPages}`
        }
        fixed
      />
    </Page>
  </Document>
);

const PageHeader = ({ constructionSite, title }: any) => (
  <View style={styles.pageHeaderWrapper} fixed>
    <View style={styles.pageHeader}>
      <Image src={Logo} style={styles.image} />
      <View>
        <Text style={styles.pageHeaderTitle}>{title}</Text>
        <Text style={styles.pageHeaderName}>Werf: {constructionSite.name}</Text>
      </View>
    </View>
  </View>
);

const ConstructionSiteInfo = ({ constructionSite }: any) => (
  <View style={styles.constructionSiteAddress}>
    <View style={styles.constructionSiteInfoRow}>
      <View style={{ width: '45%' }}>
        <View style={{ flexDirection: 'row' }}>
          <Text style={{ width: '25%', fontStyle: 'bold' }}>Werfadres:</Text>
          <Text>
            {constructionSite.address}
            {'\n'}
            {constructionSite.zip_code} {constructionSite.city}
          </Text>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <Text style={{ width: '25%', fontStyle: 'bold' }}>Naam:</Text>
          <Text style={styles.constructionSiteInfoValue}>
            {displayNameLead(constructionSite.lead)}
          </Text>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <Text style={{ width: '25%', fontStyle: 'bold' }}>Tel.:</Text>
          <Text style={styles.constructionSiteInfoValue}>
            {constructionSite.lead.mobile1_v2 || constructionSite.lead.mobile1}
          </Text>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <Text style={{ width: '25%', fontStyle: 'bold' }}>E-mail:</Text>
          <Text style={styles.constructionSiteInfoValue}>
            {constructionSite.lead.email1}
          </Text>
        </View>
      </View>
      <View
        style={{
          width: '55%',
          borderLeft: '1pt solid black',
          paddingLeft: 10,
        }}
      >
        {constructionSite.sales_rep && (
          <View style={{ flexDirection: 'row' }}>
            <Text style={{ width: '43%', fontStyle: 'bold' }}>Verkoper:</Text>
            <Text style={styles.constructionSiteInfoValue}>
              {constructionSite.sales_rep.first_name}{' '}
              {constructionSite.sales_rep.last_name}
            </Text>
          </View>
        )}
        {constructionSite.architect && (
          <View style={{ flexDirection: 'row' }}>
            <Text style={{ width: '43%', fontStyle: 'bold' }}>Architect:</Text>
            <Text style={styles.constructionSiteInfoValue}>
              {constructionSite.architect &&
                `${constructionSite.architect.first_name} ${constructionSite.architect.last_name}`}
            </Text>
          </View>
        )}
        {constructionSite.site_manager && (
          <View style={{ flexDirection: 'row' }}>
            <Text style={{ width: '43%', fontStyle: 'bold' }}>Werfleider:</Text>
            <Text style={styles.constructionSiteInfoValue}>
              {constructionSite.site_manager &&
                `${constructionSite.site_manager.first_name} ${constructionSite.site_manager.last_name}`}
            </Text>
          </View>
        )}
        {constructionSite.epb_reporter && (
          <View style={{ flexDirection: 'row' }}>
            <Text style={{ width: '43%', fontStyle: 'bold' }}>
              EPB verslaggever:
            </Text>
            <Text style={styles.constructionSiteInfoValue}>
              {constructionSite.epb_reporter &&
                `${constructionSite.epb_reporter.first_name} ${constructionSite.epb_reporter.last_name}`}
            </Text>
          </View>
        )}
        {constructionSite.safety_coordinator && (
          <View style={{ flexDirection: 'row' }}>
            <Text style={{ width: '43%', fontStyle: 'bold' }}>
              Veiligheidscoördinator:
            </Text>
            <Text style={styles.constructionSiteInfoValue}>
              {constructionSite.safety_coordinator &&
                `${constructionSite.safety_coordinator.first_name} ${constructionSite.safety_coordinator.last_name}`}
            </Text>
          </View>
        )}
      </View>
    </View>
  </View>
);

const WaterPointsListContent = ({
  waterPoints,
}: {
  waterPoints: IWaterPoint[];
}) => (
  <View style={styles.table}>
    {/* Table header */}
    <View style={styles.tableRowHeader} fixed>
      <Text style={styles.tableHeaderColDescriptionWaterPoints}>
        Omschrijving
      </Text>
      <Text style={styles.tableHeaderColRainWaterPoints}>Regenwater</Text>
      <Text style={styles.tableHeaderColTapWaterPoints}>Leidingwater</Text>
      <Text style={styles.tableHeaderColTotalWaterPoints}>
        Totaal (Regenwater + Leidingwater)
      </Text>
    </View>

    {/* Table Rows */}
    {waterPoints.map((property: IWaterPoint, index: number) => (
      <WaterPointsListContentItem property={property} key={index} />
    ))}

    {/* Total Row */}
    <View
      style={[styles.tableRowWaterpoints, { borderTopWidth: 1 }]}
      wrap={false}
    >
      <Text
        style={[styles.tableColDescriptionWaterPoints, { fontStyle: 'bold' }]}
      >
        Totaal
      </Text>

      <Text style={styles.tableColRainWaterPoints}>
        {waterPoints.reduce(
          (acc, property) => acc + (property.rain_water_points || 0),
          0,
        )}
      </Text>
      <Text style={styles.tableColTapWaterPoints}>
        {waterPoints.reduce(
          (acc, property) => acc + (property.tap_water_points || 0),
          0,
        )}
      </Text>
      <Text style={styles.tableColTotalWaterPoints}>
        {waterPoints.reduce(
          (acc, property) =>
            acc +
            (property.rain_water_points || 0) +
            (property.tap_water_points || 0),
          0,
        )}
      </Text>
    </View>
  </View>
);

const WaterPointsListContentItem = ({
  property,
}: {
  property: IWaterPoint;
}) => (
  <View style={styles.tableRowWaterpoints} wrap={false}>
    <Text style={styles.tableColDescriptionWaterPoints}>{property.name}</Text>
    <Text style={styles.tableColRainWaterPoints}>
      {property.rain_water_points || 0}
    </Text>
    <Text style={styles.tableColTapWaterPoints}>
      {property.tap_water_points || 0}
    </Text>
    <Text style={styles.tableColTotalWaterPoints}>
      {(property.rain_water_points || 0) + (property.tap_water_points || 0)}
    </Text>
  </View>
);

const SigningSection = ({
  constructionSite,
  date,
}: {
  constructionSite: any;
  date: Date;
}) => (
  <View style={styles.signingArea}>
    <View style={styles.signingAreaCustomer}>
      <Text>{moment(date).format('DD/MM/YYYY')}</Text>
      <Text style={{ paddingTop: '5pt' }}>Voor akkoord, de bouwheer</Text>
      <Text style={{ paddingTop: '15pt', fontSize: 10 }}>
        {constructionSite.lead.first_name1} {constructionSite.lead.last_name1}
      </Text>
      <Text style={{ paddingTop: '5pt', fontSize: 10 }}>
        {constructionSite.lead.first_name2} {constructionSite.lead.last_name2}
      </Text>
      <Text style={{ paddingTop: '5pt', fontSize: 10 }}>
        {constructionSite.lead.address1}
      </Text>
      <Text style={{ paddingTop: '5pt', fontSize: 10 }}>
        {constructionSite.lead.zip_code1} {constructionSite.lead.city1}
      </Text>
      <Text style={{ paddingTop: '5pt', fontSize: 10 }}>
        {constructionSite.lead.phone1_v2
          ? constructionSite.lead.phone1_v2
          : constructionSite.lead.phone1
          ? constructionSite.lead.phone1
          : constructionSite.lead.mobile1_v2
          ? constructionSite.lead.mobile1_v2
          : constructionSite.lead.mobile1}
      </Text>
      <Text style={{ paddingTop: '15pt' }}>Handtekening</Text>
    </View>

    <View style={styles.signingArea3bouw}>
      <Text>{moment(date).format('DD/MM/YYYY')}</Text>
      <Text style={{ paddingTop: '5pt' }}>Voor akkoord</Text>
      <Text style={{ paddingTop: '15pt', fontSize: 10 }}>3bouw bv</Text>
      <Text style={{ paddingTop: '5pt', fontSize: 10 }}>Zone Reme 1</Text>
      <Text style={{ paddingTop: '5pt', fontSize: 10 }}>2260 Westerlo</Text>
      <Text style={{ paddingTop: '5pt', fontSize: 10 }}>014/70 47 47</Text>
      <Text style={{ paddingTop: '15pt' }}>Handtekening</Text>
    </View>
  </View>
);

const styles = StyleSheet.create({
  constructionSiteInfoRow: {
    flexDirection: 'row',
    fontFamily: 'Segoe UI',
    fontSize: 10,
    paddingTop: '3pt',
    lineHeight: '120%',
  },
  constructionSiteAddress: {},
  constructionSiteInfoValue: {
    flex: 1,
  },
  image: {
    width: '100pt',
    // height: '35pt',
    objectFit: 'scale-down',
    marginRight: 15,
  },
  now: {
    position: 'absolute',
    fontFamily: 'Segoe UI',
    fontSize: 10,
    bottom: 30,
    left: 50,
    right: 0,
    // color: 'grey',
  },
  page: {
    backgroundColor: '#ffffff',
    paddingBottom: 60,
    paddingLeft: 50,
    paddingRight: 50,
    paddingTop: 95,
  },
  pageHeaderWrapper: {
    position: 'absolute',
    top: 45,
    left: 45,
    right: 45,
  },
  pageHeader: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'row',
    paddingBottom: 10,
  },
  pageHeaderName: {
    fontFamily: 'Segoe UI',
    fontSize: 13,
  },
  pageHeaderTitle: {
    fontFamily: 'Segoe UI',
    fontSize: 15,
    fontStyle: 'bold',
  },
  pageNumber: {
    position: 'absolute',
    fontFamily: 'Segoe UI',
    fontSize: 10,
    bottom: 30,
    left: 0,
    right: 50,
    textAlign: 'right',
    // color: 'grey',
  },

  remarks: {
    fontFamily: 'Segoe UI',
    paddingTop: '20pt',
  },
  remarksLabel: {
    fontSize: 11,
    fontStyle: 'bold',
    paddingBottom: '15pt',
  },
  remarksValue: {
    fontSize: 10,
  },

  extraRemarks: {
    fontFamily: 'Segoe UI',
    minHeight: '150pt',
    fontSize: 12,
    marginBottom: 40,
    border: '1pt solid black',
  },
  signingArea: {
    flexDirection: 'row',
    fontFamily: 'Segoe UI',
    fontSize: 12,
    marginTop: 80,
    marginBottom: 120,
  },
  signingArea3bouw: {
    textAlign: 'right',
    width: '50%',
  },
  signingAreaCustomer: {
    textAlign: 'left',
    width: '50%',
  },
  table: {
    display: 'flex',
    width: 'auto',
    paddingTop: '15pt',
  },
  tableColLeft: {
    fontFamily: 'Segoe UI',
    fontSize: 9,
    fontStyle: 'bold',
    paddingBottom: '5pt',
    paddingLeft: '5pt',
    paddingRight: '5pt',
    paddingTop: '5pt',
    width: '30%',
  },
  tableColRight: {
    fontFamily: 'Segoe UI',
    fontSize: 9,
    paddingBottom: '5pt',
    paddingLeft: '5pt',
    paddingRight: '5pt',
    paddingTop: '5pt',
    width: '70%',
  },
  tableColDescriptionWaterPoints: {
    width: '30%',
  },
  tableColRainWaterPoints: {
    paddingRight: '10pt',
    textAlign: 'right',
    width: '15%',
  },
  tableColTapWaterPoints: {
    paddingRight: '10pt',
    textAlign: 'right',
    width: '15%',
  },
  tableColTotalWaterPoints: {
    paddingRight: '10pt',
    textAlign: 'right',
    width: '40%',
  },
  tableHeaderColLeft: {
    width: '30%',
    fontSize: 9,
  },
  tableHeaderColRight: {
    width: '70%',
    fontSize: 9,
  },
  tableHeaderColDescriptionWaterPoints: {
    width: '30%',
  },
  tableHeaderColRainWaterPoints: {
    width: '15%',
  },
  tableHeaderColTapWaterPoints: {
    width: '15%',
  },
  tableHeaderColTotalWaterPoints: {
    width: '40%',
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderColor: '#D2D4D1',
    fontSize: 10,
  },
  tableRowHeader: {
    backgroundColor: '#D2D4D1',
    flexDirection: 'row',
    fontFamily: 'Segoe UI',
    fontSize: 10,
    paddingBottom: '3pt',
    paddingLeft: '5pt',
    paddingTop: '5pt',
  },
  tableRowWaterpoints: {
    flexDirection: 'row',
    fontFamily: 'Segoe UI',
    fontSize: 10,
    paddingBottom: '3pt',
    paddingLeft: '5pt',
    paddingRight: '5pt',
    paddingTop: '5pt',
  },

  textAlignRight: {
    textAlign: 'right',
  },
  textAlignCenter: {
    textAlign: 'center',
  },
});

export default WaterPointsTemplate;
