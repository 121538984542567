/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import {
  DefaultButton,
  Panel,
  PanelType,
  Stack,
  StackItem,
  TextField,
  IComboBoxOption,
  Checkbox,
  Label,
  DetailsList,
  SelectionMode,
  DetailsListLayoutMode,
  ShimmeredDetailsList,
  Text,
  Icon,
  Toggle,
  Spinner,
  SpinnerSize,
} from '@fluentui/react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import moment from 'moment';
import { useAuth0 } from '@auth0/auth0-react';
import { toast } from 'react-toastify';
import {
  convertEmployeesToComboBoxOptions,
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_TOAST_POSITION,
  IConstructionSite,
  IEmployee,
  modalContentStyles,
  titleComboboxOptions,
} from '../../../utils';
import SuggestedView from '../../../components/parts/SuggestedView';
import DateView from '../../../components/parts/DateView';
import { ComboboxWithFilter } from '../../../components/parts';
import { Accordion, AccordionItem } from '../../../components/parts/Accordion';
import { ICustomerInvoice } from '../../../utils/CustomerInvoice';
import SaveButtonWithPermissions from '../../../components/parts/SaveButtonWithPermissions';
import { toastError, toastSuccess } from '../../../utils/toast';
import ZipCodeFetcher from '../../../components/DynamicZip';
import { customPanelStyles } from '../../../theme';
import CustomLabel from '../../../components/parts/Label';

const CustomerDetail = ({
  isOpen,
  dismissPanel,
  saveCustomer,
  customer,
  setCustomer,
  employees,
  refetchCustomer,
  emailChanged,
  setEmailChanged,
}: any) => {
  const { getAccessTokenSilently, user } = useAuth0();
  const onChangeTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    if ((event.target as HTMLTextAreaElement).name === 'email1') {
      setEmailChanged(true);
    }
    if ((event.target as HTMLTextAreaElement).name === 'email2') {
      setEmailChanged(true);
    }
    setCustomer((prevState: any) => ({
      ...prevState,
      [(event.target as HTMLTextAreaElement).name]: newValue || '',
    }));
  };

  const [isLoadingUpsertLead, setIsLoadingUpsertLead] = useState(false);

  const hasDirectieRole = user?.['3bouw/roles']?.includes('Directie');

  const [selectedValueItem, setSelectedValueItem] = useState<
    number | string | undefined
  >();

  const handleButtonClick = async (_lid: string) => {
    try {
      setIsLoadingUpsertLead(true);
      const token = await getAccessTokenSilently();

      const response = await fetch(
        'https://3bouw-wings-sync-function.azurewebsites.net/api/PublicUpsertLead',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`, // Add the Authorization header with your token
          },
          body: JSON.stringify({
            id: _lid,
          }),
        },
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log(data);
      setCustomer((prevState: any) => ({
        ...prevState,
        wings_account_code: data.wings_code,
        wings_code: data.wings_code,
      }));
      toastSuccess('Klant succesvol gesynchroniseerd');
    } catch (err: any) {
      toastError(err?.message ? err.message : 'Er is iets misgelopen');
    } finally {
      setIsLoadingUpsertLead(false);
    }
  };

  const action = async (id: string, email: string, action: string) => {
    toast.promise(
      (async () => {
        try {
          const url = `${process.env.REACT_APP_BACKEND_URI}/chimp/${action}`;
          const token = await getAccessTokenSilently();

          const res = await fetch(url, {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              leadId: id,
              emailAddress: email,
            }),
          });

          if (!res.ok) throw new Error(`${action} failed`);

          const result = await res.json();

          await refetchCustomer();

          toastSuccess(result.message || `Successfully ${action}!`);
        } catch (error: any) {
          console.error(`${action} failed`, error);
          throw error;
        }
      })(),
      {
        pending: {
          position: DEFAULT_TOAST_POSITION,
          render() {
            return DEFAULT_LOADING_MESSAGE;
          },
        },
        error: {
          render({ data }: { data: any }) {
            return data.message || `Er is een fout opgetreden bij ${action}`;
          },
        },
      },
    );
  };

  const formatStatus = (status: string) => {
    if (!status) return '';
    if (status.toUpperCase() === 'SUBSCRIBED') return 'Ingeschreven';
    if (status.toUpperCase() === 'UNSUBSCRIBED') return 'Uitgeschreven';

    // status is all caps but i want to capitalize the first letter and the rest of the word should be lowercase
    return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
  };

  const statusColor = (status: string) => {
    if (status === 'Nieuw') {
      return 'green';
    }

    if (status === 'Gestart') {
      return 'orange';
    }

    if (status === 'Geannuleerd') {
      return 'red';
    }

    if (status === 'Beëindigd') {
      return 'blue';
    }

    return '';
  };

  const statusLabel = (status: string) => {
    if (status === 'Nieuw') {
      return <span style={{ padding: '0px 20px' }}>{status}</span>;
    }

    if (status === 'Gestart') {
      return <span style={{ padding: '0px 17px' }}>{status}</span>;
    }

    if (status === 'Geannuleerd') {
      return <span>{status}</span>;
    }

    if (status === 'Beëindigd') {
      return <span style={{ padding: '0px 9px' }}>{status}</span>;
    }

    return <span>Onbekend</span>;
  };

  return (
    <Panel
      isLightDismiss
      isOpen={isOpen}
      onDismiss={dismissPanel}
      closeButtonAriaLabel='Close'
      headerText={
        customer && customer.id ? 'Klant wijzigen' : 'Klant toevoegen'
      }
      type={PanelType.custom}
      customWidth='900px'
      styles={customPanelStyles}
    >
      <div>
        <div className={modalContentStyles.header} />
        <div className={modalContentStyles.body}>
          {customer && customer.id && <Label>ID: {customer.id}</Label>}

          <hr style={{ marginBottom: 20, marginTop: 20 }} />

          <Stack
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <StackItem style={{ width: '48%' }}>
              <h3 style={{ marginTop: 0, marginBottom: 10 }}>Contact</h3>
              <Stack>
                <Stack style={{ marginBottom: 10 }}>
                  <ComboboxWithFilter
                    label='Aanspreking'
                    options={titleComboboxOptions()}
                    value={customer && customer.title1 ? customer.title1 : ''}
                    multiline={false}
                    callBack={(newValue: IComboBoxOption[]) => {
                      if (newValue && newValue.length > 0) {
                        const result = { ...customer };
                        result.isDirty = true;
                        result.title1 = newValue[0].key as number;
                        setCustomer(result);
                      }
                    }}
                  />
                </Stack>

                <Stack
                  style={{
                    marginBottom: 10,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  {customer?.business ? (
                    <StackItem style={{ width: '49.5%' }}>
                      <TextField
                        name='last_name1'
                        label='Bedrijfsnaam'
                        value={
                          customer && customer.last_name1
                            ? customer.last_name1
                            : ''
                        }
                        onChange={onChangeTextFieldValue}
                        required
                        errorMessage={
                          customer && !customer.last_name1
                            ? 'Dit veld is verplicht'
                            : ''
                        }
                      />
                    </StackItem>
                  ) : (
                    <>
                      <StackItem style={{ width: '49.5%' }}>
                        <TextField
                          name='first_name1'
                          label='Voornaam'
                          value={
                            customer && customer.first_name1
                              ? customer.first_name1
                              : ''
                          }
                          onChange={onChangeTextFieldValue}
                        />
                      </StackItem>
                      <StackItem style={{ width: '49.5%' }}>
                        <TextField
                          name='last_name1'
                          label='Achternaam'
                          value={
                            customer && customer.last_name1
                              ? customer.last_name1
                              : ''
                          }
                          onChange={onChangeTextFieldValue}
                          required
                          errorMessage={
                            customer && !customer.last_name1
                              ? 'Dit veld is verplicht'
                              : ''
                          }
                        />
                      </StackItem>
                    </>
                  )}
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <TextField
                    name='email1'
                    label='E-mailadres'
                    value={customer && customer.email1 ? customer.email1 : ''}
                    onChange={onChangeTextFieldValue}
                  />
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  {/* {customer.email1opt_out ? 'hoi' : 'no'} */}
                  <Checkbox
                    name='email1opt_out'
                    label='Geen mailing'
                    disabled
                    checked={customer ? customer.email1opt_out : false}
                    onChange={() => {
                      setCustomer((prevState: any) => ({
                        ...prevState,
                        email1opt_out: customer.email1opt_out
                          ? !customer.email1opt_out
                          : true,
                      }));
                    }}
                  />
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <Label>GSM</Label>
                  <PhoneInput
                    country='be'
                    preferredCountries={['be']}
                    value={customer && customer.mobile1_v2}
                    onChange={phone =>
                      setCustomer((prevState: any) => ({
                        ...prevState,
                        mobile1_v2: phone,
                      }))
                    }
                    inputStyle={{
                      width: '100%',
                      border: '1px solid rgb(96, 94, 92)',
                      borderRadius: '2px',
                    }}
                    buttonStyle={{
                      border: '1px solid rgb(96, 94, 92)',
                      borderRadius: '2px',
                    }}
                  />
                  {customer && !customer.mobile1_v2 && customer.mobile1 && (
                    <div>{`${customer.mobile1} (oude opmaak)`}</div>
                  )}
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <Label>Telefoon</Label>
                  <PhoneInput
                    country='be'
                    preferredCountries={['be']}
                    value={customer && customer.phone1_v2}
                    onChange={phone =>
                      setCustomer((prevState: any) => ({
                        ...prevState,
                        phone1_v2: phone,
                      }))
                    }
                    inputStyle={{
                      width: '100%',
                      border: '1px solid rgb(96, 94, 92)',
                      borderRadius: '2px',
                    }}
                    buttonStyle={{
                      border: '1px solid rgb(96, 94, 92)',
                      borderRadius: '2px',
                    }}
                  />
                  {customer && !customer.phone1_v2 && customer.phone1 && (
                    <div>{`${customer.phone1} (oude opmaak)`}</div>
                  )}
                </Stack>
              </Stack>
            </StackItem>

            <StackItem style={{ width: '48%' }}>
              <h3 style={{ marginTop: 0, marginBottom: 10 }}>
                {!customer?.business ? 'Partner' : 'Wings & Archief'}
              </h3>
              {!customer?.business && (
                <Stack>
                  <Stack style={{ marginBottom: 10 }}>
                    <ComboboxWithFilter
                      label='Aanspreking'
                      options={titleComboboxOptions()}
                      value={customer && customer.title2 ? customer.title2 : ''}
                      multiline={false}
                      callBack={(newValue: IComboBoxOption[]) => {
                        if (newValue && newValue.length > 0) {
                          const result = { ...customer };
                          result.isDirty = true;
                          result.title2 = newValue[0].key as number;
                          setCustomer(result);
                        }
                      }}
                    />
                  </Stack>

                  <Stack
                    style={{
                      marginBottom: 10,
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <StackItem style={{ width: '49.5%' }}>
                      <TextField
                        name='first_name2'
                        label='Voornaam'
                        value={
                          customer && customer.first_name2
                            ? customer.first_name2
                            : ''
                        }
                        onChange={onChangeTextFieldValue}
                      />
                    </StackItem>
                    <StackItem style={{ width: '49.5%' }}>
                      <TextField
                        name='last_name2'
                        label='Achternaam'
                        value={
                          customer && customer.last_name2
                            ? customer.last_name2
                            : ''
                        }
                        onChange={onChangeTextFieldValue}
                      />
                    </StackItem>
                  </Stack>

                  <Stack style={{ marginBottom: 10 }}>
                    <TextField
                      name='email2'
                      label='E-mailadres'
                      value={customer && customer.email2 ? customer.email2 : ''}
                      onChange={onChangeTextFieldValue}
                    />
                  </Stack>

                  <Stack style={{ marginBottom: 10 }}>
                    <Checkbox
                      name='email2opt_out'
                      label='Geen mailing'
                      disabled
                      checked={customer && customer.email2opt_out}
                      defaultChecked={customer && customer.email2opt_out}
                      onChange={() => {
                        setCustomer((prevState: any) => ({
                          ...prevState,
                          email2opt_out: customer.email2opt_out
                            ? !customer.email2opt_out
                            : true,
                        }));
                      }}
                    />
                  </Stack>

                  <Stack style={{ marginBottom: 10 }}>
                    <Label>GSM</Label>
                    <PhoneInput
                      country='be'
                      preferredCountries={['be']}
                      value={customer && customer.mobile2_v2}
                      onChange={phone =>
                        setCustomer((prevState: any) => ({
                          ...prevState,
                          mobile2_v2: phone,
                        }))
                      }
                      inputStyle={{
                        width: '100%',
                        border: '1px solid rgb(96, 94, 92)',
                        borderRadius: '2px',
                      }}
                      buttonStyle={{
                        border: '1px solid rgb(96, 94, 92)',
                        borderRadius: '2px',
                      }}
                    />
                    {customer && !customer.mobile2_v2 && customer.mobile2 && (
                      <div>{customer.mobile2}</div>
                    )}
                  </Stack>

                  <Stack style={{ marginBottom: 10 }}>
                    <Label>Telefoon</Label>
                    <PhoneInput
                      country='be'
                      preferredCountries={['be']}
                      value={customer && customer.phone2_v2}
                      onChange={phone =>
                        setCustomer((prevState: any) => ({
                          ...prevState,
                          phone2_v2: phone,
                        }))
                      }
                      inputStyle={{
                        width: '100%',
                        border: '1px solid rgb(96, 94, 92)',
                        borderRadius: '2px',
                      }}
                      buttonStyle={{
                        border: '1px solid rgb(96, 94, 92)',
                        borderRadius: '2px',
                      }}
                    />
                    {customer && !customer.phone2_v2 && customer.phone2 && (
                      <div>{customer.phone2}</div>
                    )}
                  </Stack>
                </Stack>
              )}
            </StackItem>
          </Stack>

          {/* Mailchimp Section */}
          {customer && customer?.id && !emailChanged ? (
            <Stack
              style={{
                marginBottom: 30,
                marginTop: 30,
                backgroundColor: '#fbeeca',
                padding: 12,
                borderRadius: 2,
                boxShadow: '0 1px 4px rgba(0, 0, 0, 0.05)',
              }}
            >
              <Stack
                horizontal
                horizontalAlign='space-between'
                verticalAlign='center'
                wrap
                tokens={{ childrenGap: 12 }}
              >
                {/* Mailchimp Logo */}
                <Stack
                  horizontal
                  verticalAlign='center'
                  style={{ gap: 10, minWidth: 100, flexShrink: 0 }}
                >
                  <div
                    style={{
                      width: 32,
                      height: 32,
                      backgroundColor: '#FFE01B',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: 4,
                      boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
                    }}
                  >
                    <img
                      src='/mailchimp.svg'
                      height={22}
                      width={22}
                      alt='Mailchimp'
                    />
                  </div>
                  <Text variant='small' style={{ fontWeight: 600 }}>
                    Mailchimp
                  </Text>
                </Stack>

                {/* Email Sections Container */}
                <Stack
                  horizontal
                  tokens={{ childrenGap: 8 }}
                  style={{
                    flexGrow: 1,
                    width:
                      customer?.email1 && customer?.email2
                        ? 'calc(100% - 200px)'
                        : 'auto',
                  }}
                >
                  {/* Email 1 Section */}
                  {customer?.email1 && (
                    <Stack
                      horizontal
                      verticalAlign='center'
                      style={{
                        backgroundColor: '#ffffff',
                        padding: '6px 10px',
                        borderRadius: 3,
                        border: '1px solid #e8e8e8',
                        flexGrow: 1,
                        flexBasis: customer?.email2 ? '50%' : '100%',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Stack
                        horizontal
                        verticalAlign='center'
                        style={{
                          gap: 8,
                          flexGrow: 1,
                          overflow: 'hidden',
                          maxWidth: '60%',
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: '#EFEEEB',
                            width: 22,
                            height: 22,
                            borderRadius: '50%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexShrink: 0,
                          }}
                        >
                          <Icon
                            iconName='Mail'
                            style={{ fontSize: 12, color: '#241C16' }}
                          />
                        </div>
                        <Text
                          style={{
                            fontSize: 12,
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            color: '#241C16',
                          }}
                        >
                          {customer.email1}
                        </Text>
                      </Stack>

                      <Stack
                        horizontal
                        style={{ gap: 8, flexShrink: 0, alignItems: 'center' }}
                      >
                        <span
                          style={{
                            backgroundColor:
                              customer.email1_mailchimp_status?.toUpperCase() ===
                              'SUBSCRIBED'
                                ? '#d8eacc'
                                : customer.email1_mailchimp_status?.toUpperCase() ===
                                  'UNSUBSCRIBED'
                                ? '#fbeeca'
                                : customer.email1_mailchimp_status?.toUpperCase() ===
                                  'ERROR'
                                ? '#FBCFBD'
                                : '#EFEEEB',
                            padding: '3px 6px',
                            borderRadius: 2,
                            fontSize: 12,
                            fontWeight: 500,
                            color:
                              customer.email1_mailchimp_status?.toUpperCase() ===
                              'SUBSCRIBED'
                                ? '#241C16'
                                : customer.email1_mailchimp_status?.toUpperCase() ===
                                  'UNSUBSCRIBED'
                                ? '#241C16'
                                : '#241C16',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {formatStatus(customer.email1_mailchimp_status)}
                        </span>

                        {customer.email1_mailchimp_status?.toUpperCase() ===
                        'SUBSCRIBED' ? (
                          <button
                            type='button'
                            style={{
                              backgroundColor: 'transparent',
                              border: '1px solid #241C16',
                              borderRadius: '3px',
                              cursor: 'pointer',
                            }}
                            onClick={async e => {
                              e.preventDefault();
                              await action(
                                customer.id,
                                customer.email1,
                                'unsubscribe',
                              );
                            }}
                          >
                            <Icon
                              iconName='UserRemove'
                              style={{ fontSize: 12, color: '#241C16' }}
                            />
                          </button>
                        ) : customer.email1_mailchimp_status?.toUpperCase() ===
                          'UNSUBSCRIBED' ? (
                            <button
                              type='button'
                              style={{
                              backgroundColor: 'transparent',
                              border: '1px solid #241C16',
                              borderRadius: '3px',
                              cursor: 'pointer',
                            }}
                              onClick={async e => {
                              e.preventDefault();
                              await action(
                                customer.id,
                                customer.email1,
                                'subscribe',
                              );
                            }}
                            >
                              <Icon
                                iconName='AddFriend'
                                style={{ fontSize: 12, color: '#241C16' }}
                              />
                            </button>
                        ) : (
                          <button
                            type='button'
                            style={{
                              backgroundColor: 'transparent',
                              border: '1px solid #241C16',
                              borderRadius: '3px',
                              cursor: 'pointer',
                            }}
                            onClick={async e => {
                              e.preventDefault();
                              await action(
                                customer.id,
                                customer.email1,
                                'subscribe',
                              );
                            }}
                          >
                            <Icon
                              iconName='AddFriend'
                              style={{ fontSize: 12, color: '#241C16' }}
                            />
                          </button>
                        )}
                      </Stack>
                    </Stack>
                  )}

                  {/* Email 2 Section */}
                  {customer?.email2 && (
                    <Stack
                      horizontal
                      verticalAlign='center'
                      style={{
                        backgroundColor: '#ffffff',
                        padding: '6px 10px',
                        borderRadius: 3,
                        border: '1px solid #e8e8e8',
                        flexGrow: 1,
                        flexBasis: customer?.email1 ? '50%' : '100%',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Stack
                        horizontal
                        verticalAlign='center'
                        style={{
                          gap: 8,
                          flexGrow: 1,
                          overflow: 'hidden',
                          maxWidth: '60%',
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: '#EFEEEB',
                            width: 22,
                            height: 22,
                            borderRadius: '50%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexShrink: 0,
                          }}
                        >
                          <Icon
                            iconName='Mail'
                            style={{ fontSize: 12, color: '#241C16' }}
                          />
                        </div>
                        <Text
                          style={{
                            fontSize: 12,
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            color: '#241C16',
                          }}
                        >
                          {customer.email2}
                        </Text>
                      </Stack>

                      <Stack
                        horizontal
                        style={{ gap: 8, flexShrink: 0, alignItems: 'center' }}
                      >
                        <span
                          style={{
                            backgroundColor:
                              customer.email2_mailchimp_status?.toUpperCase() ===
                              'SUBSCRIBED'
                                ? '#d8eacc'
                                : customer.email2_mailchimp_status?.toUpperCase() ===
                                  'UNSUBSCRIBED'
                                ? '#fbeeca'
                                : '#EFEEEB',
                            padding: '3px 6px',
                            borderRadius: 2,
                            fontSize: 12,
                            fontWeight: 500,
                            color:
                              customer.email2_mailchimp_status?.toUpperCase() ===
                              'SUBSCRIBED'
                                ? '#241C16'
                                : customer.email2_mailchimp_status?.toUpperCase() ===
                                  'UNSUBSCRIBED'
                                ? '#241C16'
                                : '#241C16',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {formatStatus(customer.email2_mailchimp_status)}
                        </span>

                        {customer.email2_mailchimp_status?.toUpperCase() ===
                        'SUBSCRIBED' ? (
                          <button
                            type='button'
                            style={{
                              backgroundColor: 'transparent',
                              border: '1px solid #241C16',
                              borderRadius: '3px',
                              cursor: 'pointer',
                            }}
                            onClick={async e => {
                              e.preventDefault();
                              await action(
                                customer.id,
                                customer.email2,
                                'unsubscribe',
                              );
                            }}
                          >
                            <Icon
                              iconName='UserRemove'
                              style={{ fontSize: 12, color: '#241C16' }}
                            />
                          </button>
                        ) : customer.email2_mailchimp_status?.toUpperCase() ===
                          'UNSUBSCRIBED' ? (
                            <button
                              type='button'
                              style={{
                              backgroundColor: 'transparent',
                              border: '1px solid #241C16',
                              borderRadius: '3px',
                              cursor: 'pointer',
                            }}
                              onClick={async e => {
                              e.preventDefault();
                              await action(
                                customer.id,
                                customer.email2,
                                'subscribe',
                              );
                            }}
                            >
                              <Icon
                                iconName='AddFriend'
                                style={{ fontSize: 12, color: '#241C16' }}
                              />
                            </button>
                        ) : (
                          <button
                            type='button'
                            style={{
                              backgroundColor: 'transparent',
                              border: '1px solid #241C16',
                              borderRadius: '3px',
                              cursor: 'pointer',
                            }}
                            onClick={async e => {
                              e.preventDefault();
                              await action(
                                customer.id,
                                customer.email2,
                                'subscribe',
                              );
                            }}
                          >
                            <Icon
                              iconName='AddFriend'
                              style={{ fontSize: 12, color: '#241C16' }}
                            />
                          </button>
                        )}
                      </Stack>
                    </Stack>
                  )}
                </Stack>

                {/* No emails message */}
                {!customer?.email1 && !customer?.email2 && (
                  <Text style={{ fontSize: 12, color: '#8a8886' }}>
                    Geen email adressen beschikbaar
                  </Text>
                )}
              </Stack>
            </Stack>
          ) : (
            <Stack
              style={{
                marginBottom: 30,
                marginTop: 30,
                backgroundColor: '#fbeeca',
                padding: 12,
                borderRadius: 2,
                boxShadow: '0 1px 4px rgba(0, 0, 0, 0.05)',
              }}
            >
              <Stack
                horizontal
                horizontalAlign='space-between'
                verticalAlign='center'
                wrap
                tokens={{ childrenGap: 12 }}
              >
                {/* Mailchimp Logo */}
                <Stack
                  horizontal
                  verticalAlign='center'
                  style={{ gap: 10, minWidth: 100, flexShrink: 0 }}
                >
                  <div
                    style={{
                      width: 32,
                      height: 32,
                      backgroundColor: '#FFE01B',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: 4,
                      boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
                    }}
                  >
                    <img
                      src='/mailchimp.svg'
                      height={22}
                      width={22}
                      alt='Mailchimp'
                    />
                  </div>
                  <Text variant='small' style={{ fontWeight: 600 }}>
                    Mailchimp
                  </Text>
                </Stack>

                {/* Email Sections Container */}
                <Stack
                  horizontal
                  tokens={{ childrenGap: 8 }}
                  style={{
                    flexGrow: 1,
                    width: 'auto',
                  }}
                >
                  <Text style={{ fontSize: 12, color: '#8a8886' }}>
                    Instellingen zijn beschikbaar na het opslaan.
                  </Text>
                </Stack>
              </Stack>
            </Stack>
          )}

          <Stack
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            <StackItem style={{ width: '48%' }}>
              <Stack>
                <Stack style={{ marginBottom: 5 }}>
                  <TextField
                    name='address1'
                    label='Adres'
                    value={
                      customer && customer.address1 ? customer.address1 : ''
                    }
                    onChange={onChangeTextFieldValue}
                  />
                </Stack>

                <Stack
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 10,
                  }}
                >
                  <StackItem style={{ width: '25%' }}>
                    <ZipCodeFetcher
                      object={customer}
                      setObject={setCustomer}
                      zipFieldName='zip_code1'
                      cityFieldName='city1'
                    />
                  </StackItem>
                  <StackItem style={{ width: '74%' }}>
                    <TextField
                      name='city1'
                      value={customer && customer.city1 ? customer.city1 : ''}
                      onChange={onChangeTextFieldValue}
                    />
                  </StackItem>
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <TextField
                    name='country'
                    label='Land'
                    value={customer && customer.country ? customer.country : ''}
                    onChange={onChangeTextFieldValue}
                  />
                </Stack>
                <Stack style={{ flexDirection: 'row', gap: 25 }}>
                  <Stack style={{ marginBottom: 10 }}>
                    {/* {lead.email1opt_out ? 'hoi' : 'no'} */}
                    <Checkbox
                      name='business'
                      label='Bedrijf'
                      checked={customer ? customer.business : false}
                      onChange={(e, checked) => {
                        setCustomer((prevState: any) => ({
                          ...prevState,
                          business: checked,
                        }));
                      }}
                    />
                  </Stack>
                  <Stack style={{ marginBottom: 10 }}>
                    <Checkbox
                      name='only_invoice'
                      label='Enkel factuur'
                      checked={customer && customer.only_invoice}
                      onChange={(e, checked) => {
                        setCustomer((prevState: any) => ({
                          ...prevState,
                          only_invoice: !!checked,
                        }));
                      }}
                    />
                  </Stack>
                </Stack>
              </Stack>
            </StackItem>
            <StackItem style={{ width: '48%' }}>
              <Stack style={{ marginBottom: 10 }}>
                <TextField
                  name='vat'
                  type='text'
                  label='BTW-nummer'
                  value={customer && customer.vat ? customer.vat : ''}
                  onChange={onChangeTextFieldValue}
                />
              </Stack>
              <Stack style={{ marginBottom: 10 }}>
                <TextField
                  name='archive_no'
                  label='Archiefnummer'
                  value={
                    customer && customer.archive_no ? customer.archive_no : ''
                  }
                  onChange={onChangeTextFieldValue}
                />
              </Stack>

              <Stack style={{ marginBottom: 10 }}>
                <TextField
                  name='wings_account_code'
                  label='Analytische code'
                  value={
                    customer && customer.wings_account_code
                      ? customer.wings_account_code
                      : ''
                  }
                  disabled
                />
                {customer &&
                  customer?.id &&
                  !customer.wings_account_code &&
                  hasDirectieRole && (
                    <p
                      style={{
                        cursor: 'pointer',
                        color: 'rgb(113, 191, 68)',
                        textDecoration: 'underline',
                        marginTop: 0,
                      }}
                      onClick={() => handleButtonClick(customer.id)}
                    >
                      code aanmaken{' '}
                      {isLoadingUpsertLead && (
                        <Spinner size={SpinnerSize.small} />
                      )}
                    </p>
                  )}
              </Stack>
            </StackItem>
          </Stack>

          <Stack>
            <h3 style={{ marginTop: 5, marginBottom: 10 }}>
              Algemene opmerkingen
            </h3>
            <Stack>
              <Stack style={{ marginBottom: 10 }}>
                <TextField
                  name='comments'
                  multiline
                  rows={6}
                  value={customer && customer.comments ? customer.comments : ''}
                  onChange={onChangeTextFieldValue}
                />
              </Stack>
            </Stack>
          </Stack>

          <hr style={{ marginBottom: 20, marginTop: 40 }} />

          <Stack>
            <h3 style={{ marginTop: 30 }}>Project informatie</h3>
            <Stack
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <StackItem style={{ width: '48%' }}>
                <Stack>
                  <Stack
                    style={{
                      marginBottom: 10,
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <StackItem style={{ width: '49.5%' }}>
                      <TextField
                        name='budget_estimate_low'
                        label='Budget (min)'
                        value={
                          customer && customer.budget_estimate_low
                            ? customer.budget_estimate_low
                            : ''
                        }
                        onChange={onChangeTextFieldValue}
                      />
                    </StackItem>
                    <StackItem style={{ width: '49.5%' }}>
                      <TextField
                        name='budget_estimate_high'
                        label='Budget (max)'
                        value={
                          customer && customer.budget_estimate_high
                            ? customer.budget_estimate_high
                            : ''
                        }
                        onChange={onChangeTextFieldValue}
                      />
                    </StackItem>
                  </Stack>

                  <Stack style={{ marginBottom: 10 }}>
                    <SuggestedView
                      item={customer}
                      setItem={setCustomer}
                      prefix='customer_degreeOfCompletion_'
                      code={
                        customer &&
                        customer.degree_of_completion &&
                        customer.degree_of_completion.substr(
                          customer.degree_of_completion.length - 4,
                        )
                      }
                      label='Graad van afwerking'
                      field='degree_of_completion'
                    />
                  </Stack>

                  <Stack style={{ marginBottom: 10 }}>
                    <SuggestedView
                      item={customer}
                      setItem={setCustomer}
                      prefix='customer_typeOfHome_'
                      code={
                        customer &&
                        customer.type_of_home &&
                        customer.type_of_home.substr(
                          customer.type_of_home.length - 4,
                        )
                      }
                      label='Type woning'
                      field='type_of_home'
                    />
                  </Stack>

                  <Stack style={{ marginBottom: 10 }}>
                    <TextField
                      name='year_to_start_construction'
                      type='number'
                      label='Gepland bouwjaar'
                      value={
                        customer && customer.year_to_start_construction
                          ? customer.year_to_start_construction
                          : ''
                      }
                      onChange={onChangeTextFieldValue}
                    />
                  </Stack>
                </Stack>
              </StackItem>

              <StackItem style={{ width: '48%' }}>
                <Stack>
                  <Stack
                    style={{
                      marginBottom: 10,
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Stack style={{ width: '57%' }}>
                      <TextField
                        name='construction_site_wanted_location'
                        label='Zoekt bouwgrond te'
                        value={
                          customer && customer.construction_site_wanted_location
                            ? customer.construction_site_wanted_location
                            : ''
                        }
                        onChange={onChangeTextFieldValue}
                      />
                    </Stack>
                    <Stack style={{ marginTop: 33 }}>
                      <Checkbox
                        name='construction_site_available'
                        label='Grond beschikbaar?'
                        checked={
                          customer && customer.construction_site_available
                        }
                        defaultChecked={
                          customer && customer.construction_site_available
                        }
                        onChange={() => {
                          setCustomer((prevState: any) => ({
                            ...prevState,
                            construction_site_available:
                              customer.construction_site_available
                                ? !customer.construction_site_available
                                : true,
                          }));
                        }}
                      />
                    </Stack>
                  </Stack>
                  <Stack style={{ marginBottom: 5 }}>
                    <TextField
                      name='construction_site_address'
                      label='Adres (werf)'
                      placeholder='Straat + nummer'
                      value={
                        customer && customer.construction_site_address
                          ? customer.construction_site_address
                          : ''
                      }
                      onChange={onChangeTextFieldValue}
                    />
                  </Stack>

                  <Stack
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      marginBottom: 15,
                    }}
                  >
                    <StackItem style={{ width: '25%' }}>
                      <ZipCodeFetcher
                        object={customer}
                        setObject={setCustomer}
                        zipFieldName='construction_site_zip_code'
                        cityFieldName='construction_site_city'
                      />
                    </StackItem>
                    <StackItem style={{ width: '74%' }}>
                      <TextField
                        name='construction_site_city'
                        value={
                          customer && customer.construction_site_city
                            ? customer.construction_site_city
                            : ''
                        }
                        onChange={onChangeTextFieldValue}
                      />
                    </StackItem>
                  </Stack>

                  <Stack style={{ marginBottom: 10 }}>
                    <TextField
                      name='construction_site_comments'
                      label='Extra info (werf)'
                      rows={2}
                      multiline
                      value={
                        customer && customer.construction_site_comments
                          ? customer.construction_site_comments
                          : ''
                      }
                      onChange={onChangeTextFieldValue}
                    />
                  </Stack>
                </Stack>
              </StackItem>
            </Stack>
          </Stack>

          <hr style={{ marginBottom: 20, marginTop: 40 }} />

          <Stack
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: 30,
            }}
          >
            <StackItem style={{ width: '48%' }}>
              <h3 style={{ marginTop: 0, marginBottom: 10 }}>Status</h3>
              <Stack>
                <Stack style={{ marginBottom: 10 }}>
                  <Label>Datum ingave</Label>
                  <p>
                    {customer &&
                      customer?.date_created &&
                      moment(new Date(customer.date_created)).format(
                        'DD/MM/YYYY',
                      )}
                  </p>
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <Label>Ingegeven door</Label>
                  <p>
                    {customer && customer?.created_by
                      ? `${customer?.created_by?.first_name} ${customer?.created_by?.last_name}`
                      : ''}
                  </p>
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <Label>Datum laatste update</Label>
                  <p>
                    {customer && customer?.date_last_updated
                      ? moment(new Date(customer?.date_last_updated)).format(
                          'DD/MM/YYYY',
                        )
                      : `${
                          customer?.date_created
                            ? moment(new Date(customer?.date_created)).format(
                                'DD/MM/YYYY',
                              )
                            : ''
                        }`}
                  </p>
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <Label>Laatste update door</Label>
                  <p>
                    {customer && customer?.last_updated_by
                      ? `${customer?.last_updated_by?.first_name} ${customer?.last_updated_by?.last_name}`
                      : `${
                          customer?.created_by
                            ? `${user?.given_name} ${user?.family_name}`
                            : ''
                        }`}
                  </p>
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <ComboboxWithFilter
                    label='Status'
                    options={[
                      {
                        key: 'NIHIL',
                        text: 'Nihil',
                      },
                      { key: 'ACTIVE', text: 'Actief' },
                      { key: 'SOLD', text: 'Verkocht' },
                      { key: 'PASSIVE', text: 'Passief' },
                      {
                        key: 'PASSIVE_VISUAL_CONTACT',
                        text: 'Passief visueel contact',
                      },
                      {
                        key: 'INVOICE_ONLY',
                        text: 'Factuur klant',
                      },
                    ]}
                    value={customer && customer?.status ? customer?.status : ''}
                    multiline={false}
                    callBack={(newValue: IComboBoxOption[]) => {
                      if (newValue && newValue.length > 0) {
                        const result = { ...customer };
                        result.status = newValue[0].key;
                        setCustomer(result);
                      }
                    }}
                    required
                    errorMessage={
                      customer && !customer?.status
                        ? 'Dit veld is verplicht'
                        : ''
                    }
                  />
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <TextField
                    name='reason_declined'
                    label='Reden passief / nihil'
                    multiline
                    value={
                      customer && customer?.reason_declined
                        ? customer?.reason_declined
                        : ''
                    }
                    onChange={onChangeTextFieldValue}
                  />
                </Stack>
              </Stack>

              {customer && customer?.auth0_id && (
                <Stack style={{}}>
                  <h3 style={{ marginTop: 10, marginBottom: 10 }}>
                    Account informatie
                  </h3>
                  <Stack>
                    <Stack style={{ marginBottom: 10 }}>
                      <p>
                        Aangemaakt op:{' '}
                        <strong>
                          {customer &&
                            customer?.user_created &&
                            moment(customer?.user_created).format('DD/MM/YYYY')}
                        </strong>
                      </p>
                      <p style={{ margin: 0, padding: 0 }}>
                        Laatst actief op:{' '}
                        <strong>
                          {customer &&
                            customer?.last_activity &&
                            customer?.last_activity !== '' &&
                            moment(
                              JSON.parse(customer?.last_activity).time,
                            ).format('DD/MM/YYYY')}
                        </strong>
                      </p>
                      <p>
                        Aantal aanmeldingen:{' '}
                        <strong>
                          {customer &&
                            customer.last_activity &&
                            customer.last_activity !== '' &&
                            JSON.parse(customer.last_activity).count}
                        </strong>
                      </p>
                    </Stack>
                    <Stack
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: 5,
                      }}
                    >
                      <Stack style={{ marginTop: 4, width: '60.5%' }}>
                        <DateView
                          item={customer}
                          setItem={setCustomer}
                          date={customer && customer.user_expires}
                          label='Account vervalt op'
                          field='user_expires'
                        />
                      </Stack>

                      <Stack style={{ marginTop: 4 }}>
                        <Toggle
                          label='Actief'
                          checked={customer.user_active}
                          onText='Actief'
                          offText='Niet actief'
                          onChange={(e: any) => {
                            setCustomer({
                              ...customer,
                              user_active: !customer.user_active,
                            });
                          }}
                        />
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              )}
            </StackItem>

            <StackItem style={{ width: '48%' }}>
              <h3 style={{ marginTop: 0, marginBottom: 10 }}>Marketing</h3>
              <Stack>
                <Stack
                  style={{
                    marginBottom: 10,
                  }}
                >
                  <ComboboxWithFilter
                    label='Verkoper'
                    options={convertEmployeesToComboBoxOptions(
                      employees.filter((x: IEmployee) => x.sales_rep === true),
                    )}
                    disabled
                    value={
                      customer && customer.sales_rep_employee
                        ? customer.sales_rep_employee.id
                        : ''
                    }
                    multiline={false}
                    callBack={(newValue: IComboBoxOption[]) => {
                      if (newValue && newValue.length > 0) {
                        const result = { ...customer };
                        if (result.sales_rep_employee) {
                          result.sales_rep_employee.id = newValue[0]
                            .key as number;
                        } else {
                          result.sales_rep_employee = {
                            id: newValue[0].key as number,
                          };
                        }
                        setCustomer(result);
                      }
                    }}
                  />
                </Stack>

                <Stack style={{ flexDirection: 'row', gap: 20 }}>
                  <Stack style={{ marginBottom: 10 }}>
                    <Checkbox
                      name='close_follow_up'
                      label='Speciale opvolging'
                      checked={customer && customer.close_follow_up}
                      defaultChecked={customer && customer.close_follow_up}
                      onChange={() => {
                        setCustomer((prevState: any) => ({
                          ...prevState,
                          close_follow_up: customer.close_follow_up
                            ? !customer.close_follow_up
                            : true,
                        }));
                      }}
                    />
                  </Stack>
                  <Stack style={{ marginBottom: 10 }}>
                    <Checkbox
                      name='christmas_card'
                      label='Kerstkaartje?'
                      checked={
                        !customer ||
                        typeof customer.christmas_card === 'undefined'
                          ? true
                          : !!customer.christmas_card
                      }
                      onChange={(e, checked) => {
                        console.log(checked);
                        setCustomer((prevState: any) => ({
                          ...prevState,
                          christmas_card: !!checked,
                        }));
                      }}
                    />
                  </Stack>
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <SuggestedView
                    item={customer}
                    setItem={setCustomer}
                    prefix='customer_knownBy_'
                    code={
                      customer &&
                      customer.known_by &&
                      customer.known_by.substr(customer.known_by.length - 4)
                    }
                    label='Naambekendheid via'
                    field='known_by'
                  />
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <SuggestedView
                    item={customer}
                    setItem={setCustomer}
                    prefix='customer_contactMetod_'
                    code={
                      customer &&
                      customer.contact_method &&
                      customer.contact_method.substr(
                        customer.contact_method.length - 4,
                      )
                    }
                    label='Contact opgenomen via'
                    field='contact_method'
                  />
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <DateView
                    item={customer}
                    setItem={setCustomer}
                    date={customer && customer.date_documentation_sent}
                    label='Datum documentatie verstuurd'
                    field='date_documentation_sent'
                  />
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <DateView
                    item={customer}
                    setItem={setCustomer}
                    date={customer && customer.date_visit_exhibition_house}
                    label='Datum laatste bezoek kijkwoning'
                    field='date_visit_exhibition_house'
                  />
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <DateView
                    item={customer}
                    setItem={setCustomer}
                    date={customer && customer.date_visit_exposition}
                    label='Datum laatste bezoek opendeur/werf'
                    field='date_visit_exposition'
                  />
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <DateView
                    item={customer}
                    setItem={setCustomer}
                    date={customer && customer.date_last_info_visit}
                    label='Datum laatste bezoek info-avond'
                    field='date_last_info_visit'
                  />
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <SuggestedView
                    item={customer}
                    setItem={setCustomer}
                    prefix='lead_preferredWayOfContact'
                    code={
                      customer &&
                      customer.preferred_way_of_contact &&
                      customer.preferred_way_of_contact.substr(
                        customer.preferred_way_of_contact.length - 4,
                      )
                    }
                    label='Documentatie versturen via'
                    field='preferred_way_of_contact'
                  />
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <SuggestedView
                    item={customer}
                    setItem={setCustomer}
                    prefix='lead_competitors_'
                    code={
                      customer &&
                      customer.competitors &&
                      customer.competitors.substr(
                        customer.competitors.length - 4,
                      )
                    }
                    label='Concurrentie'
                    field='competitors'
                  />
                </Stack>
              </Stack>
            </StackItem>
          </Stack>

          {customer &&
            customer.portal_favourites_parsed &&
            customer.portal_favourites_parsed.length > 0 && (
              <Stack>
                <h3 style={{ fontWeight: 600, marginBottom: 10 }}>
                  Favorieten
                </h3>
                {customer?.portal_favourites_parsed &&
                customer?.portal_favourites_parsed?.length > 0 ? (
                  <Stack
                    horizontal
                    wrap
                    tokens={{ childrenGap: 12 }}
                    style={{
                      justifyContent: 'center',
                    }}
                  >
                    {customer.portal_favourites_parsed &&
                      customer.portal_favourites_parsed.map(
                        (favourite: any, index: number) => (
                          <Stack
                            key={index}
                            style={{
                              width: '250px',
                              padding: '10px',
                              borderRadius: 3,
                              backgroundColor: 'rgba(255, 255, 255, 0.4)',
                              boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
                              backdropFilter: 'blur(4px)',
                              transition:
                                'transform 0.2s ease, box-shadow 0.2s ease',
                              cursor: 'pointer',
                            }}
                            onMouseEnter={e => {
                              e.currentTarget.style.transform = 'scale(1.02)';
                              e.currentTarget.style.boxShadow =
                                '0 4px 12px rgba(0, 0, 0, 0.15)';
                            }}
                            onMouseLeave={e => {
                              e.currentTarget.style.transform = 'scale(1)';
                              e.currentTarget.style.boxShadow =
                                '0 2px 6px rgba(0, 0, 0, 0.1)';
                            }}
                          >
                            <Stack
                              horizontal
                              verticalAlign='center'
                              style={{ gap: 8, marginBottom: 5 }}
                            >
                              <Icon
                                iconName='Home'
                                style={{
                                  color: 'rgb(113, 191, 68)',
                                  fontSize: 20,
                                }}
                              />
                              <span
                                style={{
                                  fontWeight: 600,
                                  color: 'rgb(113, 191, 68)',
                                }}
                              >
                                {favourite.title}
                              </span>
                            </Stack>
                            <a
                              href={`/example-projects/${favourite.id}`}
                              target='_blank'
                              rel='noreferrer'
                              style={{
                                color: '#333',
                                textDecoration: 'none',
                                fontSize: 12,
                                transition: 'color 0.2s ease',
                              }}
                            >
                              Bekijk project
                            </a>
                          </Stack>
                        ),
                      )}
                  </Stack>
                ) : (
                  <Label>Geen favorieten gevonden</Label>
                )}
              </Stack>
            )}

          <br />
          <br />
          {customer && customer.construction_sites && (
            <div>
              <Stack
                styles={{
                  root: {
                    backgroundColor: '#E5E5E5',
                    borderRadius: 2,
                    boxShadow: '0 1px 4px rgba(0, 0, 0, 0.05)',
                  },
                }}
              >
                <h4 style={{ marginBottom: 10, marginTop: 10, marginLeft: 10 }}>
                  Werven
                </h4>
                <DetailsList
                  items={(customer && customer.construction_sites) || []}
                  columns={[
                    {
                      key: 'column1',
                      name: 'Naam',
                      fieldName: 'name',
                      minWidth: 125,
                      maxWidth: 125,
                      isRowHeader: true,
                      onRender: (construction_site: IConstructionSite) => (
                        <span>{construction_site.name}</span>
                      ),
                      data: 'string',
                      isPadded: true,
                    },
                    {
                      key: 'column2',
                      name: 'Adres',
                      fieldName: 'address',
                      minWidth: 235,
                      maxWidth: 235,
                      isRowHeader: true,
                      onRender: (construction_site: IConstructionSite) => (
                        <span>
                          {construction_site.address},{' '}
                          {construction_site.zip_code} {construction_site.city}
                        </span>
                      ),
                      data: 'string',
                      isPadded: true,
                    },
                    {
                      key: 'column3',
                      name: 'Status',
                      fieldName: 'status',
                      minWidth: 110,
                      maxWidth: 110,
                      isRowHeader: true,
                      onRender: (item: IConstructionSite) => (
                        <CustomLabel
                          color={
                            statusColor(
                              item.status ? item.status.label : '',
                            ) as any
                          }
                        >
                          {statusLabel(item.status ? item.status.label : '')}
                        </CustomLabel>
                      ),
                      data: 'string',
                      isPadded: true,
                    },
                    {
                      key: 'column4',
                      name: '',
                      fieldName: 'cta',
                      minWidth: 95,
                      maxWidth: 95,
                      isRowHeader: true,
                      onRender: (construction_site: any) => (
                        <a
                          href={`/construction-sites/${construction_site.id}`}
                          target='_blank'
                          rel='noreferrer'
                          style={{
                            color: 'rgb(113, 191, 68)',

                            transition: 'color 0.2s ease',
                          }}
                        >
                          Bekijk werf
                        </a>
                      ),
                      data: 'string',
                      isPadded: true,
                    },
                  ]}
                  isHeaderVisible
                  selectionMode={SelectionMode.none}
                  setKey='carDriverOverview'
                />
              </Stack>
              <Stack style={{ marginBottom: 10, marginTop: 30 }}>
                <Accordion
                  selectedKey={selectedValueItem}
                  defaultKey={undefined}
                  toggleItem={(key: string | number) => {
                    if (selectedValueItem === key) {
                      setSelectedValueItem(undefined);
                    } else setSelectedValueItem(key);
                  }}
                >
                  <AccordionItem
                    key='accordion-invoice-customer-invoices'
                    id='accordion-invoice-customer-invoices'
                    title={
                      <Stack>
                        <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                          Facturen
                        </h3>
                      </Stack>
                    }
                  >
                    <ShimmeredDetailsList
                      items={
                        customer && customer.invoices ? customer.invoices : []
                      }
                      columns={[
                        {
                          key: 'column1',
                          name: 'Factuurdatum',
                          fieldName: 'text',
                          minWidth: 100,
                          maxWidth: 100,
                          isRowHeader: true,
                          onRender: (item: ICustomerInvoice) => (
                            <span>
                              {moment(item.invoice_date).format('YYYY-MM-DD')}
                            </span>
                          ),
                          data: 'string',
                          isPadded: true,
                        },
                        {
                          key: 'column2',
                          name: 'Factuurnr',
                          fieldName: 'text',
                          minWidth: 70,
                          maxWidth: 70,
                          isRowHeader: true,
                          onRender: (item: ICustomerInvoice) => (
                            <span>
                              <a
                                href={`/customer-invoices/${item.id}`}
                                target='_blank'
                                rel='noreferrer'
                                style={{ width: '20%', color: 'black' }}
                              >
                                {item.invoice_no}
                              </a>
                            </span>
                          ),
                          data: 'string',
                          isPadded: true,
                        },
                        {
                          key: 'column3',
                          name: 'Intro',
                          fieldName: 'text',
                          minWidth: 300,
                          maxWidth: 300,
                          isRowHeader: true,
                          onRender: (item: ICustomerInvoice) => (
                            <span>{item.intro}</span>
                          ),
                          data: 'string',
                          isPadded: true,
                        },
                        {
                          key: 'column4',
                          name: 'Betaald',
                          fieldName: 'text',
                          minWidth: 50,
                          maxWidth: 50,
                          isRowHeader: true,
                          onRender: (item: ICustomerInvoice) => (
                            <span>
                              <Checkbox
                                disabled
                                checked={!!item.invoice_paid}
                              />
                            </span>
                          ),
                          data: 'string',
                          isPadded: true,
                        },
                        {
                          key: 'column6',
                          name: 'Totaal excl. BTW',
                          fieldName: 'unit_price',
                          minWidth: 100,
                          maxWidth: 100,
                          isRowHeader: true,
                          onRender: (customerInvoice: ICustomerInvoice) => (
                            <span>
                              &euro;{' '}
                              {customerInvoice.customer_invoice_line_items
                                .reduce(
                                  (previousValue, currentValue) =>
                                    previousValue +
                                    currentValue.unit_price *
                                      currentValue.units,
                                  0,
                                )
                                .toFixed(2)}
                            </span>
                          ),
                          data: 'string',
                          isPadded: true,
                        },
                        {
                          key: 'column7',
                          name: 'Totaal incl. BTW',
                          fieldName: 'unit_price_with_vat',
                          minWidth: 100,
                          maxWidth: 100,
                          isRowHeader: true,
                          onRender: (customerInvoice: ICustomerInvoice) => (
                            <span>
                              &euro;{' '}
                              {customerInvoice.customer_invoice_line_items
                                .reduce((previousValue, currentValue) => {
                                  const unitPrice =
                                    currentValue.unit_price ?? 0; // Default to 0 if undefined
                                  const units = currentValue.units ?? 1; // Default to 1 if undefined
                                  const vatRate =
                                    currentValue.tax_code?.rate ??
                                    currentValue.percentage_vat ??
                                    0; // Use tax_code.rate if available, else fallback to percentage_vat, default to 0 if both are undefined

                                  const vatMultiplier =
                                    vatRate === 0
                                      ? 1
                                      : vatRate < 1
                                      ? 1 + vatRate
                                      : 1 + vatRate / 100; // Calculate VAT multiplier based on rate

                                  return (
                                    previousValue +
                                    unitPrice * units * vatMultiplier
                                  ); // Calculate total including VAT
                                }, 0)
                                .toFixed(2)}
                            </span>
                          ),
                          data: 'string',
                          isPadded: true,
                        },
                        {
                          key: 'column8',
                          name: 'Open saldo',
                          fieldName: 'open_saldo',
                          minWidth: 80,
                          maxWidth: 80,
                          isRowHeader: true,
                          onRender: (customerInvoice: ICustomerInvoice) => {
                            const invoiceAmount =
                              customerInvoice.customer_invoice_line_items
                                .reduce((previousValue, currentValue) => {
                                  const unitPrice =
                                    currentValue.unit_price ?? 0; // Default to 0 if undefined
                                  const units = currentValue.units ?? 1; // Default to 1 if undefined
                                  const vatRate =
                                    currentValue.tax_code?.rate ??
                                    currentValue.percentage_vat ??
                                    0; // Use tax_code.rate if available, else fallback to percentage_vat, default to 0 if both are undefined

                                  const vatMultiplier =
                                    vatRate === 0
                                      ? 1
                                      : vatRate < 1
                                      ? 1 + vatRate
                                      : 1 + vatRate / 100; // Calculate VAT multiplier based on rate

                                  return (
                                    previousValue +
                                    unitPrice * units * vatMultiplier
                                  ); // Calculate total including VAT
                                }, 0)
                                .toFixed(2);

                            const invoiceAmountPayment =
                              customerInvoice.customer_invoice_payments
                                .reduce(
                                  (previousValue, currentValue) =>
                                    previousValue + (currentValue.amount ?? 0),
                                  0,
                                ) // Ensure amount is treated as a number, defaulting to 0 if undefined
                                .toFixed(2);

                            return (
                              <span
                                style={{
                                  color:
                                    +(
                                      invoiceAmount - invoiceAmountPayment
                                    ).toFixed(2) < 0
                                      ? 'orange'
                                      : '',
                                }}
                              >
                                &euro;{' '}
                                {(invoiceAmount - invoiceAmountPayment).toFixed(
                                  2,
                                )}
                              </span>
                            );
                          },
                          data: 'string',
                          isPadded: true,
                        },
                      ]}
                      layoutMode={DetailsListLayoutMode.justified}
                      isHeaderVisible
                      selection={undefined}
                      selectionMode={SelectionMode.none}
                      selectionPreservedOnEmptyClick
                    />
                  </AccordionItem>
                </Accordion>
              </Stack>
            </div>
          )}
        </div>
        <div className={modalContentStyles.footer}>
          <Stack style={{ flexDirection: 'row', marginTop: 10 }}>
            <SaveButtonWithPermissions
              disabled={
                !customer ||
                (customer && !customer.last_name1) ||
                (customer && !customer.business && !customer.first_name1)
              }
              save={saveCustomer}
              permission='write:customers'
            />

            <StackItem>
              <DefaultButton onClick={dismissPanel}>Annuleren</DefaultButton>
            </StackItem>
          </Stack>
        </div>
      </div>
    </Panel>
  );
};

export default CustomerDetail;
