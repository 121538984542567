import React, { useState, useEffect } from 'react';
import {
  ICommandBarItemProps,
  PrimaryButton,
  Selection,
  Stack,
} from '@fluentui/react';
import _ from 'lodash';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useBoolean } from '@fluentui/react-hooks';
import { toast } from 'react-toastify';
import {
  ADD_LOG_ITEM,
  GET_LOG_ITEM_BY_ID,
  ILogItem,
} from '../../../utils/LogItem';
import {
  CREATE_LEAD_ACCOUNT,
  GET_LEAD_BY_ID,
  ILead,
  RESEND_LEAD_ACCOUNT,
  UPDATE_LEAD,
} from '../../../utils/Lead';
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_TOAST_DURATION,
  DEFAULT_TOAST_POSITION,
  GET_EMPLOYEES,
  GET_SUGGESTED_VALUES_BY_CATEGORY_CODE,
  IEmployee,
} from '../../../utils';
import { commandBarTheme, commandBarThemeCounter } from '../../../theme';
import { CommandBarSticky } from '../../../components/parts';
import { dismissNotification } from '../../../redux/notification/notificationSlice';
import { SeverityLevel, throwError } from '../../../redux/error/errorSlice';
import { useAppDispatch } from '../../../redux/hooks';
import LeadDetail from '../../leads/components/LeadDetail';
import LeadAccountModal from '../../employees/components/LeadAccountModal';
import { toastError, toastSuccess } from '../../../utils/toast';
import LogItemDetailsList from './LogItemDetailsList';

type Props = {
  loadMore?: boolean;
  lazyLoading?: boolean;
  loading?: boolean;
  loadMoreCallback?: () => void;
  logItems: ILogItem[];
  // filter,
  // filterChange?: (filter: string) => void,
  setSorting: (isSortedAsc: boolean, sortedField: string) => void;
  isSortedAsc?: boolean;
  sortedField: string;
  showLoadMore?: boolean;
  currentUser?: IEmployee;
};

const LogItemsOverview = ({
  logItems,
  loading,
  loadMore,
  loadMoreCallback = () => ({}),
  lazyLoading,
  setSorting,
  isSortedAsc = false,
  sortedField,
  showLoadMore,
  currentUser,
}: Props) => {
  const dispatch = useAppDispatch();
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [logItem, setLogItem] = useState<ILogItem>();
  const [lead, setLead] = useState<ILead | undefined>(undefined);
  const [employees, setEmployees] = useState([]);
  const [modalUser, setModalUser] = useState<ILead | undefined>(undefined);
  const [hideModal, { toggle: toggleHideModal }] = useBoolean(true);

  const [createLeadAccount] = useMutation(CREATE_LEAD_ACCOUNT);
  const [resendLeadAccount] = useMutation(RESEND_LEAD_ACCOUNT);

  // Selection
  const [selectionDetails, setSelectionDetails] = useState<
    ILogItem | undefined
  >();

  const [selectionDetailsLead, setSelectionDetailsLead] = useState<
    ILead | undefined
  >();

  const getSelectionDetails = () => {
    const currentSelection: any = selection.getSelection();

    if (currentSelection.length > 0) {
      setSelectionDetails(currentSelection[0]);
    } else {
      setSelectionDetails(undefined);
    }
  };

  const selection = new Selection({
    onSelectionChanged: getSelectionDetails,
  });

  useQuery(GET_EMPLOYEES, {
    fetchPolicy: 'no-cache',
    onCompleted: (x: any) => {
      setEmployees(x.findManyEmployees);
    },
  });

  useQuery(GET_LOG_ITEM_BY_ID, {
    // notifyOnNetworkStatusChange: true,
    variables: selectionDetails
      ? { where: { id: selectionDetails.id } }
      : undefined,
    skip: !selectionDetails,
    onCompleted: data => {
      setLogItem(data.findOneLogItem);
      setSelectionDetailsLead(data.findOneLogItem.lead);
    },
  });

  const { refetch: refetchLead } = useQuery(GET_LEAD_BY_ID, {
    // notifyOnNetworkStatusChange: true,
    variables: selectionDetailsLead
      ? { where: { id: selectionDetailsLead.id } }
      : undefined,
    skip: !selectionDetailsLead,
    onCompleted: data => {
      setLead(data.findOneLead);
    },
  });

  const { data: dataSuggestedValues } = useQuery(
    GET_SUGGESTED_VALUES_BY_CATEGORY_CODE,
    {
      variables: {
        where: {
          OR: [
            {
              suggested_value_category: {
                suggested_value_code_prefix: {
                  contains: 'lead',
                },
              },
            },
            {
              suggested_value_category: {
                suggested_value_code_prefix: {
                  contains: 'logItem',
                },
              },
            },
          ],
        },

      },
    },
  );

  const createLeadAccountFunction = async (text: string) => {
    try {
      if (lead) {
        if (!lead.last_name1 || lead.last_name1 === '') {
          toast.error('Achternaam is verplicht');
          return;
        }

        if (!lead.email1 || lead.email1 === '') {
          toast.error('Email is verplicht');
          return;
        }

        if (lead?.auth0_id) {
          toast.error('Gebruiker heeft al een account');
          return;
        }

        if (!lead.sales_rep_employee) {
          toast.error('Verkoper is verplicht');
          return;
        }

        const res = await toast.promise(
          new Promise((resolve, reject) => {
            const allInput = {
              address1: lead.address1,
              archive_no: lead.archive_no,
              budget_estimate_high: lead.budget_estimate_high
                ? +lead.budget_estimate_high
                : null,
              budget_estimate_low: lead.budget_estimate_low
                ? +lead.budget_estimate_low
                : null,
              city1: lead.city1,
              close_follow_up: lead.close_follow_up,
              comments: lead.comments,
              competitors: lead.competitors,
              construction_site_address: lead.construction_site_address,
              construction_site_available: lead.construction_site_available,
              construction_site_city: lead.construction_site_city,
              construction_site_comments: lead.construction_site_comments,
              construction_site_wanted_location:
                lead.construction_site_wanted_location,
              construction_site_zip_code: lead.construction_site_zip_code
                ? +lead.construction_site_zip_code
                : null,
              contact_method: lead.contact_method,
              country: lead.country,
              date_documentation_sent: lead.date_documentation_sent,
              date_last_info_visit: lead.date_last_info_visit,
              date_visit_exhibition_house: lead.date_visit_exhibition_house,
              date_visit_exposition: lead.date_visit_exposition,
              degree_of_completion: lead.degree_of_completion,
              email1: lead.email1,
              email1opt_out: lead.email1opt_out,
              email2: lead.email2,
              email2opt_out: lead.email2opt_out,
              fax1: lead.fax1,
              fax2: lead.fax2,
              first_name1: lead.first_name1,
              first_name2: lead.first_name2,
              known_by: lead.known_by,
              last_name1: lead.last_name1,
              last_name2: lead.last_name2,
              mobile1_v2: lead.mobile1_v2,
              mobile2_v2: lead.mobile2_v2,
              phone1_v2: lead.phone1_v2,
              phone2_v2: lead.phone2_v2,
              preferred_way_of_contact: lead.preferred_way_of_contact,
              reason_declined: lead.reason_declined,
              status: lead.status,
              title1: lead.title1,
              title2: lead.title2,
              type_of_home: lead.type_of_home,
              business: !!lead.business,
              year_to_start_construction: lead.year_to_start_construction
                ? +lead.year_to_start_construction
                : null,
              zip_code1: lead.zip_code1 ? +lead.zip_code1 : null,
              sales_rep_employee:
                lead.sales_rep_employee && lead.sales_rep_employee.id
                  ? {
                      connect: { id: lead.sales_rep_employee.id },
                    }
                  : undefined,
              wings_code: lead.wings_code ? +lead.wings_code : null,
              wings_account_code: lead.wings_account_code
                ? +lead.wings_account_code
                : null,
            };

            if (lead.id) {
              createLeadAccount({
                variables: {
                  id: lead.id,
                  data: allInput,
                  text,
                },
                onError: (error: any) => {
                  reject(error);
                },
                onCompleted: (x: any) => {
                  resolve(x);
                  console.log('=====');
                  setLead(x.createLeadAccount);
                  console.log(x.data.createLeadAccount);
                  toastSuccess('Account aangemaakt');
                },
              });
            }
          }),
          {
            pending: {
              position: DEFAULT_TOAST_POSITION,
              render() {
                return DEFAULT_LOADING_MESSAGE;
              },
            },
          },
          {
            autoClose: DEFAULT_TOAST_DURATION,
          },
        );

        await res;
        toastSuccess('Account aangemaakt');
      }
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    }
  };

  const resendLeadAccountFunction = async (text: string) => {
    try {
      if (lead) {
        if (!lead.last_name1 || lead.last_name1 === '') {
          toast.error('Achternaam is verplicht');
          return;
        }

        if (!lead.email1 || lead.email1 === '') {
          toast.error('Email is verplicht');
          return;
        }

        if (!lead.sales_rep_employee) {
          toast.error('Verkoper is verplicht');
          return;
        }

        const res = await toast.promise(
          new Promise((resolve, reject) => {
            const allInput = {
              address1: lead.address1,
              archive_no: lead.archive_no,
              budget_estimate_high: lead.budget_estimate_high
                ? +lead.budget_estimate_high
                : null,
              budget_estimate_low: lead.budget_estimate_low
                ? +lead.budget_estimate_low
                : null,
              city1: lead.city1,
              close_follow_up: lead.close_follow_up,
              comments: lead.comments,
              competitors: lead.competitors,
              construction_site_address: lead.construction_site_address,
              construction_site_available: lead.construction_site_available,
              construction_site_city: lead.construction_site_city,
              construction_site_comments: lead.construction_site_comments,
              construction_site_wanted_location:
                lead.construction_site_wanted_location,
              construction_site_zip_code: lead.construction_site_zip_code
                ? +lead.construction_site_zip_code
                : null,
              contact_method: lead.contact_method,
              country: lead.country,
              date_documentation_sent: lead.date_documentation_sent,
              date_last_info_visit: lead.date_last_info_visit,
              date_visit_exhibition_house: lead.date_visit_exhibition_house,
              date_visit_exposition: lead.date_visit_exposition,
              degree_of_completion: lead.degree_of_completion,
              email1: lead.email1,
              email1opt_out: lead.email1opt_out,
              email2: lead.email2,
              email2opt_out: lead.email2opt_out,
              fax1: lead.fax1,
              fax2: lead.fax2,
              first_name1: lead.first_name1,
              first_name2: lead.first_name2,
              known_by: lead.known_by,
              last_name1: lead.last_name1,
              last_name2: lead.last_name2,
              mobile1_v2: lead.mobile1_v2,
              mobile2_v2: lead.mobile2_v2,
              phone1_v2: lead.phone1_v2,
              phone2_v2: lead.phone2_v2,
              preferred_way_of_contact: lead.preferred_way_of_contact,
              reason_declined: lead.reason_declined,
              status: lead.status,
              title1: lead.title1,
              title2: lead.title2,
              type_of_home: lead.type_of_home,
              business: !!lead.business,
              year_to_start_construction: lead.year_to_start_construction
                ? +lead.year_to_start_construction
                : null,
              zip_code1: lead.zip_code1 ? +lead.zip_code1 : null,
              sales_rep_employee:
                lead.sales_rep_employee && lead.sales_rep_employee.id
                  ? {
                      connect: { id: lead.sales_rep_employee.id },
                    }
                  : undefined,
              wings_code: lead.wings_code ? +lead.wings_code : null,
              wings_account_code: lead.wings_account_code
                ? +lead.wings_account_code
                : null,
            };

            if (lead.id) {
              resendLeadAccount({
                variables: {
                  id: lead.id,
                  data: allInput,
                  text,
                },
                onError: (error: any) => {
                  reject(error);
                },
                onCompleted: (x: any) => {
                  resolve(x);
                  setLead(x.data.resendLeadAccount);
                  toastSuccess('Account gegevens verstuurd');
                },
              });
            }
          }),
          {
            pending: {
              position: DEFAULT_TOAST_POSITION,
              render() {
                return DEFAULT_LOADING_MESSAGE;
              },
            },
          },
          {
            autoClose: DEFAULT_TOAST_DURATION,
          },
        );

        await res;
        toastSuccess('Account gegevens verstuurd');
      }
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    }
  };

  const openLogItemDetail = (newLogItem?: boolean) => {
    if (selectionDetails && !newLogItem) {
      // getLogItem({ variables: { where: { id: selectionDetails.id } } });
    } else {
      setLogItem(undefined);
      setLead(undefined);
    }
    setIsPanelOpen(true);
  };

  // Save log item
  const [addLogItem] = useMutation(ADD_LOG_ITEM, {
    onError: error => {
      dispatch(dismissNotification());

      dispatch(
        throwError({
          module: 'executionList.saveLogItem',
          message: error.message,
          level: SeverityLevel.Critical,
        }),
      );
    },
    // refetchQueries: [{ query: GET_LOG_ITEMS }],
  });

  const [modifyLead] = useMutation(UPDATE_LEAD, {});

  const [emailChanged, setEmailChanged] = useState<boolean>(false);

  const saveLead = async () => {
    try {
      if (lead) {
        // Validation checks
        if (!lead.last_name1 || lead.last_name1 === '') {
          toast.error('Achternaam is verplicht');
          return;
        }

        if (!lead.sales_rep_employee) {
          toast.error('Verkoper is verplicht');
          return;
        }

        // Toast promise
        const res = await toast.promise(
          new Promise((resolve, reject) => {
            const allInput = {
              address1: lead.address1,
              budget_estimate_high: lead.budget_estimate_high
                ? +lead.budget_estimate_high
                : null,
              budget_estimate_low: lead.budget_estimate_low
                ? +lead.budget_estimate_low
                : null,
              city1: lead.city1,
              close_follow_up: lead.close_follow_up,
              comments: lead.comments,
              competitors: lead.competitors,
              construction_site_address: lead.construction_site_address,
              construction_site_available: lead.construction_site_available,
              construction_site_city: lead.construction_site_city,
              construction_site_comments: lead.construction_site_comments,
              construction_site_wanted_location:
                lead.construction_site_wanted_location,
              construction_site_zip_code: lead.construction_site_zip_code
                ? +lead.construction_site_zip_code
                : null,
              contact_method: lead.contact_method,
              country: lead.country,
              date_documentation_sent: lead.date_documentation_sent,
              date_last_info_visit: lead.date_last_info_visit,
              date_visit_exhibition_house: lead.date_visit_exhibition_house,
              date_visit_exposition: lead.date_visit_exposition,
              degree_of_completion: lead.degree_of_completion,
              email1: lead.email1,
              email1opt_out: lead.email1opt_out,
              email2: lead.email2,
              email2opt_out: lead.email2opt_out,
              fax1: lead.fax1,
              fax2: lead.fax2,
              first_name1: lead.first_name1,
              first_name2: lead.first_name2,
              known_by: lead.known_by,
              last_name1: lead.last_name1,
              last_name2: lead.last_name2,
              mobile1_v2: lead.mobile1_v2,
              mobile2_v2: lead.mobile2_v2,
              phone1_v2: lead.phone1_v2,
              phone2_v2: lead.phone2_v2,
              preferred_way_of_contact: lead.preferred_way_of_contact,
              reason_declined: lead.reason_declined,
              status: lead.status,
              title1: lead.title1,
              title2: lead.title2,
              type_of_home: lead.type_of_home,
              vat: lead.vat,
              year_to_start_construction: lead.year_to_start_construction
                ? +lead.year_to_start_construction
                : null,
              zip_code1: lead.zip_code1 ? +lead.zip_code1 : null,
              sales_rep_employee:
                lead.sales_rep_employee && lead.sales_rep_employee.id
                  ? {
                      connect: { id: lead.sales_rep_employee.id },
                    }
                  : undefined,
              wings_code: lead.wings_code ? +lead.wings_code : null,
              wings_account_code: lead.wings_account_code
                ? +lead.wings_account_code
                : null,
              user_active: lead?.user_active,
              user_expires: lead?.user_expires,
              user_created: lead?.user_created,
            };

            // Modify or Add lead
            if (lead.id) {
              modifyLead({
                variables: {
                  id: lead.id,
                  data: allInput,
                },
                onError: error => {
                  reject(error);
                },
                onCompleted: (x: any) => {
                  resolve(x);
                  setEmailChanged(false);
                  toastSuccess('Prospect gewijzigd');
                },
              });
            }
          }),
          {
            pending: {
              position: DEFAULT_TOAST_POSITION,
              render() {
                return DEFAULT_LOADING_MESSAGE;
              },
            },
          },
          {
            autoClose: DEFAULT_TOAST_DURATION,
          },
        );

        await res;
      }
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    }
  };

  // Columns

  // Command bar
  const commandBaritems: ICommandBarItemProps[] = [
    {
      key: 'totaal',
      text: `${logItems.length} / ${
        logItems.length > 0 ? logItems[0].prisma_total : 0
      }`,
      theme: commandBarThemeCounter,
    },
    {
      key: 'modify',
      text: 'Openen',
      iconProps: { iconName: 'Edit' },
      onClick: () => openLogItemDetail(),
      theme: commandBarTheme,
      disabled: !selectionDetails,
    },
  ];

  useEffect(() => {
    if (logItem && isPanelOpen) {
      document.title = `3bouw | Log item - ${logItem.title}`;
    } else {
      document.title = '3bouw | Log items';
    }
  }, [logItem, isPanelOpen]);

  const openNewUserModal = (lead: ILead) => {
    setModalUser(lead);
    toggleHideModal();
  };

  return (
    logItems && (
      <>
        {!hideModal && (
          <LeadAccountModal
            hideModal={hideModal}
            toggleHideModal={toggleHideModal}
            modalUser={modalUser}
            createUser={
              lead?.auth0_id
                ? resendLeadAccountFunction
                : createLeadAccountFunction
            }
          />
        )}

        <CommandBarSticky
          items={commandBaritems}
          theme={commandBarTheme}
          maxWidth='2000px'
        />

        <LeadDetail
          isOpen={isPanelOpen}
          dismissPanel={() => {
            if (!hideModal) return;

            setIsPanelOpen(false);
          }}
          openNewUserModal={openNewUserModal}
          lead={lead || {}}
          saveLead={saveLead}
          setLead={setLead}
          employees={employees}
          refetchLead={refetchLead}
          suggestedValues={dataSuggestedValues}
          emailChanged={emailChanged}
          setEmailChanged={setEmailChanged}
        />

        <LogItemDetailsList
          items={logItems}
          selection={selection}
          enableShimmer={!loading}
          isSortedAsc={isSortedAsc}
          sortedField={sortedField}
          setSorting={setSorting}
          loadMore={loadMore}
          lazyLoading={lazyLoading}
          loadMoreCallback={loadMoreCallback}
          loading={loading}
          onItemInvoked={() => openLogItemDetail()}
        />
        {logItems && (
          <>
            {showLoadMore && (
              <Stack
                style={{
                  marginTop: '15px',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
                horizontal
                horizontalAlign='center'
              >
                <PrimaryButton
                  text='Toon meer'
                  onClick={() => loadMoreCallback()}
                />
              </Stack>
            )}
            <Stack style={{ minHeight: '50px' }} />
          </>
        )}
        {!loading && !showLoadMore && logItems.length === 0 && (
          <div
            style={{
              textAlign: 'center',
              fontWeight: 600,
              fontSize: 14,
            }}
          >
            Geen resultaten
          </div>
        )}
      </>
    )
  );
};

export default LogItemsOverview;
