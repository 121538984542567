/* eslint-disable no-restricted-globals */
import React, { useState, useEffect, useRef } from 'react';
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  ICommandBarItemProps,
  PrimaryButton,
  Selection,
  Stack,
} from '@fluentui/react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useBoolean } from '@fluentui/react-hooks';
import {
  ADD_LEAD,
  displayNameLead,
  GET_LEAD_BY_ID,
  UPDATE_LEAD,
  ILead,
  ICreateLead,
  CREATE_LEAD_ACCOUNT,
  RESEND_LEAD_ACCOUNT,
} from '../../../utils/Lead';
import { commandBarTheme, commandBarThemeCounter } from '../../../theme';
import { CommandBarSticky } from '../../../components/parts';
import {
  convertEmployeesToComboBoxOptions,
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_TOAST_DURATION,
  DEFAULT_TOAST_POSITION,
  GET_EMPLOYEES,
  GET_SUGGESTED_VALUES_BY_CATEGORY_CODE,
  IEmployee,
} from '../../../utils';
import {
  Filter,
  FilterPanel,
  FilterState,
  initFilterPanelState,
} from '../../../components/parts/FilterPanel';
import { toastError, toastSuccess } from '../../../utils/toast';
import LeadAccountModal from '../../employees/components/LeadAccountModal';
import LeadDetail from './LeadDetail';
import LeadsDetailsList from './LeadsDetailsList';
import LeadDownload from './LeadDownload';

interface Props {
  loadMore?: boolean;
  lazyLoading?: boolean;
  loading?: boolean;
  loadMoreCallback?: () => void;
  downloadFile?: () => void;
  leads: ILead[];
  // filter,
  // filterChange?: (filter: string) => void,
  setSorting: (isSortedAsc: boolean, sortedField: string) => void;
  isSortedAsc?: boolean;
  sortedField?: string;
  showLoadMore?: boolean;
  setLeadFilter: React.Dispatch<React.SetStateAction<FilterState | undefined>>;
  isFilterPanelOpen: boolean;
  setIsFilterPanelOpen: React.Dispatch<React.SetStateAction<boolean>>;
  refetch: any;
}

const LeadsOverview = ({
  leads,
  loading,
  loadMore,
  setSorting,
  isSortedAsc = false,
  sortedField = '',
  showLoadMore,
  lazyLoading,
  loadMoreCallback = () => ({}),
  downloadFile = () => {},
  setLeadFilter,
  isFilterPanelOpen,
  setIsFilterPanelOpen,
  refetch,
}: Props) => {
  const [lead, setLead] = useState<ILead | ICreateLead | undefined>(undefined);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const leadChangeCount = useRef(0);
  const [modalUser, setModalUser] = useState<ILead | undefined>(undefined);
  const [hideModal, { toggle: toggleHideModal }] = useBoolean(true);

  useEffect(() => {
    // console.log('test');
    if (lead) leadChangeCount.current += 1;
    else leadChangeCount.current = 0;
  }, [lead]);

  // const isConfirmationRef = useRef(false);

  const { data: salesRepData } = useQuery(GET_EMPLOYEES, {
    fetchPolicy: 'no-cache',
    /* onCompleted: (x: any) => {
      setEmployees(x.findManyEmployees);
    }, */
    variables: {
      filter: {
        sales_rep: true,
      },
    },
  });

  const [addLead] = useMutation(ADD_LEAD, {
    onCompleted: () => {
      leadChangeCount.current = 1;
    },
  });

  const [modifyLead] = useMutation(UPDATE_LEAD, {
    onCompleted: () => {
      leadChangeCount.current = 1;
    },
  });

  const { data: dataSuggestedValues } = useQuery(
    GET_SUGGESTED_VALUES_BY_CATEGORY_CODE,
    {
      variables: {
        where: {
          OR: [
            {
              suggested_value_category: {
                suggested_value_code_prefix: {
                  contains: 'lead',
                },
              },
            },
            {
              suggested_value_category: {
                suggested_value_code_prefix: {
                  contains: 'logItem',
                },
              },
            },
          ],
        },
        orderBy: {
          suggested_value: 'asc',
        },
      },
    },
  );

  const [createLeadAccount] = useMutation(CREATE_LEAD_ACCOUNT);
  const [resendLeadAccount] = useMutation(RESEND_LEAD_ACCOUNT);

  // Selection
  const [selectionDetails, setSelectionDetails] = useState<ILead | undefined>();

  const getSelectionDetails = () => {
    const currentSelection: any = selection.getSelection();

    if (currentSelection.length > 0) {
      setSelectionDetails(currentSelection[0]);
    } else {
      setSelectionDetails(undefined);
    }
  };

  const selection = new Selection({
    onSelectionChanged: getSelectionDetails,
  });

  const { refetch: refetchLead, loading: leadLoading } = useQuery(GET_LEAD_BY_ID, {
    // notifyOnNetworkStatusChange: true,
    variables: selectionDetails
      ? { where: { id: selectionDetails.id } }
      : undefined,
    skip: !selectionDetails,
    onCompleted: data => {
      setLead(data.findOneLead);
      console.log('executed, state set');
    },
  });

  const createLeadAccountFunction = async (text: string) => {
    try {
      if (lead) {
        if (!lead.last_name1 || lead.last_name1 === '') {
          toast.error('Achternaam is verplicht');
          return;
        }

        if (!lead.email1 || lead.email1 === '') {
          toast.error('Email is verplicht');
          return;
        }

        if (lead?.auth0_id) {
          toast.error('Gebruiker heeft al een account');
          return;
        }

        if (!lead.sales_rep_employee) {
          toast.error('Verkoper is verplicht');
          return;
        }

        const res = await toast.promise(
          new Promise((resolve, reject) => {
            const allInput = {
              address1: lead.address1,
              archive_no: lead.archive_no,
              budget_estimate_high: lead.budget_estimate_high
                ? +lead.budget_estimate_high
                : null,
              budget_estimate_low: lead.budget_estimate_low
                ? +lead.budget_estimate_low
                : null,
              city1: lead.city1,
              close_follow_up: lead.close_follow_up,
              comments: lead.comments,
              competitors: lead.competitors,
              construction_site_address: lead.construction_site_address,
              construction_site_available: lead.construction_site_available,
              construction_site_city: lead.construction_site_city,
              construction_site_comments: lead.construction_site_comments,
              construction_site_wanted_location:
                lead.construction_site_wanted_location,
              construction_site_zip_code: lead.construction_site_zip_code
                ? +lead.construction_site_zip_code
                : null,
              contact_method: lead.contact_method,
              country: lead.country,
              date_documentation_sent: lead.date_documentation_sent,
              date_last_info_visit: lead.date_last_info_visit,
              date_visit_exhibition_house: lead.date_visit_exhibition_house,
              date_visit_exposition: lead.date_visit_exposition,
              degree_of_completion: lead.degree_of_completion,
              email1: lead.email1,
              email1opt_out: lead.email1opt_out,
              email2: lead.email2,
              email2opt_out: lead.email2opt_out,
              fax1: lead.fax1,
              fax2: lead.fax2,
              first_name1: lead.first_name1,
              first_name2: lead.first_name2,
              known_by: lead.known_by,
              last_name1: lead.last_name1,
              last_name2: lead.last_name2,
              mobile1_v2: lead.mobile1_v2,
              mobile2_v2: lead.mobile2_v2,
              phone1_v2: lead.phone1_v2,
              phone2_v2: lead.phone2_v2,
              preferred_way_of_contact: lead.preferred_way_of_contact,
              reason_declined: lead.reason_declined,
              status: lead.status,
              title1: lead.title1,
              title2: lead.title2,
              type_of_home: lead.type_of_home,
              business: !!lead.business,
              year_to_start_construction: lead.year_to_start_construction
                ? +lead.year_to_start_construction
                : null,
              zip_code1: lead.zip_code1 ? +lead.zip_code1 : null,
              sales_rep_employee:
                lead.sales_rep_employee && lead.sales_rep_employee.id
                  ? {
                      connect: { id: lead.sales_rep_employee.id },
                    }
                  : undefined,
              wings_code: lead.wings_code ? +lead.wings_code : null,
              wings_account_code: lead.wings_account_code
                ? +lead.wings_account_code
                : null,
            };

            if (lead.id) {
              createLeadAccount({
                variables: {
                  id: lead.id,
                  data: allInput,
                  text,
                },
                onError: error => {
                  reject(error);
                },
                onCompleted: (x: any) => {
                  leadChangeCount.current = 1;
                  resolve(x);
                  console.log('=====');
                  setLead(x.createLeadAccount);
                  console.log(x.data.createLeadAccount);
                  toastSuccess('Account aangemaakt');
                },
              });
            }
          }),
          {
            pending: {
              position: DEFAULT_TOAST_POSITION,
              render() {
                return DEFAULT_LOADING_MESSAGE;
              },
            },
          },
          {
            autoClose: DEFAULT_TOAST_DURATION,
          },
        );

        await res;
        toastSuccess('Account aangemaakt');
      }
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    }
  };

  const resendLeadAccountFunction = async (text: string) => {
    try {
      if (lead) {
        if (!lead.last_name1 || lead.last_name1 === '') {
          toast.error('Achternaam is verplicht');
          return;
        }

        if (!lead.email1 || lead.email1 === '') {
          toast.error('Email is verplicht');
          return;
        }

        if (!lead.sales_rep_employee) {
          toast.error('Verkoper is verplicht');
          return;
        }

        const res = await toast.promise(
          new Promise((resolve, reject) => {
            const allInput = {
              address1: lead.address1,
              archive_no: lead.archive_no,
              budget_estimate_high: lead.budget_estimate_high
                ? +lead.budget_estimate_high
                : null,
              budget_estimate_low: lead.budget_estimate_low
                ? +lead.budget_estimate_low
                : null,
              city1: lead.city1,
              close_follow_up: lead.close_follow_up,
              comments: lead.comments,
              competitors: lead.competitors,
              construction_site_address: lead.construction_site_address,
              construction_site_available: lead.construction_site_available,
              construction_site_city: lead.construction_site_city,
              construction_site_comments: lead.construction_site_comments,
              construction_site_wanted_location:
                lead.construction_site_wanted_location,
              construction_site_zip_code: lead.construction_site_zip_code
                ? +lead.construction_site_zip_code
                : null,
              contact_method: lead.contact_method,
              country: lead.country,
              date_documentation_sent: lead.date_documentation_sent,
              date_last_info_visit: lead.date_last_info_visit,
              date_visit_exhibition_house: lead.date_visit_exhibition_house,
              date_visit_exposition: lead.date_visit_exposition,
              degree_of_completion: lead.degree_of_completion,
              email1: lead.email1,
              email1opt_out: lead.email1opt_out,
              email2: lead.email2,
              email2opt_out: lead.email2opt_out,
              fax1: lead.fax1,
              fax2: lead.fax2,
              first_name1: lead.first_name1,
              first_name2: lead.first_name2,
              known_by: lead.known_by,
              last_name1: lead.last_name1,
              last_name2: lead.last_name2,
              mobile1_v2: lead.mobile1_v2,
              mobile2_v2: lead.mobile2_v2,
              phone1_v2: lead.phone1_v2,
              phone2_v2: lead.phone2_v2,
              preferred_way_of_contact: lead.preferred_way_of_contact,
              reason_declined: lead.reason_declined,
              status: lead.status,
              title1: lead.title1,
              title2: lead.title2,
              type_of_home: lead.type_of_home,
              business: !!lead.business,
              year_to_start_construction: lead.year_to_start_construction
                ? +lead.year_to_start_construction
                : null,
              zip_code1: lead.zip_code1 ? +lead.zip_code1 : null,
              sales_rep_employee:
                lead.sales_rep_employee && lead.sales_rep_employee.id
                  ? {
                      connect: { id: lead.sales_rep_employee.id },
                    }
                  : undefined,
              wings_code: lead.wings_code ? +lead.wings_code : null,
              wings_account_code: lead.wings_account_code
                ? +lead.wings_account_code
                : null,
            };

            if (lead.id) {
              resendLeadAccount({
                variables: {
                  id: lead.id,
                  data: allInput,
                  text,
                },
                onError: error => {
                  reject(error);
                },
                onCompleted: (x: any) => {
                  leadChangeCount.current = 1;
                  resolve(x);
                  setLead(x.data.resendLeadAccount);
                  toastSuccess('Account gegevens verstuurd');
                },
              });
            }
          }),
          {
            pending: {
              position: DEFAULT_TOAST_POSITION,
              render() {
                return DEFAULT_LOADING_MESSAGE;
              },
            },
          },
          {
            autoClose: DEFAULT_TOAST_DURATION,
          },
        );

        await res;
        toastSuccess('Account gegevens verstuurd');
      }
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    }
  };

  const openLeadDetail = (newLead?: boolean) => {
    if (selectionDetails && !newLead) {
      // getLead({ variables: { where: { id: selectionDetails.id } } });
    } else {
      setLead({ status: 'ACTIVE' });
    }
    setIsPanelOpen(true);
  };

  const openFilter = () => {
    setIsFilterPanelOpen(true);
  };

  const [emailChanged, setEmailChanged] = useState<boolean>(false);

  const saveLead = async () => {
    try {
      if (lead) {
        if (!lead.last_name1 || lead.last_name1 === '') {
          toast.error('Achternaam is verplicht');
          return;
        }

        if (!lead.sales_rep_employee) {
          toast.error('Verkoper is verplicht');
          return;
        }

        const res = await toast.promise(
          new Promise((resolve, reject) => {
            const allInput = {
              address1: lead.address1,
              archive_no: lead.archive_no,
              budget_estimate_high: lead.budget_estimate_high
                ? +lead.budget_estimate_high
                : null,
              budget_estimate_low: lead.budget_estimate_low
                ? +lead.budget_estimate_low
                : null,
              city1: lead.city1,
              close_follow_up: lead.close_follow_up,
              comments: lead.comments,
              competitors: lead.competitors,
              construction_site_address: lead.construction_site_address,
              construction_site_available: lead.construction_site_available,
              construction_site_city: lead.construction_site_city,
              construction_site_comments: lead.construction_site_comments,
              construction_site_wanted_location:
                lead.construction_site_wanted_location,
              construction_site_zip_code: lead.construction_site_zip_code
                ? +lead.construction_site_zip_code
                : null,
              contact_method: lead.contact_method,
              country: lead.country,
              date_documentation_sent: lead.date_documentation_sent,
              date_last_info_visit: lead.date_last_info_visit,
              date_visit_exhibition_house: lead.date_visit_exhibition_house,
              date_visit_exposition: lead.date_visit_exposition,
              degree_of_completion: lead.degree_of_completion,
              email1: lead.email1,
              email1opt_out: lead.email1opt_out,
              email2: lead.email2,
              email2opt_out: lead.email2opt_out,
              fax1: lead.fax1,
              fax2: lead.fax2,
              first_name1: lead.first_name1,
              first_name2: lead.first_name2,
              known_by: lead.known_by,
              last_name1: lead.last_name1,
              last_name2: lead.last_name2,
              mobile1_v2: lead.mobile1_v2,
              mobile2_v2: lead.mobile2_v2,
              phone1_v2: lead.phone1_v2,
              phone2_v2: lead.phone2_v2,
              preferred_way_of_contact: lead.preferred_way_of_contact,
              reason_declined: lead.reason_declined,
              status: lead.status,
              title1: lead.title1,
              title2: lead.title2,
              type_of_home: lead.type_of_home,
              business: !!lead.business,
              year_to_start_construction: lead.year_to_start_construction
                ? +lead.year_to_start_construction
                : null,
              zip_code1: lead.zip_code1 ? +lead.zip_code1 : null,
              sales_rep_employee:
                lead.sales_rep_employee && lead.sales_rep_employee.id
                  ? {
                      connect: { id: lead.sales_rep_employee.id },
                    }
                  : undefined,
              wings_code: lead.wings_code ? +lead.wings_code : null,
              wings_account_code: lead.wings_account_code
                ? +lead.wings_account_code
                : null,
              user_active: lead?.user_active,
              user_expires: lead?.user_expires,
              user_created: lead?.user_created,
            };

            if (lead.id) {
              modifyLead({
                variables: {
                  id: lead.id,
                  data: allInput,
                },
                onError: error => {
                  reject(error);
                },
                onCompleted: (x: any) => {
                  leadChangeCount.current = 1;
                  resolve(x);
                  setEmailChanged(false);
                  toastSuccess('Prospect gewijzigd');
                },
              });
            } else {
              addLead({
                variables: {
                  data: allInput,
                },
                onError: error => {
                  reject(error);
                },
                onCompleted: (x: any) => {
                  if (x && x.createLead) {
                    setLead(x.createLead);
                    selection.setItems(x.createLead);
                    resolve(x);
                    setEmailChanged(false);
                    toastSuccess('Prospect toegevoegd');
                  }
                },
              });
            }
          }),
          {
            pending: {
              position: DEFAULT_TOAST_POSITION,
              render() {
                return DEFAULT_LOADING_MESSAGE;
              },
            },
          },
          {
            autoClose: DEFAULT_TOAST_DURATION,
          },
        );

        await res;
      }
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    }
  };

  /* const deleteItem = () => {
    isConfirmationRef.current = true;
    setIsPanelOpen(false);
    toggleConfirmationDialog();
    isConfirmationRef.current = false;
  }; */

  const filter_schema: Filter = {
    id: 'leads_filter_schema_rev6',
    permission: 'read:leads',
    schema: [
      {
        id: 'section_0',
        label: 'Algemeen',
        width: 100,
        fields: [
          {
            id: 'section_0_0',
            name: 'Startdatum',
            fieldName: 'date_created',
            data: 'date',
            type: 'date',
            width: 32.5,
            callback: (fieldValue: string) => ({
              gte: moment(new Date(fieldValue)).toISOString(),
            }),
          },
          {
            id: 'section_0_1',
            name: 'Einddatum',
            fieldName: 'date_created',
            data: 'date',
            type: 'date',
            width: 32.5,
            callback: (fieldValue: string) => ({
              lte: moment(new Date(fieldValue)).toISOString(),
            }),
          },
          {
            id: 'section_0_2',
            name: 'Status',
            fieldName: 'OR',
            data: 'string',
            type: 'list',
            options: [
              {
                key: 'NIHIL',
                text: 'Nihil',
              },
              { key: 'ACTIVE', text: 'Actief' },
              { key: 'SOLD', text: 'Verkocht' },
              { key: 'PASSIVE', text: 'Passief' },
              {
                key: 'PASSIVE_VISUAL_CONTACT',
                text: 'Passief visueel contact',
              },
              { key: 'INVOICE_ONLY', text: 'Factuur klant' },
            ],
            width: 32.5,
            callback: (fieldValue: string) => {
              if (fieldValue && fieldValue !== 'ALLES') {
                if (fieldValue === 'INVOICE_ONLY') {
                  const filterValue: any = [
                    {
                      status: {
                        equals: fieldValue,
                      },
                    },
                    {
                      only_invoice: true,
                    },
                  ];
                  return filterValue;
                }

                const filterValue: any = [
                  {
                    status: {
                      equals: fieldValue,
                    },
                  },
                ];

                return filterValue;
              }
            },
          },
        ],
      },
      {
        id: 'section_1',
        label: 'Contact',
        width: 48,
        fields: [
          {
            id: 'section_1_2',
            name: 'Voornaam',
            fieldName: 'first_name1',
            data: 'string',
            type: 'text',
            width: 49.5,
            callback: (fieldValue: string) => ({
              contains: fieldValue,
            }),
          },
          {
            id: 'section_1_1',
            name: 'Achternaam',
            fieldName: 'last_name1',
            data: 'string',
            type: 'text',
            width: 49.5,
            callback: (fieldValue: string) => ({
              startsWith: fieldValue,
            }),
          },
          {
            id: 'section_1_3',
            name: 'Adres',
            fieldName: 'address1',
            data: 'string',
            type: 'text',
            callback: (fieldValue: string) => ({
              startsWith: fieldValue,
            }),
          },
          {
            id: 'section_1_4',
            name: 'Stad',
            fieldName: 'city1',
            data: 'string',
            type: 'text',
            width: 69.5,
            callback: (fieldValue: string) => ({
              startsWith: fieldValue,
            }),
          },
          {
            id: 'section_1_5',
            name: 'Post code',
            fieldName: 'zip_code1',
            data: 'number',
            type: 'number',
            width: 29.5,
            callback: (fieldValue: string) => parseInt(fieldValue, 10),
          },
          {
            id: 'section_1_6',
            name: 'Post code (comma separated)',
            fieldName: 'OR',
            data: 'number',
            type: 'text',
            callback: (fieldValue: string) => {
              const fieldArray = fieldValue.replace(' ', '').split(',');
              if (fieldArray.length > 0) {
                const filterArray = [];

                for (let i = 0; i < fieldArray.length; i++) {
                  if (!isNaN(fieldArray[i] as any)) {
                    filterArray.push({
                      zip_code1: parseInt(fieldArray[i], 10),
                    });
                  }
                }

                return filterArray;
              }
            },
          },
          {
            id: 'section_1_7',
            name: 'Land',
            fieldName: 'country',
            data: 'string',
            type: 'text',
            callback: (fieldValue: string) => ({
              startsWith: fieldValue,
            }),
          },
          {
            id: 'section_1_8',
            name: 'E-mailadres',
            fieldName: 'email1',
            data: 'string',
            type: 'text',
            callback: (fieldValue: string) => ({
              startsWith: fieldValue,
            }),
          },
          {
            id: 'section_1_9',
            name: 'GSM',
            fieldName: 'OR',
            data: 'string',
            type: 'text',
            callback: (fieldValue: string) => [
              { mobile1: { contains: fieldValue } },
              { mobile1_v2: { contains: fieldValue } },
              { mobile1search: { contains: fieldValue } },
            ],
          },
          {
            id: 'section_1_10',
            name: 'Telefoon',
            fieldName: 'OR',
            data: 'string',
            type: 'text',
            callback: (fieldValue: string) => [
              { phone1: { contains: fieldValue } },
              { phone1_v2: { contains: fieldValue } },
              { phone1search: { contains: fieldValue } },
            ],
          },
          {
            id: 'section_1_11',
            name: 'BTW-nummer',
            fieldName: 'vat',
            data: 'string',
            type: 'text',
            callback: (fieldValue: string) => ({
              contains: fieldValue.replace(' ', ''),
            }),
          },
        ],
      },
      {
        id: 'section_2',
        label: 'Partner',
        width: 48,
        fields: [
          {
            id: 'section_2_1',
            name: 'Voornaam',
            fieldName: 'first_name2',
            data: 'string',
            type: 'text',
            width: 49.5,
            callback: (fieldValue: string) => ({
              startsWith: fieldValue,
            }),
          },
          {
            id: 'section_2_2',
            name: 'Achternaam',
            fieldName: 'last_name2',
            data: 'string',
            type: 'text',
            width: 49.5,
            callback: (fieldValue: string) => ({
              startsWith: fieldValue,
            }),
          },
          {
            id: 'section_2_3',
            name: 'E-mailadres',
            fieldName: 'email2',
            data: 'string',
            type: 'text',
            callback: (fieldValue: string) => ({
              startsWith: fieldValue,
            }),
          },
          {
            id: 'section_2_4',
            name: 'GSM',
            fieldName: 'OR',
            data: 'string',
            type: 'text',
            callback: (fieldValue: string) => [
              { mobile2: { contains: fieldValue } },
              { mobile2_v2: { contains: fieldValue } },
              { mobile2search: { contains: fieldValue } },
            ],
          },
          {
            id: 'section_2_5',
            name: 'Telefoon',
            fieldName: 'OR',
            data: 'string',
            type: 'text',
            callback: (fieldValue: string) => [
              { phone2: { contains: fieldValue } },
              { phone2_v2: { contains: fieldValue } },
              { phone2search: { contains: fieldValue } },
            ],
          },
          {
            id: 'section_2_7',
            name: 'Analytische code',
            fieldName: 'wings_account_code',
            data: 'number',
            type: 'number',
            callback: (fieldValue: string) => ({
              equals: parseInt(fieldValue, 10),
            }),
          },
        ],
      },
      {
        id: 'section_3',
        label: 'Marketing',
        width: 48,
        fields: [
          {
            id: 'section_3_0',
            name: 'Verkoper',
            fieldName: 'sales_rep_employee',
            data: 'string',
            type: 'list',
            options: salesRepData
              ? convertEmployeesToComboBoxOptions(
                  salesRepData.findManyEmployees.filter(
                    (x: IEmployee) => x.sales_rep === true,
                  ),
                )
              : [],
            callback: (fieldValue: string) => {
              if (fieldValue && fieldValue !== 'ALLES') {
                return {
                  id: {
                    equals: parseInt(fieldValue, 10),
                  },
                };
              }
            },
          },
          {
            id: 'section_3_1',
            name: 'Naambekendheid via',
            fieldName: 'known_by',
            data: 'string',
            type: 'suggested',
            prefix: 'lead_knownBy_',
            callback: (fieldValue: string) => {
              if (fieldValue && fieldValue !== 'ALLES') {
                return {
                  startsWith: `lead_knownBy_${fieldValue}`,
                };
              }
            },
          },
          {
            id: 'section_3_2',
            name: 'Contact opgenomen via',
            fieldName: 'contact_method',
            data: 'string',
            type: 'suggested',
            prefix: 'lead_contactMetod_',
            callback: (fieldValue: string) => {
              if (fieldValue && fieldValue !== 'ALLES') {
                return {
                  startsWith: `lead_contactMetod_${fieldValue}`,
                };
              }
            },
          },
          {
            id: 'section_3_3',
            name: 'Kerstkaartje?',
            fieldName: 'christmas_card',
            data: 'boolean',
            type: 'checkbox',
            // prefix: 'lead_contactMetod_',
            callback: (fieldValue: string) => fieldValue === 'true',
          },
        ],
      },
      {
        id: 'section_4',
        label: 'Bouwgrond info',
        width: 48,
        fields: [
          {
            id: 'section_4_2',
            name: 'Bouwgrond beschikbaar',
            fieldName: 'construction_site_available',
            data: 'string',
            type: 'list',
            options: [
              { key: 'true', text: 'Ja' },
              { key: 'false', text: 'Nee' },
            ],
            callback: (fieldValue: string) => {
              if (fieldValue && fieldValue !== 'ALLES') {
                if (fieldValue === 'true') return true;
                if (fieldValue === 'false') return false;
              }
            },
          },
          {
            id: 'section_4_3',
            name: 'Adres',
            fieldName: 'construction_site_address',
            data: 'string',
            type: 'text',
            callback: (fieldValue: string) => ({
              startsWith: fieldValue,
            }),
          },
          {
            id: 'section_4_4',
            name: 'Stad',
            fieldName: 'construction_site_city',
            data: 'string',
            type: 'text',
            width: 69.5,
            callback: (fieldValue: string) => ({
              startsWith: fieldValue,
            }),
          },
          {
            id: 'section_4_5',
            name: 'Post code',
            fieldName: 'construction_site_zip_code',
            data: 'number',
            type: 'number',
            width: 29.5,
            callback: (fieldValue: string) => parseInt(fieldValue, 10),
          },
          {
            id: 'section_4_6',
            name: 'Post code (comma separated)',
            fieldName: 'OR',
            data: 'number',
            type: 'text',
            callback: (fieldValue: string) => {
              const fieldArray = fieldValue.replace(' ', '').split(',');
              if (fieldArray.length > 0) {
                const filterArray = [];

                for (let i = 0; i < fieldArray.length; i++) {
                  if (!isNaN(fieldArray[i] as any)) {
                    filterArray.push({
                      construction_site_zip_code: parseInt(fieldArray[i], 10),
                    });
                  }
                }

                return filterArray;
              }
            },
          },
          {
            id: 'section_4_7',
            name: 'Graad van afwerking',
            fieldName: 'degree_of_completion',
            data: 'string',
            type: 'suggested',
            prefix: 'customer_degreeOfCompletion_',
            callback: (fieldValue: string) => {
              if (fieldValue && fieldValue !== 'ALLES') {
                return {
                  startsWith: `customer_degreeOfCompletion_${fieldValue}`,
                };
              }
            },
          },
        ],
      },
      {
        id: 'section_8',
        label: 'Logitems',
        width: 48,
        fields: [
          {
            id: 'section_8_0',
            name: 'Action required',
            fieldName: 'log_items',
            data: 'string',
            type: 'list',
            options: [
              { key: 'true', text: 'Ja' },
              { key: 'false', text: 'Nee' },
            ],
            callback: (fieldValue: string) => {
              if (fieldValue && fieldValue !== 'ALLES') {
                return {
                  some: {
                    action_required: true,
                  },
                };
              }
            },
          },
          {
            id: 'section_8_10',
            name: 'Titel',
            fieldName: 'log_items',
            data: 'string',
            type: 'text',
            width: 100,
            callback: (fieldValue: string) => ({
              some: {
                text: {
                  contains: fieldValue,
                },
              },
            }),
          },
          {
            id: 'section_8_1',
            name: 'Startdatum actie vereist',
            fieldName: 'log_items',
            data: 'date',
            type: 'date',
            width: 48.5,
            callback: (fieldValue: string) => ({
              some: {
                action_required_by: {
                  gte: moment(new Date(fieldValue)).toISOString(),
                },
              },
            }),
          },
          {
            id: 'section_8_2',
            name: 'Einddatum actie vereist',
            fieldName: 'log_items',
            data: 'date',
            type: 'date',
            width: 48.5,
            callback: (fieldValue: string) => ({
              some: {
                action_required_by: {
                  lte: moment(new Date(fieldValue)).toISOString(),
                },
              },
            }),
          },
          {
            id: 'section_8_3',
            name: 'Startdatum aanmaak',
            fieldName: 'log_items',
            data: 'date',
            type: 'date',
            width: 48.5,
            callback: (fieldValue: string) => ({
              some: {
                date_created: {
                  gte: moment(new Date(fieldValue)).toISOString(),
                },
              },
            }),
          },
          {
            id: 'section_8_4',
            name: 'Einddatum aanmaak',
            fieldName: 'log_items',
            data: 'date',
            type: 'date',
            width: 48.5,
            callback: (fieldValue: string) => ({
              some: {
                date_created: {
                  lte: moment(new Date(fieldValue)).toISOString(),
                },
              },
            }),
          },
        ],
      },
    ],
  };

  const modelProps = {
    isBlocking: false,
    styles: { main: { maxWidth: 450 } },
  };

  const dialogRemoveConfirmationProps = {
    type: DialogType.largeHeader,
    title: 'Sluit panel',
    subText: 'Niet opgeslagen wijzigingen gaan verloren.',
  };

  // Command bar
  const commandBaritems: ICommandBarItemProps[] = [
    {
      key: 'totaal',
      text: `${leads.length} / ${leads.length > 0 ? leads[0].prisma_total : 0}`,
      theme: commandBarThemeCounter,
    },
    {
      key: 'new',
      text: 'Nieuw',
      iconProps: { iconName: 'Add' },
      onClick: () => openLeadDetail(true),
      theme: commandBarTheme,
    },
    {
      key: 'modify',
      text: 'Wijzig',
      iconProps: { iconName: 'Edit' },
      onClick: () => {
        openLeadDetail();
      },
      theme: commandBarTheme,
      disabled: !selectionDetails,
    },
    {
      key: 'downloadXLS',
      text: 'Excel',
      iconProps: { iconName: 'ExcelDocument' },
      onClick: () => downloadFile(),
      theme: commandBarTheme,
      // disabled: !selectionDetails,
    },
    {
      key: 'downloadPDF',
      text: 'PDF',
      iconProps: { iconName: 'PDF' },
      onClick: () => LeadDownload(lead as any, dataSuggestedValues),
      disabled: !selectionDetails,
      theme: commandBarTheme,
    },
    {
      key: 'filter',
      text: 'Filter',
      iconProps: { iconName: 'Filter' },
      onClick: () => openFilter(),
      theme: commandBarTheme,
    },
  ];

  useEffect(() => {
    if (lead && isPanelOpen) {
      document.title = `3bouw | Prospect - ${displayNameLead(lead)}`;
    } else {
      document.title = '3bouw | Prospecten';
    }
  }, [lead, isPanelOpen]);

  const [filterState, setFilterState] = useState<FilterState>(
    initFilterPanelState(filter_schema),
  );

  const openNewUserModal = (lead: ILead) => {
    setModalUser(lead);
    toggleHideModal();
  };

  return (
    <>
      {!hideModal && (
        <LeadAccountModal
          hideModal={hideModal}
          toggleHideModal={toggleHideModal}
          modalUser={modalUser}
          createUser={
            lead?.auth0_id
              ? resendLeadAccountFunction
              : createLeadAccountFunction
          }
        />
      )}

      <CommandBarSticky
        items={commandBaritems}
        theme={commandBarTheme}
        maxWidth='2000px'
      />

      <LeadDetail
        isOpen={isPanelOpen}
        dismissPanel={() => {
          /* if (!isConfirmationRef.current) {
            setIsConfirmationHidden(false);
          } else {
            setIsPanelOpen(false);
            isConfirmationRef.current = false;
          } */
          if (!hideModal) return;

          if (leadChangeCount.current > 2) {
            console.log('open confirmation');
            setShowConfirmationDialog(true);
          } else {
            setIsPanelOpen(false);
            leadChangeCount.current = 1;
          }
        }}
        loading={leadLoading}
        openNewUserModal={openNewUserModal}
        lead={lead || {}}
        saveLead={saveLead}
        setLead={setLead}
        employees={salesRepData ? salesRepData.findManyEmployees : []}
        refetchLead={refetchLead}
        suggestedValues={dataSuggestedValues}
        refetch={refetch}
        emailChanged={emailChanged}
        setEmailChanged={setEmailChanged}
      />

      {isFilterPanelOpen && (
        <FilterPanel
          isOpen={isFilterPanelOpen}
          dismissPanel={() => {
            setIsFilterPanelOpen(false);
          }}
          filter={filter_schema}
          setFilter={setLeadFilter}
          filterState={filterState}
          setFilterState={setFilterState}
        />
      )}

      <LeadsDetailsList
        items={leads}
        selection={selection}
        isShimmered={loading}
        enableShimmer
        isSortedAsc={isSortedAsc}
        sortedField={sortedField}
        setSorting={setSorting}
        loadMore={loadMore}
        lazyLoading={lazyLoading}
        loadMoreCallback={loadMoreCallback}
        loading={loading}
        onItemInvoked={item => {
          openLeadDetail(false);
        }}
        employees={salesRepData ? salesRepData.findManyEmployees : []}
      />

      {showConfirmationDialog && (
        <Dialog
          dialogContentProps={dialogRemoveConfirmationProps}
          modalProps={modelProps}
          hidden={!showConfirmationDialog}
        >
          <DialogFooter>
            <PrimaryButton
              onClick={() => {
                setIsPanelOpen(false);
                setShowConfirmationDialog(false);
                leadChangeCount.current = 1;
              }}
              text='Sluit'
            />
            <DefaultButton
              text='Annuleren'
              onClick={() => {
                setShowConfirmationDialog(false);
              }}
            />
          </DialogFooter>
        </Dialog>
      )}
      {leads && (
        <>
          {showLoadMore && (
            <Stack
              style={{
                marginTop: '15px',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
              horizontal
              horizontalAlign='center'
            >
              <PrimaryButton
                text='Toon meer'
                onClick={() => loadMoreCallback()}
              />
            </Stack>
          )}
          <Stack style={{ minHeight: '50px' }} />
        </>
      )}

      {!loading && !showLoadMore && leads.length === 0 && (
        <div
          style={{
            textAlign: 'center',
            fontWeight: 600,
            fontSize: 14,
          }}
        >
          Geen resultaten
        </div>
      )}
    </>
  );
};

export default LeadsOverview;
